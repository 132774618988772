/* eslint-disable no-unused-vars */
import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import Navbar from "../../components/Navbar";
import Contact from "../../components/Profile/Contact";
import EmailForm from "../../components/Profile/EmailForm";
import PasswordForm from "../../components/Profile/PasswordForm";
import TabPanel from "../../components/Profile/TabPanel";
import AlertDialog from "../../components/Project/AlertDialog";
import configData from "../../configData.json";
import BecomeCreator from "../myAccount/BecomeCreator";

function Profile(props) {
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(0);
  const currentUser = useSelector((state) => state.authentication.currentUser);
  const canBecomeCreator = configData.become_creator.includes(
    currentUser?.role_id
  );
  const isAdmin = configData.administraion.includes(currentUser?.role_id);
  const [isChnnelTouched, setIsChnnelTouched] = useState(false);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [ifTouchedValue, setIfTouchedValue] = useState();
  const handleChange = (event, newValue) => {
    if (isChnnelTouched) {
      setIsAlertDialogOpen(true);
    } else {
      setValue(newValue);
    }
    setIfTouchedValue(newValue);
  };
  const setChannel = (val) => {
    setIsChnnelTouched(val);
  };
  const onCanelAlertDialog = () => {
    setIsAlertDialogOpen(false);
  };
  const onOkAlertDialog = () => {
    setIsAlertDialogOpen(false);
    setIsChnnelTouched(false);
    setValue(ifTouchedValue);
  };
  return (
    <>
      <Navbar
        title="My Account"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <CustomLoader isOpen={loading} />
      <Box sx={{ mt: 5 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Contact Info" sx={{ textTransform: "capitalize" }} />
            {(!canBecomeCreator || isAdmin) && (
              <Tab label="Channel" sx={{ textTransform: "capitalize" }} />
            )}
            {!currentUser.isUserLoginViaSocial && (
              <Tab label="Email" sx={{ textTransform: "capitalize" }} />
            )}
            {!currentUser.isUserLoginViaSocial && (
              <Tab label="Password" sx={{ textTransform: "capitalize" }} />
            )}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Contact
            goToEmailTab={() => setValue(canBecomeCreator ? 1 : 2)}
            setChannel={setChannel}
          />
        </TabPanel>
        {(!canBecomeCreator || isAdmin) && (
          <TabPanel value={value} index={1}>
            <BecomeCreator hideNavbar setChannel={setChannel} />
          </TabPanel>
        )}
        <TabPanel value={value} index={canBecomeCreator ? 1 : 2}>
          <EmailForm setChannel={setChannel} />
        </TabPanel>
        <TabPanel value={value} index={canBecomeCreator ? 2 : 3}>
          <PasswordForm setChannel={setChannel} />
        </TabPanel>
        <AlertDialog
          isAlertDialogOpen={isAlertDialogOpen}
          onCanel={onCanelAlertDialog}
          onOk={onOkAlertDialog}
          display="All unsaved contents will be lost!"
        />
      </Box>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(Profile);
