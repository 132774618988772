import { Alert, Box, Button, FormControl } from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  emailValidationMessage,
  firstNameValidationMessage,
  lastNameValidationMessage,
  passwordValidationMessage,
} from "../../common/constants";
import { isPasswordValid, validateEmail } from "../../common/helper";
import CustomInput from "../../components/CustomInput";
import CustomLoader from "../../components/CustomLoader";
import Navbar from "../../components/Navbar";
import PrivatePolicy from "../../components/privatePolicy";
import Terms from "../../components/terms";
import { signUp } from "../../store/slices/authenticationSlice";
import LoginStyle from "../../styles/Register.module.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "#fff",
  border: "2px solid #000",
  boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
  padding: 4,
  maxHeight: "80vh", // Set a max height relative to the viewport height
  overflowY: "auto", // Allow vertical scrolling if content overflows
};

function Register(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const [emailValidationMsg, setEmailValidationMsg] = useState("");
  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [firstNameValidationMsg, setFirstNameValidationMsg] = useState("");
  const [lastNameValidationMsg, setLastNameValidationMsg] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState(
    []
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const handleOpenPrivacy = () => setOpenPrivacy(true);
  const handleClosePrivacy = () => setOpenPrivacy(false);

  const onPressRegister = () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      active: true,
      role_id: "3",
      status: 0,
    };

    if (!isBtnDisabled && isFormValid) {
      setLoading(true);
      dispatch(signUp(payload))
        .unwrap()
        .then((res) => {
          console.log("res", res);
          setLoading(false);
          if (res?.data?.status) {
            setLoading(false);
            navigate("/confirm-email");
          } else if (res?.data?.status === false) {
            setServerSideValidationErrors(Object.values(res?.data?.errors));
          }
        })
        .catch((err) => {
          console.log("catchError:", err);
        });
    } else {
      firstNameLength(firstName);
      lastNameValid(lastName);
      emailValid(email);
      passwordValid(password);
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      email &&
      password &&
      isFormValid &&
      !firstNameValidationMsg &&
      !lastNameValidationMsg &&
      !emailValidationMsg &&
      !passwordValidationMsg
    ) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
      setIsFormValid(false);
    }
  }, [
    firstName,
    lastName,
    email,
    password,
    isFormValid,
    firstNameValidationMsg,
    lastNameValidationMsg,
    emailValidationMsg,
    passwordValidationMsg,
  ]);

  const firstNameLength = (value) => {
    if (value.length === 0) {
      setFirstNameValidationMsg(firstNameValidationMessage);
      setIsFormValid(false);
    } else {
      setFirstNameValidationMsg("");
      setIsFormValid(true);
    }
  };

  const lastNameValid = (value) => {
    if (value.length === 0) {
      setLastNameValidationMsg(lastNameValidationMessage);
      setIsFormValid(false);
    } else {
      setLastNameValidationMsg("");
      setIsFormValid(true);
    }
  };

  const emailValid = (value) => {
    if (value.length === 0) {
      setEmailValidationMsg(emailValidationMessage);
      setIsFormValid(false);
    }

    if (!validateEmail(value)) {
      setEmailValidationMsg(emailValidationMessage);
      setIsFormValid(false);
    } else {
      setEmailValidationMsg("");
      setIsFormValid(true);
    }
  };

  const passwordValid = (value) => {
    if (isPasswordValid(value)) {
      setPasswordValidationMsg(passwordValidationMessage);
      setIsFormValid(false);
    } else {
      setPasswordValidationMsg("");
      setIsFormValid(true);
    }
  };

  return (
    <>
      <Navbar />
      <CustomLoader isOpen={loading} />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          sx={{ width: "45em", marginTop: "4em" }}
          sm={{ width: "35em", p: 1, mt: "4em" }}
          className={LoginStyle.inputContaier}
        >
          <Box textAlign="center">
            <h3>Sign up for flippingBook</h3>
            <p>Register an account to get started</p>
          </Box>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <CustomInput
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                firstNameLength(e.target.value);
              }}
              placeholder="Please input your first name"
              label="First Name"
              validationMessage={firstNameValidationMsg}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <CustomInput
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                lastNameValid(e.target.value);
              }}
              placeholder="Please input your last name"
              label="Last Name"
              validationMessage={lastNameValidationMsg}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <CustomInput
              value={email}
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
                emailValid(e.target.value);
              }}
              placeholder="Please input your email"
              label="Email Address"
              validationMessage={emailValidationMsg}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <CustomInput
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                passwordValid(e.target.value);
              }}
              placeholder="******************************"
              label="Password"
              isInputTypePassword
              validationMessage={passwordValidationMsg}
            />
          </FormControl>
          {serverSideValidationErrors &&
            serverSideValidationErrors.map((item, i) => (
              <Alert key={`${item}_${i}`} sx={{ mt: 2.5 }} severity="error">
                {item}
              </Alert>
            ))}

          <p className={LoginStyle["text-center"]}>
            By clicking signing up, you agree to our{" "}
            <Link className={LoginStyle.linkButton} onClick={handleOpen}>
              Terms
            </Link>{" "}
            and{" "}
            <Link className={LoginStyle.linkButton} onClick={handleOpenPrivacy}>
              Privacy Policy
            </Link>
          </p>
          <Button
            variant="contained"
            size="large"
            sx={{ display: "flex", width: "80%", mx: "auto" }}
            onClick={onPressRegister}
          >
            Sign up
          </Button>
          <p className={LoginStyle["text-center"]}>
            Already have an account?{" "}
            <Link to="/login" className={LoginStyle.linkButton}>
              {" "}
              Sign in
            </Link>{" "}
            now
          </p>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: "80%", height: "auto" }}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Terms />
          </Typography>
          <Button
             variant="contained"
             size="large"
             sx={{ display: "flex", mx: "auto" }}
            onClick={handleClose}
          >
            Close
          </Button> 
        </Box>
      </Modal>
      <Modal
        open={openPrivacy}
        onClose={handleClosePrivacy}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: "80%" }}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <PrivatePolicy />
          </Typography>
          <Button
           variant="contained"
           size="large"
           sx={{ display: "flex", mx: "auto" }}
            onClick={handleClosePrivacy}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(Register);
