import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import styles from "../../styles/Components/discover/discover.module.scss";

function DiscoverSortDropdown({ onChangeDropdown, filter }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [dropdownValue, setDropdownValue] = useState(filter);
  const onDropDownChange = (value) => {
    setDropdownValue(value);
    onChangeDropdown(value);
    handleClose();
  };

  return (
    <Box className={styles.discoverSortContainer}>
      <Button
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
        sx={{ textTransform: "capitalize", width: "fit-content", "@media only screen and (max-width: 1200px)": { padding: "5px", fontSize: "13px"}, }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ margin: 3 }}>{`Sort by “${filter}"`}</p>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Box>
      </Button>
      <Menu
        className={styles.discoverSortContainerPopup}
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          selected={dropdownValue === "Recently Published"}
          onClick={() => onDropDownChange("Recently Published")}
        >
          Sort by: “Recently Published”
        </MenuItem>

        <MenuItem
          selected={dropdownValue === "Most popular"}
          onClick={() => onDropDownChange("Most popular")}
        >
          Sort by: “Most Popular”
        </MenuItem>
      </Menu>
    </Box>
  );
}
export default DiscoverSortDropdown;
