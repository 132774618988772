import { Alert, Box, Button, FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { passwordValidationMessage } from "../../common/constants";
import { isPasswordValid } from "../../common/helper";
import CustomInput from "../../components/CustomInput";
import CustomLoader from "../../components/CustomLoader";
import Navbar from "../../components/Navbar";
import {
  getUserDetailsWithForgetPasswordLink,
  updatePasswordAfterForget,
} from "../../store/slices/authenticationSlice";
import LoginStyle from "../../styles/Register.module.scss";

function ResetPassword(props) {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const verification = searchParams.get("verification");
  const key = searchParams.get("key");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { forgetPasswordLinkUserDetaisl } = props;

  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [response, setResponse] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [loading, setLoading] = useState(false);

  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [confrimPasswordValidationMsg, setConfrimPasswordValidationMsg] =
    useState("");
  // eslint-disable-next-line no-unused-vars
  const [isFormValid, setIsFormValid] = useState(false);
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState(
    []
  );
  const onPressForgotPassword = () => {
    setIsExpired(false);
    navigate("/forget-password");
  };
  useEffect(() => {
    setResponse(forgetPasswordLinkUserDetaisl);
  }, [forgetPasswordLinkUserDetaisl]);

  useEffect(() => {
    if (verification && key) {
      const payload = {
        verification,
        key,
      };

      dispatch(getUserDetailsWithForgetPasswordLink(payload))
        .unwrap()
        .then((res) => {
          setLoading(false);
          if (res?.data?.status) {
            // setResponse(res?.data);
            setLoading(false);
          } else if (res?.data?.status === false) {
            if (
              res?.data?.errors &&
              res?.data?.errors.error === "Link Expired"
            ) {
              setIsExpired(true);
            }
            setServerSideValidationErrors(Object.values(res?.data?.errors));
          }
        })
        .catch((err) => {
          console.log("catchError:", err);
        });
    }
  }, [verification, key, dispatch]);

  const passwordValid = (value) => {
    if (isPasswordValid(value)) {
      setPasswordValidationMsg(passwordValidationMessage);
      setIsFormValid(false);
    } else {
      setPasswordValidationMsg("");
      setIsFormValid(true);
    }
  };

  const confirmPasswordValid = (value) => {
    if (isPasswordValid(value)) {
      setConfrimPasswordValidationMsg(passwordValidationMessage);
      setIsFormValid(false);
    } else {
      setConfrimPasswordValidationMsg("");
      setIsFormValid(true);
    }
  };

  const onPressResetPassword = () => {
    const payload = {
      password: newPassword,
      comfirm_password: confirmPassword,
      email: response?.email,
    };
    if (
      !isBtnDisabled
      // && isFormValid
    ) {
      setLoading(true);
      dispatch(updatePasswordAfterForget(payload))
        .unwrap()
        .then((res) => {
          console.log("res", res);
          setLoading(false);
          if (res?.data?.status) {
            setLoading(false);
            navigate("/login");
          } else if (res?.data?.status === false) {
            setServerSideValidationErrors(Object.values(res?.data?.errors));
          }
        })
        .catch((err) => {
          console.log("catchError:", err);
        });
    } else {
      passwordValid(newPassword);
      confirmPasswordValid(confirmPassword);
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    if (
      newPassword &&
      confirmPassword &&
      newPassword === confirmPassword
      //  &&
      // isFormValid
    ) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
      setIsFormValid(false);
    }
  }, [
    newPassword,
    confirmPassword,
    response,
    // isFormValid
  ]);

  return (
    <>
      <Navbar title="Forget Password" />
      <CustomLoader isOpen={loading} />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          sx={{ width: "45em", p: 1, mt: 7 }}
          className={LoginStyle.inputContaier}
        >
          {isExpired ? (
            <Box textAlign="center">
              <h3>Your Password reset link has expired</h3>
              <p>
                The password reset link has expired beacuse it was used, a new
                one was sent or 24 hours has passed since we sent it to you.
              </p>
            </Box>
          ) : (
            <Box textAlign="center">
              <h3>Reset Password</h3>
              <p>
                Use this form to reset your password to a new one. You will then
                be able to login into your account with your new password.
              </p>
            </Box>
          )}
          <>
            {!isExpired && (
              <FormControl fullWidth sx={{ mb: 3, mt: 2 }}>
                <CustomInput
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    passwordValid(e.target.value);
                  }}
                  placeholder="******************************"
                  label="New Password"
                  isInputTypePassword
                  validationMessage={passwordValidationMsg}
                />
              </FormControl>
            )}

            {!isExpired && (
              <FormControl fullWidth sx={{ mb: 3, mt: 2 }}>
                <CustomInput
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    confirmPasswordValid(e.target.value);
                  }}
                  placeholder="******************************"
                  label="Confirm Password"
                  isInputTypePassword
                  validationMessage={confrimPasswordValidationMsg}
                />
              </FormControl>
            )}
            {!isExpired ? (
              <Button
                disabled={!(confirmPassword === newPassword)}
                variant="contained"
                size="large"
                sm={{ display: "flex", width: "40%", mx: "auto", mt: 2 }}
                sx={{ display: "flex", width: "100%", mx: "auto", mt: 2 }}
                onClick={onPressResetPassword}
              >
                Save
              </Button>
            ) : (
              <Button
                disabled={!(confirmPassword === newPassword)}
                variant="contained"
                size="large"
                sm={{ display: "flex", width: "40%", mx: "auto", mt: 2 }}
                sx={{ display: "flex", width: "50%", mx: "auto", mt: 2 }}
                onClick={onPressForgotPassword}
              >
                Reset Password
              </Button>
            )}

            {!isExpired &&
              serverSideValidationErrors &&
              serverSideValidationErrors.map((item, i) => (
                <Alert key={`${item}_${i}`} sx={{ mt: 2.5 }} severity="error">
                  {item}
                </Alert>
              ))}
          </>
        </Box>
      </Box>
    </>
  );
}

function mapStateToProps(state) {
  const { forgetPasswordLinkUserDetaisl } = state.authentication;

  return { forgetPasswordLinkUserDetaisl };
}
export default connect(mapStateToProps)(ResetPassword);
