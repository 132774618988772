/* eslint-disable no-unused-vars */
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Avatar, Box, Button, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import TabPanel from "../../components/Profile/TabPanel";
import { getChannelById } from "../../store/slices/admin/adminAction";
import { authenticationActions } from "../../store/slices/authenticationSlice";
import { subscribeProject } from "../../store/slices/project/projectAction";
import styles from "../../styles/Components/discover/discover.module.scss";
import Discover from "../discover/Discover";

function Channel(props) {
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const currentUser = useSelector((state) => state.authentication.currentUser);
  const channelDetails = useSelector((state) => state.admin.channelDetails);
  const location = useLocation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(getChannelById(id));
  }, []);
  const subscribeHandler = () => {
    const channelId = channelDetails?.id;
    const payload = {
      channel_id: channelId.toString(),
    };
    if (!currentUser) {
      dispatch(authenticationActions.remeberPathForGuest(location.pathname));
      navigate("/login");
    } else {
      dispatch(subscribeProject(payload))
        .then((res) => {
          dispatch(getChannelById(id));
        })
        .catch((err) => {
          console.log("catchError:", err);
        });
    }
  };
  return (
    <>
      <CustomLoader isOpen={loading} />
      <Box sx={{ mt: 6, ml: 2 }} className={styles.projectUserInfo}>
        <Box display="flex" alignItems="center">
          {channelDetails?.image_url ? (
            <Avatar
              sx={{ width: 80, height: 80, mr: 2, mt: 2, color: "lightgray" }}
              alt={channelDetails?.name}
              src={channelDetails?.image_url}
            />
          ) : (
            <AccountCircleIcon
              sx={{ width: 50, height: 50, mr: 2, color: "lightgray" }}
            />
          )}
          <Box>
            <p
              style={{
                textTransform: "capitalize",
                marginBottom: "0.5em",
                fontWeight: "500",
              }}
            >
              {channelDetails?.name ?? ""}
            </p>
            {channelDetails?.subscribe_count > 0 && (
              <p
                style={{
                  textTransform: "capitalize",
                  fontSize: "14px",
                  marginTop: 0,
                }}
              >
                {channelDetails?.subscribe_count}{" "}
                {channelDetails?.subscribe_count === 1
                  ? "subscriber"
                  : "subscribers"}
              </p>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="right" sx={{ mr: 2 }}>
          <Button
            disabled={loading}
            onClick={subscribeHandler}
            variant="contained"
            className={styles.subscribeButton}
          >
            {console.log(channelDetails)}
            {channelDetails.is_subscribed ? "UnSubscribe" : "Subscribe"}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 0, ml: 2, mr: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 0 }}>
          <Tabs className="channel-tabs" value={value} onChange={handleChange}>
            <Tab
              className={styles.channelTab}
              label="Channel"
              sx={{ textTransform: "capitalize" }}
            />
            <Tab
              className={styles.channelTab}
              label="About"
              sx={{ textTransform: "capitalize" }}
            />
          </Tabs>
        </Box>
      </Box>
      <Box sx={{ mt: 0, ml: 0, mr: 0 }}>
        <TabPanel value={value} index={0} className="discovery-tab-channel">
          <Discover channel_id={id} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <>
            <Discover
              channel_id={id}
              desc={channelDetails?.description ?? ""}
            />
          </>
        </TabPanel>
      </Box>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(Channel);
