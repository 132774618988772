import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { signInWithToken } from "../store/slices/authenticationSlice";

function ProtectedRoute({ children }) {
  const dispatch = useDispatch();
  let accessToken = useSelector((state) => state.authentication.accessToken);

  if (!accessToken) {
    accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      dispatch(signInWithToken());
    }
  }

  const location = useLocation();

  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export default ProtectedRoute;
