import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const headers = {
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    Pragma: "no-cache",
    Expires: "0",
  },
};

export const getDiscovers = createAsyncThunk(
  "getDiscovers",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    let filter =
      payload.filter === "Recently Published"
        ? "?field=published_at&order=desc"
        : "?field=hot";
    return await axios.get(
      `${apiUrl}project/discovers${filter}&channel_id=${payload.channel_id}`,
      headers
    );
  }
);
export const likeProject = createAsyncThunk(
  "likeProject",
  async (payload) =>
    await axios.get(`${apiUrl}project/${payload}/like`, headers)
);

export const dislikeProject = createAsyncThunk(
  "dislikeProject",
  async (payload) =>
    await axios.get(`${apiUrl}project/${payload}/dislike`, headers)
);
export const discoverPagination = createAsyncThunk(
  "discoverPagination",
  async (payObj) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      if (payObj.channel_id === 0) {
        return await axios.get(
          `${apiUrl}project/discovers?page=${payObj.id}&size=${payObj.pageSize}${payObj.field}&type=${payObj.type}&value=${payObj.value} `,
          headers
        );
      } else {
        return await axios.get(
          `${apiUrl}project/discovers?page=${payObj.id}&size=${payObj.pageSize}${payObj.field}&type=${payObj.type}&value=${payObj.value}&channel_id=${payObj.channel_id} `,
          headers
        );
      }
    } catch (err) {
      return err?.response;
    }
  }
);

export const discoverSearch = createAsyncThunk(
  "discoverSearch",
  async (payload) => {
    console.log("gssssg");
    console.log(payload);
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}project/discover-search`,
        payload,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const discoverSearchPagination = createAsyncThunk(
  "discoverSearchPagination",
  async (payload) => {
    console.log("gqg");
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}project/discover-search?page=${payload.id}`,
        payload.data,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const discoverFilter = createAsyncThunk(
  "discoverFilter",
  async (payload) => {
    console.log("gg");
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return await axios.get(
      `${apiUrl}project/discovers${payload?.fiterData}`,
      headers
    );
  }
);
