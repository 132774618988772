import BookIcon from "@mui/icons-material/Book";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import configData from "../configData.json";
import DrawerNavbar from "./DrawerNavbar";

export default function Sidebar({ toggleDrawer }) {
  const [open, setOpen] = React.useState(false);
  const [isAdministrationOpen, setIsAdministrationOpen] = useState(false);
  const [isSubscriptionOpen, setIsSubscriptionOpen] = useState(false);
  const [isMyAccountOpen, setIsMyAccountOpen] = useState(false);
  const currentUser = useSelector((state) => state.authentication.currentUser);
  const hasAdminPermission = configData.administraion.includes(
    currentUser?.role_id
  );
  const hasProjectPermission = configData.projects.includes(
    currentUser?.role_id
  );
  const canBecomeCreator = configData.become_creator.includes(
    currentUser?.role_id
  );
  const location = useLocation();
  useEffect(() => {
    switch (location.pathname) {
      case "/profile":
      case "/become-creator":
        setIsMyAccountOpen(true);
        break;
      case "/subscription":
        setIsSubscriptionOpen(true);
        break;
      case "/users":
      case "/category":
        setIsAdministrationOpen(true);
        break;
      case "/my-projects":
      case "/create-project":
        setOpen(true);
        break;
      default:
    }
  }, [location.pathname]);

  return (
    <Box role="presentation">
      <DrawerNavbar toggleDrawer={toggleDrawer} />
      <List>
        {hasProjectPermission && (
          <ListItem disablePadding>
            <ListItemButton onClick={() => setOpen(!open)}>
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary="Projects" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
        )}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link
              to="my-projects"
              className={`sidebarLink ${
                location.pathname === "/my-projects" && "active"
              }`}
            >
              <ListItemButton sx={{ pl: 10 }}>
                <ListItemText primary="My Projects" />
              </ListItemButton>
            </Link>
            <Link
              to="create-project"
              className={`sidebarLink ${
                location.pathname === "/create-project" && "active"
              }`}
            >
              <ListItemButton sx={{ pl: 10 }}>
                <ListItemText primary="Create Project" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        {hasAdminPermission && (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => setIsAdministrationOpen(!isAdministrationOpen)}
            >
              <ListItemIcon sx={{ pl: 0 }}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Administration" />
              {isAdministrationOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
        )}
        <Collapse in={isAdministrationOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link
              to="users"
              className={`sidebarLink ${
                location.pathname === "/users" && "active"
              }`}
            >
              <ListItemButton sx={{ pl: 10, background: "#F0F0F0" }}>
                <ListItemText primary="Users" />
              </ListItemButton>
            </Link>
            <Link
              to="category"
              className={`sidebarLink ${
                location.pathname === "/category" && "active"
              }`}
            >
              <ListItemButton sx={{ pl: 10, background: "#F0F0F0" }}>
                <ListItemText primary="Categories" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <Link
          to="discover"
          className={`sidebarLink sidebarParent ${
            (location.pathname === "/discover" || location.pathname === "/") &&
            "active"
          }`}
        >
          <ListItemButton onClick={() => {}}>
            <ListItem disablePadding>
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText primary="Discover" />
            </ListItem>
          </ListItemButton>
        </Link>
        {currentUser && (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => setIsSubscriptionOpen(!isSubscriptionOpen)}
            >
              <ListItemIcon>
                <BookmarksIcon />
              </ListItemIcon>
              <ListItemText primary="Subscriptions" />
              {isSubscriptionOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
        )}
        {currentUser && (
          <Collapse in={isSubscriptionOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to="subscription"
                className={`sidebarLink ${
                  location.pathname === "/subscription" && "active"
                }`}
              >
                <ListItemButton sx={{ pl: 10 }}>
                  <ListItemText primary="My Subscriptions" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        )}
        {currentUser && (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => setIsMyAccountOpen(!isMyAccountOpen)}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="My Account" />
              {isMyAccountOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
        )}
        <Collapse in={isMyAccountOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {canBecomeCreator && (
              <Link
                to="become-creator"
                className={`sidebarLink ${
                  location.pathname === "/become-creator" && "active"
                }`}
              >
                <ListItemButton sx={{ pl: 10 }}>
                  <ListItemText primary="Become a creator" />
                </ListItemButton>
              </Link>
            )}
            <Link
              to="profile"
              className={`sidebarLink ${
                location.pathname === "/profile" && "active"
              }`}
            >
              <ListItemButton sx={{ pl: 10 }}>
                <ListItemText primary="Profile" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
      </List>
    </Box>
  );
}
