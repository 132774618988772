import { create } from "zustand";

const useSystemStore = create((set) => ({
  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
  isIos:
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document),
  isFullScreen: false,
  viewportDimension: {
    width: window.innerWidth,
    height: window.innerHeight,
  },

  setIsFullScreen: (isFullScreen) =>
    set({
      isFullScreen,
    }),
  setViewportDimension: (viewportDimension) =>
    set({
      viewportDimension,
    }),
}));

export default useSystemStore;
