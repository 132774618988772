import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import DiscoverItem from "../../components/Discover/DiscoverItem";
import Navbar from "../../components/Navbar";
import { getSubscription } from "../../store/slices/project/projectAction";

function Subscription(props) {
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subscription } = props;

  const [todaysData, setTodaysData] = useState([]);
  const [thisMonth, setThisMonth] = useState([]);
  const [thisWeek, setThisWeek] = useState([]);
  const [older, setOlder] = useState([]);

  const [loading, setLoading] = useState(false);

  const getDiscoverData = () => {
    setLoading(true);
    dispatch(getSubscription())
      .unwrap()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log("catchError:", err);
      });
  };

  useEffect(() => {
    getDiscoverData();
  }, [dispatch]);

  useEffect(() => {
    setThisMonth(subscription?.this_month);
    setTodaysData(subscription?.todays);
    setThisWeek(subscription?.this_week);
    setOlder(subscription?.older);
  }, [subscription]);

  return (
    <>
      <Navbar
        title="My Subscriptions"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <Grid container item xs={12}>
        <CustomLoader isOpen={loading} />
        <>
          <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box>
                <p style={{ fontSize: 20, paddingLeft: 16 }}>Today</p>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {todaysData &&
                    todaysData.map((item, i) => (
                      <DiscoverItem key={`${item?.title}_${i}`} {...item} />
                    ))}
                </Box>
              </Box>
              <Box>
                <p style={{ fontSize: 20, paddingLeft: 16 }}>This week</p>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {thisWeek &&
                    thisWeek.map((item, i) => (
                      <DiscoverItem key={`${item?.title}_${i}`} {...item} />
                    ))}
                </Box>
              </Box>
              <Box>
                <p style={{ fontSize: 20, paddingLeft: 16 }}>This month</p>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {thisMonth &&
                    thisMonth.map((item, i) => (
                      <DiscoverItem key={`${item?.title}_${i}`} {...item} />
                    ))}
                </Box>
              </Box>
              <Box>
                <p style={{ fontSize: 20, paddingLeft: 16 }}>Older</p>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {older &&
                    older.map((item, i) => (
                      <DiscoverItem key={`${item?.title}_${i}`} {...item} />
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { subscription } = state.project;
  return { subscription };
}
export default connect(mapStateToProps)(Subscription);
