import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "../../../styles/MyProject.module.scss";

function FlipBookHeader({ project }) {
  let status = "";
  if (project?.files_count > 0 && project?.is_published === 1) {
    status = "Published";
  } else if (project?.files_count > 0 && project?.is_published === 0) {
    status = "Uploaded ";
  } else if (project?.files_count === 0 && project?.is_published === 0) {
    status = "Created";
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ mr: 1.2 }}>
        {/*  */}
        <div className={styles.projectTitleImage}>
          {status === "Created" ? (
            <img
              src="/assets/PlaceholderProjectListing.png"
              alt="projectListingPlaceholder"
            />
          ) : (
            <img
              style={{ mr: 2 }}
              src={project?.croppedfiles[0]?.image_url}
              alt="projectListingPlaceholder"
            />
          )}
        </div>
      </Box>
      <Box>
        <Link
          style={{
            textTransform: "capitalize",
            color: "#111111",
            textDecoration: "none",
            fontSize: 16,
          }}
          to={`/project/${project?.id}/edit`}
        >
          {project?.title}
        </Link>
        <Link
          style={{ display: "block", marginTop: "0.3em" }}
          to={`/discover-details/${project?.id}`}
        >
          <InsertLinkIcon
            sx={{ transform: "rotate(130deg)", color: "#111111" }}
          />
        </Link>
        <span style={{ fontSize: 14 }}>{project?.files_count} Pages</span>

        <div style={{ fontSize: 12, marginTop: 4 }}>
          {}
          {status}
        </div>
      </Box>
    </Box>
  );
}

export default FlipBookHeader;
