/* eslint-disable react-hooks/exhaustive-deps */
import { useDroppable } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { useEffect, useState } from "react";
import CustomLoader from "../../CustomLoader";
import SortableItem from "./SortableItem";

function Droppable({ items }) {
  const { setNodeRef } = useDroppable({ id: "unique-id" });
  const groupItems = [...items];
  let sort_order = 1;
  const groupFiles = groupItems.map((i, index) => {
    const item = { ...i };
    if (typeof item.id !== "number") {
      item.ind = "";
    } else if (index === 0) {
      item.ind = "Cover";
    } else if (index === groupItems.length - 1) {
      item.ind = "Back Cover";
    } else {
      item.ind = sort_order;
      sort_order++;
    }
    return item;
  });
  const droppableStyle = {
    display: "flex",
    flexWrap: "wrap",
    touchAction: "none",
  };
  const [cnt, setCnt] = useState(0);
  const [visibility, setVisibilty] = useState("hidden");
  const [loading, setLoading] = useState(false);
  const onLoad = () => {
    setCnt((p) => p + 1);
  };
  useEffect(() => {
    setLoading(true);
    if (cnt > 0 && cnt >= groupFiles.length) {
      setLoading(false);
      setVisibilty("visible");
    }
  }, [cnt]);
  return (
    <SortableContext items={items} strategy={rectSortingStrategy}>
      <CustomLoader isOpen={loading} />
      <div ref={setNodeRef} style={droppableStyle}>
        {groupFiles &&
          groupFiles.map((item, i) => (
            <SortableItem
              visibility={visibility}
              onload={onLoad}
              key={item.id}
              id={item}
              index={i}
              end={items.length - 1}
            />
          ))}
      </div>
    </SortableContext>
  );
}

export default Droppable;
