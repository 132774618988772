import { Alert, Box, Button, FormControl, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";
import { channelValidationMessage } from "../../common/constants";
import CustomInput from "../../components/CustomInput";
import CustomLoader from "../../components/CustomLoader";
import ImgUploadPlaceholder from "../../components/MyAccount/ImgUploadPlaceholder";
import Navbar from "../../components/Navbar";
import configData from "../../configData.json";
import {
  createChannel,
  getChannel,
  updateChannel,
} from "../../store/slices/admin/adminAction";
import {
  authenticationActions,
  signInWithToken,
} from "../../store/slices/authenticationSlice";

function BecomeCreator(props) {
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const dispatch = useDispatch();
  const isChannel = props?.channel;

  const [file, setFile] = useState(null);
  const [channeId, setChanneId] = useState("");
  const [channel, setChannellocal] = useState("");
  const [description, setDescription] = useState("");
  const [imgPath, setImgPath] = useState("");

  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [appliedForCreatorOrCreator, setAppliedForCreatorOrCreator] =
    useState(false);

  const [channelValidationMsg, setChannelValidationMsg] = useState("");
  const [channelDescription, setChannelDescription] = useState("");
  const currentUser = useSelector((state) => state.authentication.currentUser);
  useEffect(() => {
    dispatch(signInWithToken());
  }, [dispatch]);
  useEffect(() => {
    if (isChannel) {
      getChannelData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChannel]);

  const getChannelData = () => {
    setLoading(true);
    dispatch(getChannel())
      .unwrap()
      .then((res) => {
        setLoading(false);
        setChanneId(res?.data?.data?.id);
        setChannellocal(res?.data?.data?.name ?? "");
        setDescription(res?.data?.data?.description ?? "");
        setImgPath(res?.data?.data?.image_url ?? "");
      })
      .catch((err) => {
        console.log("channel error:", err);
      });
  };

  const channelValid = (value) => {
    if (value.length === 0) {
      setChannelValidationMsg(channelValidationMessage);
    } else {
      setChannelValidationMsg("");
    }
  };

  const channelDescriptionValid = (value) => {
    setChannelDescription("");
  };

  const applied_for_creator = useSelector(
    (state) => state.authentication?.currentUser?.applied_for_creator
  );

  useEffect(() => {
    setAppliedForCreatorOrCreator(applied_for_creator !== 0);
  }, [applied_for_creator]);
  const isAdmin = configData.administraion.includes(currentUser?.role_id);
  const onPressCreateChannel = () => {
    const formdata = new FormData();
    formdata.append("name", channel);
    formdata.append("description", description);
    if (file) {
      formdata.append("image_path", file);
    }

    // if channelId is exist, then call update else call create
    if (channeId) {
      setLoading(true);
      formdata.append("_method", "PATCH");
      const payload = {
        id: channeId,
        data: formdata,
      };

      dispatch(updateChannel(payload))
        .unwrap()
        .then((res) => {
          setLoading(false);
          if (res?.data?.status) {
            if (props.setChannel) {
              props.setChannel(false);
            }
            setLoading(false);
            setToastMessage("Channel Updated successfully!");
            if (currentUser.applied_for_creator !== 2) {
              dispatch(authenticationActions.setAppliedforBeingCreator());
            }

            setIsToastOpen(true);
          } else {
            setLoading(false);
            if (currentUser.applied_for_creator !== 2) {
              setAppliedForCreatorOrCreator(false);
            }
            if (res?.data?.errors) {
              setToastMessage(Object.values(res?.data?.errors));
            }

            setAlertSeverity("error");
            setIsToastOpen(true);
          }
        })
        .catch((err) => {
          console.log("channel error:", err);
          setLoading(false);
        });
    } else if (!isBtnDisabled) {
      setLoading(true);

      dispatch(createChannel(formdata))
        .unwrap()
        .then((res) => {
          setLoading(false);
          if (res?.data?.status) {
            if (props.setChannel) {
              props.setChannel(false);
            }
            setChanneId(res?.data?.data?.id);
            setToastMessage("Channel created successfully!");
            if (currentUser.applied_for_creator !== 2) {
              dispatch(authenticationActions.setAppliedforBeingCreator());
            }
            setIsToastOpen(true);
          } else {
            if (currentUser.applied_for_creator !== 2) {
              setAppliedForCreatorOrCreator(false);
            }
            setToastMessage(
              "Channel Name already Present! Please Select Other Name"
            );
            setAlertSeverity("error");
            setIsToastOpen(true);
          }
        })
        .catch((err) => {
          console.log("channel error:", err);
          setLoading(false);
        });
    } else {
      channelValid(channel);
      channelDescriptionValid(description);
    }
  };

  useEffect(() => {
    if (channel && description) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [channel, description]);

  const onChangeFile = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setImgPath(URL.createObjectURL(e.target.files[0]));
      if (props.setChannel) {
        props.setChannel(true);
      }
    }
  };

  return (
    <>
      {!props?.hideNavbar && (
        <Navbar
          title="My Account"
          isDrawerOpen={openDrawer}
          toggleDrawer={() => setOpenDrawer(!openDrawer)}
        />
      )}
      <CustomLoader isOpen={loading} />

      {!appliedForCreatorOrCreator || isAdmin || props.hideNavbar ? (
        <Box
          sx={
            currentUser.role_id === 3
              ? { padding: "24px", mt: 5 }
              : {
                  width: "100%",
                  mt: 2,
                }
          }
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              alignItems: "center",
              width: "60%",
              mb: 2,
              "@media only screen and (max-width: 768px)": {
                width: "100%",
              },
            }}
          >
            <Box sx={{ mr: 2 }}>
              <Box>
                {imgPath ? (
                  <ImgUploadPlaceholder
                    onChangeFile={onChangeFile}
                    imgPath={imgPath}
                  />
                ) : (
                  <ImgUploadPlaceholder
                    onChangeFile={onChangeFile}
                    imgPath="/assets/Avatar.png"
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "60%",
              "@media only screen and (max-width: 768px)": {
                width: "100%",
              },
            }}
          >
            <FormControl fullWidth sx={{ mb: 3, mt: 2 }}>
              <CustomInput
                value={channel}
                onChange={(e) => {
                  setChannellocal(e.target.value);
                  channelValid(e.target.value);
                  if (props.setChannel) {
                    props.setChannel(true);
                  }
                }}
                placeholder="Please input your channel name"
                label="Your Channel Name"
                validationMessage={channelValidationMsg}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 3, mt: 2 }}>
              <CustomInput
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  channelDescriptionValid(e.target.value);
                  if (props.setChannel) {
                    props.setChannel(true);
                  }
                }}
                placeholder="Tell viewers about your channel"
                label="Your Channel Description"
                isMultiline
                validationMessage={channelDescription}
              />
            </FormControl>
          </Box>
          <Snackbar
            open={isToastOpen}
            autoHideDuration={6000}
            onClose={() => setIsToastOpen(false)}
          >
            <Alert
              onClose={() => setIsToastOpen(false)}
              severity={alertSeverity}
              sx={{ width: "100%" }}
            >
              {toastMessage}
            </Alert>
          </Snackbar>

          <Box
            sx={{ width: "100%", mb: 1 }}
            display="flex"
            justifyContent="flex-end"
          >
            <Button variant="contained" onClick={onPressCreateChannel}>
              Apply
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <p style={{ fontSize: 18 }}>
            {currentUser.role_id === 4
              ? "You are already Creator"
              : "Application submitted, please wait for approval"}
          </p>
        </Box>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { channel } = state?.authentication?.currentUser ?? {};
  return { channel };
}
export default connect(mapStateToProps)(BecomeCreator);
