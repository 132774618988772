import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import FlipbookComponent from "../../components/Discover/Flipbook/Flipbook";
import { getProjectById } from "../../store/slices/project/projectAction";

function DiscoverDetailsWithIframe(props) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [showFlipBook, setShowFlipBook] = useState(0);
  const { projectDetails } = props;

  const [projectDetailData, setProjectDetailData] = useState({});

  // generic state's
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getProjectById(id))
      .unwrap()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [id]);
  const isSelfDesignAndBackCoverReqAppend = useSelector(
    (state) =>
      state.project.projectDetails.design_type === "self-design" &&
      state.project.projectDetails.layout_type === "back_cover" &&
      state.project.projectDetails.croppedfiles.length % 2 !== 0
  );
  useEffect(() => {
    const isLayFlat =
      projectDetails.design_type === "photobook" ||
      projectDetails.design_type === "layflat photobook";
    const isSelfDesignAndFrontCover =
      projectDetails.design_type === "self-design" &&
      projectDetails.layout_type === "front_cover";
    const projectData = { ...projectDetails };

    let filesAsPerLayout = projectDetails?.croppedfiles
      ? [...projectDetails?.croppedfiles]
      : [];
    filesAsPerLayout = filesAsPerLayout.filter((item) => item.is_cropped === 1);

    if (
      isLayFlat ||
      isSelfDesignAndFrontCover ||
      isSelfDesignAndBackCoverReqAppend
    ) {
      const n1 = [...filesAsPerLayout];
      const nn = { ...n1[0] };
      nn.id = "unsortable-layflat";
      nn.image_url = projectDetails?.grey ?? "../../assets/layflat.png";
      if (!isSelfDesignAndBackCoverReqAppend) {
        nn.sort_order = null;
        n1.splice(1, 0, nn);
      }
      const nn1 = { ...nn };
      nn1.id = "unsortable-front";
      n1.splice(n1.length - 1, 0, nn1);
      filesAsPerLayout = n1;
    }
    projectData.files = filesAsPerLayout;
    setProjectDetailData(projectData);
  }, [projectDetails]);

  const handleShowFlip = () => {
    setShowFlipBook(0);
    setTimeout(() => {
      setShowFlipBook(1);
    }, 1000);
  };
  useEffect(() => {
    setTimeout(() => {
      setShowFlipBook(1);
    }, 1000);
  }, []);
  const [showFlipBookWithModal, setShoeFlipBookWithModal] = useState(1);

  return (
    <div className="wrapper">
      <Grid p={2} container item xs={12}>
        <CustomLoader isOpen={loading} />
        {showFlipBookWithModal && (
          <Box sx={{ width: "100%" }}>
            {showFlipBook === 1 && (
              <FlipbookComponent
                {...projectDetailData}
                handleShowFlip={handleShowFlip}
              />
            )}
          </Box>
        )}
      </Grid>
    </div>
  );
}

function mapStateToProps(state) {
  const { projectDetails } = state.project;
  return { projectDetails };
}
export default connect(mapStateToProps)(DiscoverDetailsWithIframe);
