import { createBrowserRouter } from "react-router-dom";
import DeleteDataInstructions from "../components/DeleteDataInstructions";
import PrivatePolicy from "../components/privatePolicy";
import Terms from "../components/terms";
import AdminProtected from "../middlewares/adminProtected";
import AlreadyLoggedIn from "../middlewares/alreadyLoggedin";
import ProjectAccess from "../middlewares/projectAccess";
import ProtectedRoute from "../middlewares/protectedRoute";
import RestrictBecomeCreator from "../middlewares/restrictBEcomeCreator";
import AccessDenied from "../pages/access-denied";
import Category from "../pages/administration/Category/Category";
import CreateCategory from "../pages/administration/Category/CreateCategory";
import EditCategory from "../pages/administration/Category/EditCategory";
import CreateUser from "../pages/administration/CreateUser";
import EditUser from "../pages/administration/EditUser";
import EmailVerfication from "../pages/administration/EmailVerfication";
import UserScreen from "../pages/administration/UserScreen";
import ConfirmEmail from "../pages/auth/ConfirmEmail";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/Register";
import ResetPassword from "../pages/auth/ResetPassword";
import Channel from "../pages/channel/Channel";
import Discover from "../pages/discover/Discover";
import DiscoverDetails from "../pages/discover/DiscoverDetails";
import DiscoverDetailsWithIframe from "../pages/discover/DiscoverDetailsWithIframe";
import ErrorPage from "../pages/error";
import Index from "../pages/index";
import BecomeCreator from "../pages/myAccount/BecomeCreator";
import NotFound from "../pages/notfound";
import Profile from "../pages/profile/Profile";
import ChooseLayout from "../pages/projects/ChooseLayout";
import Congratulations from "../pages/projects/Congratulations";
import CreateProject from "../pages/projects/CreateProject";
import EditProject from "../pages/projects/EditProject";
import MyProjects from "../pages/projects/MyProjects";
import RearrangeNew from "../pages/projects/RearrangeNew";
import RedirectUrl from "../pages/redirecturl";
import Root from "../pages/root";
import Subscription from "../pages/subscription/Subscription";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Index />,
      },
      {
        path: "users",
        element: (
          <ProtectedRoute>
            <AdminProtected>
              <UserScreen />
            </AdminProtected>
          </ProtectedRoute>
        ),
      },
      {
        path: "create-user",
        element: (
          <ProtectedRoute>
            <AdminProtected>
              <CreateUser />
            </AdminProtected>
          </ProtectedRoute>
        ),
      },
      {
        path: "edit-user/:id",
        element: (
          <ProtectedRoute>
            <AdminProtected>
              <EditUser />
            </AdminProtected>
          </ProtectedRoute>
        ),
      },
      {
        path: "create-category",
        element: (
          <ProtectedRoute>
            <AdminProtected>
              <CreateCategory />
            </AdminProtected>
          </ProtectedRoute>
        ),
      },
      {
        path: "category",
        element: (
          <ProtectedRoute>
            <AdminProtected>
              <Category />
            </AdminProtected>
          </ProtectedRoute>
        ),
      },
      {
        path: "edit-category/:id",
        element: (
          <ProtectedRoute>
            <AdminProtected>
              <EditCategory />
            </AdminProtected>
          </ProtectedRoute>
        ),
      },
      {
        path: "become-creator",
        element: (
          <ProtectedRoute>
            <RestrictBecomeCreator>
              <BecomeCreator />
            </RestrictBecomeCreator>
          </ProtectedRoute>
        ),
      },
      {
        path: "create-project",
        element: (
          <ProtectedRoute>
            <ProjectAccess>
              <CreateProject />
            </ProjectAccess>
          </ProtectedRoute>
        ),
      },
      {
        path: "my-projects",
        element: (
          <ProtectedRoute>
            <ProjectAccess>
              <MyProjects />
            </ProjectAccess>
          </ProtectedRoute>
        ),
      },
      {
        path: "project/:id/upload",
        element: (
          <ProtectedRoute>
            <ProjectAccess>
              <ChooseLayout />
            </ProjectAccess>
          </ProtectedRoute>
        ),
      },
      {
        path: "project/:id/edit",
        element: (
          <ProtectedRoute>
            <ProjectAccess>
              <EditProject />
            </ProjectAccess>
          </ProtectedRoute>
        ),
      },
      {
        path: "project/:id/rearrange",
        element: (
          <ProtectedRoute>
            <RearrangeNew />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
      },
      {
        path: "channel/:id",
        element: <Channel />,
      },
      {
        path: "congratulations",
        element: <Congratulations />,
      },
      {
        path: "discover",
        element: <Discover />,
      },
      {
        path: "discover-details/:id",
        element: <DiscoverDetails />,
      },
      {
        path: "discover-details-with-iframe/:id",
        element: <DiscoverDetailsWithIframe />,
      },
      {
        path: "subscription",
        element: (
          <ProtectedRoute>
            <Subscription />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "verify-email",
    element: <EmailVerfication />,
  },
  {
    path: "/register",
    element: (
      <AlreadyLoggedIn>
        <SignUp />
      </AlreadyLoggedIn>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: (
      <AlreadyLoggedIn>
        <Login />
      </AlreadyLoggedIn>
    ),
  },
  {
    path: "/forget-password",
    element: (
      <AlreadyLoggedIn>
        <ForgotPassword />
      </AlreadyLoggedIn>
    ),
  },
  {
    path: "/confirm-email",
    element: <ConfirmEmail />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/aceess-denied",
    element: <AccessDenied />,
  },
  {
    path: "/privacy",
    element: <PrivatePolicy />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/delete-data-instructions",
    element: <DeleteDataInstructions />,
  },
  {
    path: "/redirect-url",
    element: (
      <ProtectedRoute>
        <RedirectUrl />
      </ProtectedRoute>
    ),
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
