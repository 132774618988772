import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const headers = {
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

export const getRoles = createAsyncThunk("getRoles", async () => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return await axios.get(`${apiUrl}admin/role`, headers);
});

export const roleSlice = createSlice({
  name: "role",
  initialState: {
    roles: [],
  },

  reducers: {},
  extraReducers: (builder) => {
    // get roles
    builder.addCase(getRoles.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.loader = false;
      state.roles = action.payload.data.data;
    });
    builder.addCase(getRoles.rejected, (state) => {
      state.loader = false;
    });
  },
});

export default roleSlice.reducer;
