import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  Modal,
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { passwordValidationMessage } from "../../common/constants";
import { isPasswordValid } from "../../common/helper";
import {
  signInWithToken,
  updateEmailFromProfile,
  verifyOtpforChangeEmail,
} from "../../store/slices/authenticationSlice";
import CustomInput from "../CustomInput";
import CustomLoader from "../CustomLoader";

function EmailForm({ currentUser, setChannel }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [serverSideValidationErrors, setServerSideValidationErrors] =
    useState();
  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [visibility, setVisibility] = useState(false);
  useEffect(() => {
    if (email && password && passwordValidationMsg === "") {
      setIsBtnDisabled(false);
      setServerSideValidationErrors();
    } else {
      setIsBtnDisabled(true);
    }
  }, [email, password, passwordValidationMsg]);

  const updateEmail = () => {
    setLoading(true);
    const payload = {
      email,
      password,
    };

    dispatch(updateEmailFromProfile(payload))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res?.data?.errors) {
          setServerSideValidationErrors(Object.values(res?.data?.errors));
        } else if (res?.data?.status) {
          setChannel(false);
          setVisibility(true);
          dispatch(signInWithToken());
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("catchError:", err);
      });
  };

  const passwordValid = (value) => {
    if (isPasswordValid(value)) {
      setPasswordValidationMsg(passwordValidationMessage);
    } else {
      setPasswordValidationMsg("");
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    "@media only screen and (max-width: 768px)": { width: "80%" },
  };
  const [otp, setOtp] = useState();
  const handleClose = () => {
    setVisibility(false);
    setServerSideValidationErrors();
  };
  const verifyOtp = () => {
    setLoading(true);
    const payload = {
      email,
      password,
      otp,
    };

    dispatch(verifyOtpforChangeEmail(payload))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res?.data?.errors) {
          setServerSideValidationErrors(Object.values(res?.data?.errors));
        }
        if (res?.data?.status) {
          dispatch(signInWithToken());
          setVisibility(false);
          setEmail(payload.email);
          setPassword("");
        }
        setOtp();
      })
      .catch((err) => {
        console.log("catchError:", err);
      });
  };
  return (
    <>
      <CustomLoader isOpen={loading} />
      <Box
        sx={{
          width: "100%",
          pt: 2,
        }}
      >
        <Box
          sx={{
            width: "60%",
            mb: 2,
            "@media only screen and (max-width: 768px)": {
              width: "100%",
            },
          }}
        >
          <p>Current Email Address </p>
          <p
            style={{
              marginRight: "1.5em",
              color: "#AAAAAA",
              marginLeft: "1em",
            }}
          >
            {currentUser?.email}
          </p>
        </Box>
        <Box
          sx={{
            width: "60%",
            "@media only screen and (max-width: 768px)": {
              width: "100%",
            },
          }}
        >
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <CustomInput
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setChannel(true);
              }}
              placeholder="Please input the new email address"
              label="New Email Address"
              autoComplete="off"
              // validationMessage={"not valid"}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <CustomInput
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                passwordValid(e.target.value);
                setChannel(true);
              }}
              placeholder="Please input you current password"
              label="Password"
              isInputTypePassword
              validationMessage={passwordValidationMsg}
              autoComplete="off"
            />
          </FormControl>
          {!visibility &&
            serverSideValidationErrors &&
            serverSideValidationErrors.map((item, i) => (
              <Alert key={`${item}_${i}`} sx={{ mt: 2.5 }} severity="error">
                {item}
              </Alert>
            ))}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            mt: 1,
          }}
        >
          <Button
            disabled={isBtnDisabled}
            variant="contained"
            onClick={updateEmail}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Modal
        open={visibility}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ fontSize: 28 }} />
            </IconButton>
          </Box>
          {serverSideValidationErrors &&
            serverSideValidationErrors.map((item, i) => (
              <Alert key={`${item}_${i}`} sx={{ mt: 1.5 }} severity="error">
                {item}
              </Alert>
            ))}
          {!serverSideValidationErrors && (
            <span>
              Hi, <br /> <br />
              Please find below your one time PIN for changing email address in
              the Flipping Book App{" "}
            </span>
          )}
          <FormControl fullWidth sx={{ mb: 3, mt: 2 }}>
            <CustomInput
              value={otp}
              placeholder="Please Enter OTP received"
              onChange={(e) => {
                setOtp(e.target.value);
                setServerSideValidationErrors();
              }}
            />
          </FormControl>
          <Button
            variant="contained"
            size="large"
            sx={{ display: "flex", width: "100%", mx: "auto", mt: 2 }}
            onClick={verifyOtp}
            disabled={serverSideValidationErrors}
          >
            Verify
          </Button>
        </Box>
      </Modal>
    </>
  );
}

function mapStateToProps(state) {
  const currentUser = state.authentication?.currentUser;
  return {
    currentUser,
  };
}
export default connect(mapStateToProps)(EmailForm);
