import { createSlice } from "@reduxjs/toolkit";
import {
  approveUserById,
  createChannel,
  deleteUserById,
  fetchEditUserData,
  getChannel,
  getChannelById,
  getUsers,
  lockUserById,
  register,
  updateChannel,
  updateUser,
  userPagination,
  userSearch,
  userSearchPagination,
} from "./admin/adminAction";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    users: [],
    userDetails: {},
    loader: false,
    status: false,
    pagination: {},
    channel: {},
    channelDetails: {},
  },

  reducers: {
    setNull(state, action) {
      state.users = [];
    },
  },
  extraReducers: (builder) => {
    // get users
    builder.addCase(getUsers.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.loader = false;
      state.fetched = true;
      state.users = action.payload.data.data;
      state.pagination = action.payload?.data?.meta;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.loader = false;
    });

    // pagination
    builder.addCase(userPagination.pending, (state) => {});
    builder.addCase(userPagination.fulfilled, (state, action) => {
      state.users = action.payload.data.data;
      state.pagination = action.payload?.data?.meta;
    });
    builder.addCase(userPagination.rejected, (state) => {});

    // userSearchPagination
    builder.addCase(userSearchPagination.pending, (state) => {});
    builder.addCase(userSearchPagination.fulfilled, (state, action) => {
      state.users = action.payload.data.data;
      state.pagination = action.payload?.data?.meta;
    });
    builder.addCase(userSearchPagination.rejected, (state) => {});

    // user search
    builder.addCase(userSearch.pending, (state) => {});
    builder.addCase(userSearch.fulfilled, (state, action) => {
      state.users = action.payload.data.data;
      state.pagination = action.payload?.data?.meta;
    });
    builder.addCase(userSearch.rejected, (state) => {});

    // Delete user by id
    builder.addCase(deleteUserById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(deleteUserById.fulfilled, (state, action) => {
      const userData = action.payload.data;
      const users = JSON.parse(JSON.stringify(state.users));
      const userIndex = users.findIndex(
        (element) => element.id === parseInt(userData?.data?.user_id)
      );
      if (userIndex !== -1) {
        users.splice(userIndex, 1);
      }
      state.users = users;
      state.loader = false;
      state.status = true;
    });

    // Register user
    builder.addCase(register.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      const users = JSON.parse(JSON.stringify(state.users));
      if (action.payload.data.data) {
        state.users = [action.payload.data.data, ...users];
      }
      state.loader = false;
      state.status = true;
    });
    builder.addCase(register.rejected, (state) => {
      state.loader = false;
    });

    // fetch Edit user Data
    builder.addCase(fetchEditUserData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(fetchEditUserData.fulfilled, (state, action) => {
      state.userDetails = action.payload.data.data;
      state.loader = false;
    });
    builder.addCase(fetchEditUserData.rejected, (state) => {
      state.loader = false;
    });

    // Update user
    builder.addCase(updateUser.pending, (state) => {
      // state.loader = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const userData = action.payload.data;
      const users = JSON.parse(JSON.stringify(state.users));
      const userIndex = users.findIndex(
        (element) => element.id === userData?.data?.id
      );
      if (userIndex !== -1) {
        users[userIndex] = userData?.data;
      }
      state.users = users;
      state.loader = false;
      state.status = true;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.loader = false;
    });

    // Lock user by id
    builder.addCase(lockUserById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(lockUserById.fulfilled, (state, action) => {
      const userData = action.payload.data;
      const users = JSON.parse(JSON.stringify(state.users));
      const userIndex = users.findIndex(
        (element) => element.id === userData?.data?.id
      );
      if (userIndex !== -1) {
        users[userIndex] = userData?.data;
      }
      state.users = users;
      state.loader = false;
    });
    builder.addCase(lockUserById.rejected, (state) => {
      state.loader = false;
    });

    // approve user by id
    builder.addCase(approveUserById.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(approveUserById.fulfilled, (state, action) => {
      const userData = action.payload.data;
      const users = JSON.parse(JSON.stringify(state.users));
      const userIndex = users.findIndex(
        (element) => element.id === userData?.data?.id
      );
      if (userIndex !== -1) {
        users[userIndex] = userData?.data;
      }
      state.users = users;
      state.loader = false;
      state.status = true;
    });
    builder.addCase(approveUserById.rejected, (state) => {
      state.loader = false;
    });

    // Get channel data
    builder.addCase(getChannel.pending, (state) => {});
    builder.addCase(getChannel.fulfilled, (state, action) => {
      state.channel = action.payload.data.data;
    });
    builder.addCase(getChannel.rejected, (state) => {});

    // Get channel data
    builder.addCase(getChannelById.pending, (state) => {});
    builder.addCase(getChannelById.fulfilled, (state, action) => {
      state.channelDetails = action.payload.data.data;
    });
    builder.addCase(getChannelById.rejected, (state) => {});

    // createChannel
    builder.addCase(createChannel.pending, (state) => {});
    builder.addCase(createChannel.fulfilled, (state, action) => {
      state.channelDetails = action.payload.data.data;
    });
    builder.addCase(createChannel.rejected, (state) => {});

    // update channel
    builder.addCase(updateChannel.pending, (state) => {});
    builder.addCase(updateChannel.fulfilled, (state, action) => {
      state.channel = action.payload.data.data;
    });
    builder.addCase(updateChannel.rejected, (state) => {});
  },
});
export const adminActions = adminSlice.actions;
export default adminSlice.reducer;
