import { Box } from "@mui/material";

export default function PrivatePolicy() {
  return (
    <Box sx={{ padding: "30px" }}>
      <h1 id="privacy-policy">Privacy policy</h1>
      <p>Last changed: 9 September, 2023</p>
      <h2 id="your-privacy-matters">Your Privacy Matters</h2>
      <p>
        Welcome to flipbookapp.ubabybaby.com (&quot;flipbookapp&quot;,
        &quot;ubabybaby&quot;, &quot;Platform&quot; or &quot;Site&quot;,
        &quot;Website&quot;). We understand that the privacy of our register
        members, subscribers, guests and/or visitors (&quot;Users&quot;,
        &quot;you&quot; or &quot;your&quot;) is very important. As such, we
        provide this Privacy Policy explaining our online information practices
        and the choices you can make about the way your personal data is
        collected, processed, maintained and disposed by us and our 3rd party
        service providers (&quot;Suppliers&quot;).
      </p>
      <p>
        The Privacy Policy describes how we treat your Personal Information when
        you use flipbookapp services (&quot;Services&quot;). The following
        describes our privacy practices that are specific to
        flipbookapp.ubabybaby.com . We reserve the right to update it at any
        time, and all changes are effective upon posting without notice to you.
        You are encouraged to revisit this page from time to time. Your use of
        the Site or our Services after such changes constitutes your agreement
        to such changes.
      </p>
      <h2 id="1-who-we-are">1. Who we are</h2>
      <p>
        UBabyBaby (&quot;the Company&quot;) is a photo book and photo service
        that allows you to create your own photo books, albums, and other photo
        products using your photos. With UBabyBaby&#39;s services, you can
        create electronic versions of photo books and albums and share them with
        your friends.
      </p>
      <h2 id="2-what-information-we-collect">2. What information we collect</h2>
      <p>
        The types of Personal Information that we collect fall under two general
        categories: personally identifiable information (PII) and non-personally
        identifiable information (non-PII). PII consists of any information
        which can be used to specifically identify you as an individual, whereas
        non-PII consists of aggregate information or any information that does
        not reveal your identity. The following paragraphs explain what PII and
        non-PII we collect from you, and how we use such information.
      </p>
      <h3 id="2-1-cookies">2.1 Cookies</h3>
      <p>
        A cookie is a small file stored on your computer&#39;s Browser, ready
        for future access when you return to our site. We use cookies to deliver
        web content specific to you and collect website usage data. Cookies
        contain session identification numbers that allow our systems to recall
        previous sessions for authentication efforts and assemble information
        from our gathered data. Cookies cannot pass viruses, harm your computer
        or pass on private information such as an email address without the
        user&#39;s intervention. You can configure your computer&#39;s browser
        to alert you when a site is attempting to send you a cookie and allow
        you to accept or refuse the cookie.
      </p>
      <h3 id="2-2-web-beacons">2.2 Web Beacons</h3>
      <p>
        &quot;Web beacons&quot; (also known as &quot;clear gifs&quot; and
        &quot;pixel tags&quot;) are small transparent graphic images that are
        often used in conjunction with cookies in order to further personalize
        our Website for our users and to collect a limited set of information
        about our visitors. We may also use web beacons in email communications
        sent to you in order to understand the behavior of our members. We do
        not link the web beacons to any of our members’ PII.
      </p>
      <h3 id="2-3-log-activity">2.3 Log activity</h3>
      <p>
        We receive information every time you view or interact with content on
        our site, even if you don&#39;t have an account. Log activity
        information covers a variety of data that is available to ubabybaby
        flipbook either through 3rds party service providers or collected
        directly by us: browser information, OS type, the device used, IP, chat
        activity, date, time, and duration for each site visit, referring web
        page, page visits, search terms, location, cookies, clicks, views and
        session duration for all site pages. We generally analyze data sets to
        make sure our services are fully functional and to ensure reliable and
        secure performance. We also use such information to better understand
        user needs, improve our site performance, and send relevant marketing
        messages that match your interests.
      </p>
      <h3 id="2-4-link-clicks">2.4 Link clicks</h3>
      <p>
        In order to operate our service, we keep track of how you interact with
        our site and what links you decide to click on. It may include links on
        the website, blog, help center, emails, ads, and live chat support.
      </p>
      <h3 id="2-5-embeds">2.5 Embeds</h3>
      <p>
        If you view flipbookapp integrated on third-party websites via embed
        codes or white-labeled URLs, we may receive log data regarding your
        activity (what page you visited, session information, and how you
        interacted with the embedded flipbook). This type of data does not
        contain any personally identifiable information and is reported as an
        aggregate to us. We make this data available directly in the
        publisher&#39;s account under statistics and in Google Analytics .This
        type of information is important for our users who need to understand
        how their publications are performing in order to be able to optimize
        them.
      </p>
      <h3 id="2-6-location">2.6 Location</h3>
      <p>
        We get information about your signup and current location (country and
        city) from your IP address and device settings. (An IP address is a
        number that is assigned to your device by your Internet Service Provider
        when you are accessing the Internet.) You may receive personalized
        messages based on your location.
      </p>
      <h3 id="2-7-public-information">2.7 Public information</h3>
      <p>
        All your public information can be viewed on your public profile,
        including name, unique URL, profile image, website, social profiles,
        number of followers, as well as all the projects that you have created
        and published publicly. None of these details are mandatory for setting
        up an account, but we recommend displaying them on your profile, if you
        have any interest in branding or promoting your content. You are
        responsible for the information that you share on your profile and the
        content of your projects, so you should think carefully about what you
        want to publish publicly. For sensitive information that you wish to
        keep private or share with a limited group of individuals, we recommend
        publishing your content privately.
      </p>
      <p>
        If you update your public information on our site it may automatically
        be updated on our website.
      </p>
      <h3 id="2-8-contact-information-and-password">
        2.8 Contact information and password
      </h3>
      <p>
        We use your email address and password to authenticate your account and
        keep it secure, preventing fraud, abuses, and spam. We need your email
        to set up unique user accounts, to be able to get in touch with you, and
        to send you personalized messages. You can always modify your email
        address directly from your account, and you can reset your password at
        any time.
      </p>
      <p>
        In addition, your password is non-reversible encrypted before being
        saved to the database for your protection, so it cannot be copied or
        stolen, even in case of a hacking attack. We also use your email address
        to get in touch with you and send you information about our services.
      </p>
      <p>
        You can sign up for flipbookapp using another service: a Facebook or
        Google account. If you do, we will use information from that service to
        create your account, such as your email address and name. We do not
        collect any passwords at all for users that are using the Facebook
        Connect API as well as for those connecting with Google API.
      </p>
      <p>
        If you email or contact us via live chat, we will keep your contact info
        and message to be able to send you a reply.
      </p>
      <h2 id="3-why-we-collect">3.Why we collect</h2>
      <p>ubabybaby will collect and use your personal data only where:</p>
      <ul>
        <li>
          <p>
            We need it in order to fulfill our contractual obligations when
            delivering you the Service.
          </p>
        </li>
        <li>
          <p>
            It is in our legitimate interest for developing and improving the
            Service.
          </p>
        </li>
        <li>
          <p>It is necessary for compliance with our legal obligations.</p>
        </li>
      </ul>
      <h2 id="4-how-to-use-and-dsclosure-of-personal-information">
        4.How to use and dsclosure of personal information
      </h2>
      <p>
        We may use and disclose Personal Information only for the following
        purposes:
      </p>
      <h3 id="4-1-to-promote-the-use-of-our-services-to-you-and-others">
        4.1 To promote the use of our services to you and others
      </h3>
      <p>
        If you use any of our Services and we think you might benefit from using
        another Service we offer, we may send you an email about that. You can
        stop receiving our promotional emails by following the unsubscribe
        instructions included in every email we send. In addition, we may use
        the information we collect in order to advertise our Services to you or
        suggest additional features of our Services that you might consider
        using. In addition, we may use your Personal Information to advertise
        our Services to potential or other users like you.
      </p>
      <h3 id="4-2-to-send-you-informational-and-promotional-content-in-accordance-with-your-marketing-preferences">
        4.2 To send you informational and promotional content in accordance with
        your marketing preferences
      </h3>
      <p>
        You can stop receiving our promotional emails by following the
        unsubscribe instructions included in every email.
      </p>
      <h3 id="4-3-to-send-you-system-alert-messages">
        4.3 To send you System Alert messages
      </h3>
      <p>
        For example, we may inform you of temporary or permanent changes to our
        Services, such as planned outages, new features, version updates,
        releases, abuse warnings, and changes to our Privacy Policy.
      </p>
      <h3 id="4-4-to-communicate-with-our-users-about-their-account-and-provide-customer-support">
        4.4 To communicate with our Users about their account and provide
        customer support
      </h3>
      <h3 id="4-5-to-protect-the-rights-and-safety-of-our-users-and-third-parties-as-well-as-our-own">
        4.5 To protect the rights and safety of our Users and third parties, as
        well as our own
      </h3>
      <h3 id="4-6-to-meet-legal-requirements-including-complying-with-court-orders-valid-discovery-requests-valid-subpoenas-and-other-appropriate-legal-mechanisms">
        4.6 To meet legal requirements, including complying with court orders,
        valid discovery requests, valid subpoenas, and other appropriate legal
        mechanisms
      </h3>
      <h3 id="4-7-to-provide-support-and-improve-the-services-we-offer">
        4.7 To provide, support, and improve the Services we offer
      </h3>
      <h3 id="4-8-we-may-disclose-personal-information-to-the-following-types-of-third-parties-for-the-purposes-described-in-this-policy">
        4.8 We may disclose Personal Information to the following types of third
        parties for the purposes described in this policy
      </h3>
      <h4 id="service-providers">Service Providers</h4>
      <p>
        Sometimes, we share your information with our third-party Service
        Providers, who help us provide and support our Services. For example, if
        it is necessary to provide you something you have requested (like
        enabling a feature), then we may share your and/or your Subscribers’
        Personal Information with a Service Provider for that purpose. Just like
        with the other third parties we work with, these third-party Service
        Providers enter into a contract that requires them to use your Personal
        Information only for the provision of services to us and in a manner
        that is consistent with this policy.
      </p>
      <h4 id="advertising-partners">Advertising partners</h4>
      <p>
        We may partner with third-party advertising networks and exchanges to
        display advertising on our Websites or to manage and serve our
        advertising on other sites and may share Personal Information with them
        for this purpose. All third parties with which we share this information
        are required to use your Personal Information in a manner that is
        consistent with this policy. We and our third-party partners may use
        cookies and other tracking technologies, such as pixels and web beacons,
        to gather information about your activities on our Websites and other
        sites in order to provide you with targeted advertising based on your
        browsing activities and interests.
      </p>
      <h2 id="5-your-rights">5. Your rights</h2>
      <h3 id="5-1-access-and-correction">5.1 Access and correction</h3>
      <p>
        You have the right to access and correct your personal data held by us,
        at any time, by sending an email to our Data Protection Officer at
        cs@ubabybaby.com.
      </p>
      <p>
        In order to ensure that your personal data is kept as accurate as
        possible, please notify us at the email address above of any changes to
        your personal data.
      </p>
      <h3 id="5-2-opt-out-of-direct-marketing">
        5.2 Opt-out of direct marketing
      </h3>
      <p>
        At any time, you can ask us to stop using your personal data to send you
        Marketing Communications, or you can customise how you would like to
        receive Marketing Communications from us. You can exercise your rights
        to do this by:
      </p>
      <ul>
        <li>changing your settings on your User Account; or</li>
        <li>
          following the unsubscribe instructions in the Marketing Communications
          we send to you.
        </li>
      </ul>
      <p>
        We will stop using your personal data to send you Marketing
        Communications in accordance with your request. However, we may still
        send you non-promotional communications such as information concerning
        the administration of your User Account, and may use non-personally
        identifiable information collected via cookies and other tracking
        technology to display marketing information via online advertising
        banners on websites and apps visited by you.
      </p>
      <p>
        We are not involved or responsible for any marketing materials sent
        directly by our partners that concern any reward programmes, or products
        or services offered or provided by them that are unrelated to the
        functions of the Site or App. If you would like to stop receiving any
        marketing materials from our partners, then please contact them directly
        in order to unsubscribe from their mailing lists.
      </p>
      <h2 id="6-additional-information-and-assistance">
        6. Additional information and assistance
      </h2>
      <p>
        If you have questions or if you need assistance regarding any
        information listed in this Privacy policy, please contact our data
        protection officer at cs@ubabybaby.com.
      </p>
      <h2 id="7-acceptance-of-privacy-policy">
        7. Acceptance of privacy policy
      </h2>
      <p>
        Your use of our Website, including any dispute concerning privacy, is
        subject to this Privacy Policy and the applicable Terms of Use. BY USING
        OUR WEBSITE, YOU ARE ACCEPTING THE PRACTICES SET OUT IN THIS PRIVACY
        POLICY AND THE APPLICABLE TERMS OF USE
      </p>
      <p>
        If we decide to amend our Privacy Policy, we will post those changes on
        this page and additionally any other place on the Website we deem
        appropriate so that you may become aware of it. We reserve our right to
        modify this Privacy Policy at any time, so please come and revisit this
        page frequently.{" "}
      </p>
      <p>
        Your continued use of any portion of our Website following posting of
        our updated Privacy Policy will constitute your acceptance of the
        changes.
      </p>
    </Box>
  );
}
