import { Alert, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import { verifyEmail } from "../../store/slices/authenticationSlice";

function EmailVerfication() {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const verification = searchParams.get("verification");
  const key = searchParams.get("key");
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState(
    []
  );

  useEffect(() => {
    if (verification && key) {
      setLoading(true);
      const payload = {
        verification,
        key,
      };

      dispatch(verifyEmail(payload))
        .unwrap()
        .then((res) => {
          console.log("res", res);
          setLoading(false);
          if (res?.data?.status) {
            setIsToastOpen(true);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          } else if (res?.data?.status === false) {
            setServerSideValidationErrors(Object.values(res?.data?.errors));
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          }
        })
        .catch((err) => {
          console.log("catchError:", err);
        });
    }
  }, [verification, key, navigate, dispatch]);

  return (
    <>
      <CustomLoader isOpen={loading} />
      <Box sx={{ width: "100%" }}>
        {serverSideValidationErrors &&
          serverSideValidationErrors.map((item, i) => (
            <Alert key={`${item}_${i}`} sx={{ mt: 2.5 }} severity="error">
              {item}
            </Alert>
          ))}
        {isToastOpen && (
          <Alert severity="success">Email verified successfully!</Alert>
        )}
      </Box>
    </>
  );
}

export default EmailVerfication;
