import CloseIcon from "@mui/icons-material/Close";
import CodeIcon from "@mui/icons-material/Code";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Alert, Button, Divider, IconButton, InputBase } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";

import { useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import styles from "../../styles/share.module.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  "@media only screen and (max-width: 768px)": {
    width: "75%",
  },
};

export default function ShareModal({
  isModalVisible,
  handleClose,
  shareLink,
  onPressShowIframeModal,
  title,
  visibility,
  projectId,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const editIfPrivate = () => {
    navigate(`/project/${projectId}/edit`);
  };
  return (
    <div>
      <Modal
        open={isModalVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ margin: 0, fontSize: 18 }}>Share</p>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography sx={{ mt: 1 }}>
            {visibility !== "private"
              ? "You have several options to share your flipbook: copy and send it as a direct link via email or messengers, post it on social media."
              : "This flipping book is private, which can’t be shared. If you want to share this flipping book, please make it public or unlisted."}{" "}
          </Typography>
          {visibility !== "private" && (
            <Box sx={{ mt: 2 }}>
              <CopyToClipboard text={shareLink}>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "95%",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    value={shareLink}
                    disabled
                  />
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    color="primary"
                    sx={{ p: "10px" }}
                    aria-label="directions"
                    onClick={() => {
                      setOpen(true);
                      setTimeout(() => {
                        setOpen(false);
                      }, 2000);
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Paper>
              </CopyToClipboard>
            </Box>
          )}
          {open && (
            <Box sx={{ width: "100%", mt: 2 }} spacing={2}>
              <Alert
                onClose={() => {
                  setOpen(false);
                }}
              >
                Link copied to clipborad
              </Alert>
            </Box>
          )}
          {visibility !== "private" && (
            <Box sx={{ mt: 2 }}>
              <IconButton
                className={styles.btnStyle}
                onClick={onPressShowIframeModal}
              >
                <CodeIcon sx={{ color: "white" }} />
              </IconButton>
              <FacebookShareButton url={shareLink} quote={title}>
                <IconButton className={styles.btnStyle}>
                  <FacebookIcon sx={{ color: "white" }} />
                </IconButton>
              </FacebookShareButton>
              <TwitterShareButton url={shareLink}>
                <IconButton className={styles.btnStyle}>
                  <TwitterIcon sx={{ color: "white" }} />
                </IconButton>
              </TwitterShareButton>
              <WhatsappShareButton url={shareLink}>
                <IconButton className={styles.btnStyle}>
                  <WhatsAppIcon sx={{ color: "white" }} />
                </IconButton>
              </WhatsappShareButton>
            </Box>
          )}
          {visibility === "private" && (
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={editIfPrivate} variant="contained">
                Edit Project
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}
