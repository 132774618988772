import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button } from "@mui/material";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import configData from "../configData.json";

export default function MenuButton({
  notRequiredPremission,
  onPressEdit,
  onPressDelete,
  onPressLock,
  onPressUnLock,
  onPressApprove,
  usertItem,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canApprove, setCanApprove] = useState(false);
  const [canUnLock, setCanUnLock] = useState(false);

  const open = Boolean(anchorEl);
  const userDetails = useSelector((state) => state.authentication.currentUser);

  useEffect(() => {
    const isAdminOrSuperAdmin = configData.administraion.includes(
      userDetails?.role_id
    );

    setCanEdit(
      notRequiredPremission ||
        (isAdminOrSuperAdmin && usertItem?.created_by === userDetails.id)
    );
    setCanDelete(
      notRequiredPremission ||
        (isAdminOrSuperAdmin && usertItem?.created_by === userDetails.id)
    );
    setCanApprove(usertItem?.applied_for_creator === 1 && isAdminOrSuperAdmin);
    setCanUnLock(usertItem?.status === 2 && isAdminOrSuperAdmin);
  }, [usertItem, userDetails, notRequiredPremission]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled={usertItem?.role_id <= 2}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {onPressEdit && canEdit && (
          <MenuItem
            onClick={() => {
              onPressEdit();
              handleClose();
            }}
          >
            Edit
          </MenuItem>
        )}
        {onPressLock && usertItem?.status !== 2 && (
          <MenuItem
            onClick={() => {
              onPressLock();
              handleClose();
            }}
          >
            Lock
          </MenuItem>
        )}
        {onPressUnLock && canUnLock && usertItem.status === 2 && (
          <MenuItem
            onClick={() => {
              onPressUnLock();
              handleClose();
            }}
          >
            Unlock
          </MenuItem>
        )}
        {onPressApprove && canApprove && (
          <MenuItem
            onClick={() => {
              onPressApprove();
              handleClose();
            }}
          >
            Approve
          </MenuItem>
        )}
        {onPressDelete && canDelete && (
          <MenuItem
            onClick={() => {
              onPressDelete();
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
