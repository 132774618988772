import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Grid, Menu, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { arrayMove } from "../../common/helper";
import CustomLoader from "../../components/CustomLoader";
import FlipbookModal from "../../components/Discover/Flipbook/FlipbookModal";
import Navbar from "../../components/Navbar";
import Droppable from "../../components/Project/Rearrange/Droppable";
import {
  getCroppedFilesByProject,
  getFlipBookHeightWidth,
  getProjectById,
  publishProject,
  reArrangeImages,
} from "../../store/slices/project/projectAction";
import { projectActions } from "../../store/slices/projectSlice";
function RearrangeNew(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDrawer, setOpenDrawer] = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { projectId } = location?.state;
  const [croppredFiles, setCroppedFiles] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  // const perservedData = useSelector(
  //   (state) => state.project.projectDetails.files
  // );

  // const project = useSelector((state) => state.project.projectDetails);

  const isLayFlat = useSelector(
    (state) =>
      state.project.projectDetails.design_type === "photobook" ||
      state.project.projectDetails.design_type === "layflat photobook"
  );

  const isSelfDesignAndFrontCover = useSelector(
    (state) =>
      state.project.projectDetails.design_type === "self-design" &&
      state.project.projectDetails.layout_type === "front_cover"
  );
  const isSelfDesignAndBackCoverReqAppend = useSelector(
    (state) =>
      state.project.projectDetails?.design_type === "self-design" &&
      state.project.projectDetails?.layout_type === "back_cover" &&
      state.project.projectDetails?.files?.length % 2 !== 0
  );
  // eslint-disable-next-line no-unused-vars
  const [items, setItems] = useState([]);

  const backToChooseLayout = () => {
    dispatch(projectActions.setFilesToFlipBook());
    navigate(`/project/${location?.state?.projectId}/upload`, {
      state: {
        categoryId: location?.state?.category_id,
        projectTitle: location?.state?.title,
        visibility: location?.state?.visibility,
        projectId: location?.state?.projectId,
      },
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 0,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  // const projectDetailData = useSelector(
  //   (state) => state.project.projectDetails
  // );
  useEffect(() => {
    dispatch(getProjectById(projectId));
  }, []);
  useEffect(() => {
    setLoading(true);
    dispatch(getCroppedFilesByProject(projectId)).then((res) => {
      setLoading(false);
      let filesAsPerLayout = res.payload.data.cropped_files;
      console.log(filesAsPerLayout, "filesAsPerLayout");
      if (
        isLayFlat ||
        isSelfDesignAndFrontCover ||
        isSelfDesignAndBackCoverReqAppend
      ) {
        const n1 = [...filesAsPerLayout];
        const nn = { ...n1[0] };
        nn.id = "unsortable-layflat";
        nn.image_url = res.payload.data.grey;
        nn.compressed = res.payload.data.grey;
        if (!isSelfDesignAndBackCoverReqAppend) {
          nn.sort_order = null;
          n1.splice(1, 0, nn);
        }
        const nn1 = { ...nn };
        nn1.id = "unsortable-front";
        n1.splice(n1.length - 1, 0, nn1);
        filesAsPerLayout = n1;
      }
      setCroppedFiles(filesAsPerLayout);
      dispatch(projectActions.setFilesToFlipBook(filesAsPerLayout));
      dispatch(getFlipBookHeightWidth(projectId));
    });
  }, [
    dispatch,
    isLayFlat,
    isSelfDesignAndBackCoverReqAppend,
    isSelfDesignAndFrontCover,
    projectId,
  ]);

  const getCroppedFilesAndOpenModal = () => {
    handleClose();
    setIsModalVisible(true);
    setIsPreview(true);
    // getCroppedFilesByProjectHandler();
  };
  const handleDragEnd = ({ active, over }) => {
    if (
      !over ||
      over.id === "unsortable-layflat" ||
      over.id === "unsortable-front"
    ) {
      return;
    }

    if (active.id !== over.id) {
      const activeContainer = active.data.current.sortable.containerId;
      const overContainer = over.data.current?.sortable.containerId || over.id;
      const activeIndex = active.data.current.sortable.index;
      const overIndex = over.data.current?.sortable.index || 0;
      let newItems = [];
      setCroppedFiles((sortableItems) => {
        if (activeContainer === overContainer) {
          newItems = arrayMove(croppredFiles, activeIndex, overIndex);

          const uploadedFiles = [...newItems];
          const unsortable_layflat_index = uploadedFiles.findIndex(
            (i) => i.id === "unsortable-layflat"
          );
          const unsortable_front_index = uploadedFiles.findIndex(
            (i) => i.id === "unsortable-front"
          );
          if (unsortable_layflat_index > -1) {
            const temp = { ...uploadedFiles[unsortable_layflat_index] };
            uploadedFiles[unsortable_layflat_index] = uploadedFiles[1];
            uploadedFiles[1] = temp;
            newItems = uploadedFiles;
          }
          const secondLastIndex = uploadedFiles.length - 2;
          if (unsortable_front_index > -1) {
            const temp = { ...uploadedFiles[unsortable_front_index] };
            uploadedFiles[unsortable_front_index] =
              uploadedFiles[secondLastIndex];
            uploadedFiles[secondLastIndex] = temp;
            newItems = uploadedFiles;
          }
        }
        const groupItems = [...newItems];
        let sort_order = 0;
        const groupFiles = groupItems.map((i) => {
          const item = { ...i };
          if (typeof item.id === "number") {
            item.sort_order = sort_order;
            sort_order++;
          } else {
            item.sort_order = null;
          }
          return item;
        });
        newItems = groupFiles;
        return newItems;
      });

      const payloadData = newItems.filter((item) => item.sort_order !== null);
      const newPayload = payloadData.map(({ id, sort_order }) => ({
        id,
        sort_order,
      }));
      const payload = {
        id: projectId,
        data: {
          "sort-order": newPayload,
        },
      };
      dispatch(reArrangeImages(payload));
    }
  };

  const OnPressSaveAndUpload = () => {
    // eslint-disable-next-line no-unused-vars
    const payloadData = items.filter((item) => item.sort_order !== null);
    const newPayload = payloadData.map(({ id, sort_order }) => ({
      id,
      sort_order,
    }));
    // eslint-disable-next-line no-unused-vars
    const payload = {
      id: projectId,
      data: {
        "sort-order": newPayload,
      },
    };

    if (!isBtnDisable) {
      callPublishProjectApi();
    }
  };

  const callPublishProjectApi = () => {
    setLoading(true);
    dispatch(publishProject(projectId))
      .unwrap()
      .then((res) => {
        setLoading(false);
        navigate("/congratulations");
        setTimeout(() => {
          navigate("/discover");
        }, 3000);
      });
  };

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isPreview, setIsPreview] = React.useState(false);
  const closeModal = () => setIsModalVisible(false);

  return (
    <>
      <Navbar
        title="Create Project"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <CustomLoader isOpen={loading} />
      <Grid p={2} pl={3} container item xs={12}>
        <Box
          sx={{ width: "100%", mb: 1 }}
          display="flex"
          justifyContent="flex-end"
        >
          {location?.state?.is_published === 0 && (
            <Button
              sx={{
                mr: 2,
              }}
              variant="contained"
              onClick={backToChooseLayout}
              disabled={isBtnDisable}
            >
              {"< "}Upload file
            </Button>
          )}

          <Button variant="contained" disabled={isBtnDisable}>
            <span onClick={() => OnPressSaveAndUpload()}>Publish</span>
            <KeyboardArrowDownIcon
              sx={{ ml: 1 }}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={getCroppedFilesAndOpenModal}>Preview</MenuItem>
            {/* <MenuItem
              onClick={() => {
               navigate('/my-projects')
                handleClose();
              }}
            >
              Save Draft
            </MenuItem> */}
          </Menu>
        </Box>

        <Box sx={{ width: "100%" }}>
          <h3 style={{ textAlign: "center" }}>Arrange Pages</h3>
          <hr />
        </Box>
      </Grid>

      <Box sx={{ width: isMobile ? "85%" : "100%", mx: "auto" }}>
        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
          <div className="rearrange">
            {/* {Object.keys(sortableItems).map((group) => ( */}
            <Droppable
              // id={group}
              items={croppredFiles}
              // key={group}
            />
            {/* ))} */}
          </div>
        </DndContext>
      </Box>
      {croppredFiles.length > 0 && (
        <FlipbookModal
          files={croppredFiles}
          isModalVisible={isModalVisible}
          isPreview={isPreview}
          onClose={closeModal}
        />
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { projectDetails } = state.project;
  return { projectDetails };
}
export default connect(mapStateToProps)(RearrangeNew);
