import { Box } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import Navbar from "../../components/Navbar";

function Congratulations() {
  const [openDrawer, setOpenDrawer] = useOutletContext();

  return (
    <>
      <Navbar
        title="Create Project"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "85vh",
        }}
      >
        <h3
          style={{
            fontSize: 22,
            // marginTop: "3em",
            fontWeight: "400",
            color: "#000000",
          }}
        >
          Congratulations
        </h3>
        <p>You have published your project.</p>
      </Box>
    </>
  );
}

export default Congratulations;
