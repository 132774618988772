import { Alert, Button, Grid, Snackbar } from "@mui/material";
import { Box } from "@mui/system";
import MaterialReactTable from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { optionsData, visibilityData } from "../../common/constants";
import { categoryName } from "../../common/helper";
import CustomLoader from "../../components/CustomLoader";
import Navbar from "../../components/Navbar";
import AlertDialog from "../../components/Project/AlertDialog";
import FlipBookHeader from "../../components/Project/MyProject.js/FlipBookHeader";
import FlipMorebtn from "../../components/Project/MyProject.js/FlipMorebtn";
import LightTooltip from "../../components/Project/MyProject.js/LightTooltip";
import ProjectSearchBar from "../../components/Project/Search/ProjectSearchBar";
import ShareModal from "../../components/Share/ShareModal";
import ShareWithIframe from "../../components/Share/ShareWithIframe";
import { getCategories } from "../../store/slices/CategorySlice";
import {
  deletedProject,
  getProjectById,
  getProjects,
  projectPagination,
  projectSearch,
  projectSearchPagination,
  publishProject,
} from "../../store/slices/project/projectAction";
import styles from "../../styles/MyProject.module.scss";

function MyProjects(props) {
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projects, categories, paginationData } = props;
  const [publishedOpen, setPublishedOpen] = useState(false);
  // main state's
  const [projectData, setProjectData] = useState([]);
  const [categoryOptionData, setCategoryOptionData] = useState(
    categories ?? []
  );

  // search state's
  const [typeValue, setTypeValue] = useState("");
  const [seachInputValue, setSeachInputValue] = useState("");
  const [checkBoxData, setCheckBoxData] = useState({});
  const [isClearSearch, setIsClearSearch] = useState(false);

  // pagination state's
  const [isSearchPagination, setisSearchPagination] = useState(false);
  const [searchPayload, setSearchPayload] = useState({});

  // generic state's
  const [loading, setLoading] = useState(false);

  // Share stat's
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [shareIframeLink, setShareIframeLink] = useState("");
  const [showShareIframe, setShowShareIframe] = useState(false);
  const [currentProjectFiles, setCurrentProjectFiles] = useState([]);
  const [currentProject, setCurrentProject] = useState({});
  const handleClose = () => {
    setPublishedOpen(false);
  };
  const getProjectData = () => {
    setLoading(true);
    // dispatch projects
    dispatch(getProjects())
      .unwrap()
      .then((res) => {
        setLoading(false);
      });
  };

  const getCategoryData = () => {
    setLoading(true);
    // dispatch categories
    dispatch(getCategories())
      .unwrap()
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getProjectData();
    getCategoryData();
  }, []);

  useEffect(() => {
    setProjectData(projects);
  }, [projects]);

  useEffect(() => {
    setCategoryOptionData(categories);
  }, [categories]);

  const showShareModal = (project) => {
    console.log("project", project);
    setCurrentProject(project);
    setIsShareModalVisible(true);
    setShareLink(
      `${process.env.REACT_APP_URL}discover-details/${project?.id}`
    );
    setShareIframeLink(
      `${process.env.REACT_APP_URL}discover-details-with-iframe/${project?.id}`
    );
    setCurrentProjectFiles(project?.croppedfiles);
  };

  const columns = useMemo(
    () => [
      {
        id: "title",
        header: "Flipbook",
        size: "30%",
        accessorKey: "project_title",
        accessorFn: (row) => row.project_title.toLowerCase(),
        Cell: ({ Prender, row }) => <FlipBookHeader project={row.original} />,
      },
      {
        accessorKey: "updatedTime",
        header: "Last Modified",
        size: "10%",
      },
      {
        accessorKey: "views",
        header: "Views",
        size: "7%",
      },
      {
        accessorKey: "description",
        size: "32%",
        render: (roData) => roData.description,
        accessorFn: (row) => row.description.toLowerCase(),
        Cell: ({ renderedCellValue, row }) => {
          const description = row.original.description;
          const toShow = `${description?.substring(0, 90)}...`;
          const showEcllipsText = description?.length > 90;
          const showDescription = showEcllipsText ? toShow : description;

          return (
            <Box sx={{ display: "flex" }}>
              {showEcllipsText ? (
                <LightTooltip title={description}>
                  <p>{showDescription}</p>
                </LightTooltip>
              ) : (
                <p>{showDescription}</p>
              )}
            </Box>
          );
        },
        header: "Description",
      },
      {
        accessorKey: "category_id",
        accessorFn: (project) => (
          <p style={{ textTransform: "capitalize" }}>
            {categoryName(categoryOptionData, project?.category_id)}
          </p>
        ),
        header: "Category",
        size: "7%",
      },
      {
        accessorKey: "visibility",
        accessorFn: (project) => (
          <p style={{ textTransform: "capitalize" }}>{project.visibility}</p>
        ),
        header: "Visibility",
        size: "7%",
      },
      {
        field: "",
        header: "More",
        size: "5%",
        sortable: false,
        accessorFn: (project) => (
          <FlipMorebtn
            project={project}
            onProjectEdit={() => {
              navigate(`/project/${project.id}/edit`);
              window.location.reload();
            }}
            onProjectDelete={() => {
              setIsAlertDialogOpen(true);
              setLoading(true);
              setDeleteProject(project);
            }}
            onPressShare={() => showShareModal(project)}
            onPressPublish={() => {
              // publish project
              setLoading(true);
              dispatch(publishProject(project.id))
                .unwrap()
                .then((res) => {
                  console.log("res", res);
                  setLoading(false);
                })
                .catch((err) => {
                  setPublishedOpen(true);
                  console.log("catchError:", err);
                });
            }}
            onPressUpload={() => {
              // navigate to choose-layout screen
              if (project?.id) {
                dispatch(getProjectById(project.id))
                  .unwrap()
                  .then((res) => {
                    navigate(`/project/${project.id}/upload`, {
                      state: {
                        categoryId: project?.category_id,
                        projectTitle: project?.title,
                        visibility: project?.visibility,
                        projectId: project?.id,
                        description: project?.description,
                      },
                    });
                  })
                  .catch((err) => {
                    setLoading(false);
                    console.log("err", err, err.response);
                    if (err.code === "ERR_BAD_REQUEST") {
                      window.location.href = "/404";
                    }
                  });
              }

              //
            }}
            onRearrange={() => {
              // navigate to choose-layout screen
              navigate(`/project/${project.id}/rearrange`, {
                state: {
                  categoryId: project?.category_id,
                  projectTitle: project?.title,
                  visibility: project?.visibility,
                  projectId: project?.id,
                  is_published: project.is_published,
                },
              });
              window.location.reload();
            }}
          />
        ),
      },
    ],
    [navigate, dispatch]
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const payload = {
        type: typeValue === "" ? "title" : typeValue?.toLowerCase(),
        value: seachInputValue?.toLowerCase(),
        size: pagination.pageSize,
      };
      callSearchApi(payload);
    }
  };

  const onChangeCheckBox = (type, value) => {
    const statusPayload = {
      type,
      value,
      size: pagination.pageSize,
    };
    setCheckBoxData(statusPayload);
  };

  const callSearchApi = (payload) => {
    if (
      (payload?.type === "category" || payload?.type === "visibility") &&
      payload?.value?.length === 0
    ) {
      onPressReset();
    }

    setSearchPayload(payload);
    if (payload.type && payload.value.length) {
      setLoading(true);
      setisSearchPagination(true);
      dispatch(projectSearch(payload))
        .unwrap()
        .then((res) => {
          // console.log("res", res);
          setLoading(false);
        });
    }
  };

  const onPressSearch = () => {
    if (typeValue === "") {
      const payload = {
        type: "title",
        value: seachInputValue?.toLowerCase(),
        size: pagination.pageSize,
      };
      callSearchApi(payload);
    } else if (
      checkBoxData.type !== "" &&
      checkBoxData.type !== "title" &&
      checkBoxData.type !== "description" &&
      checkBoxData.type !== undefined
    ) {
      callSearchApi(checkBoxData);
    } else {
      const payload = {
        type: typeValue?.toLowerCase(),
        value: seachInputValue?.toLowerCase(),
        size: pagination.pageSize,
      };
      callSearchApi(payload);
    }
  };

  const onPressReset = () => {
    setSeachInputValue("");
    setIsClearSearch(!isClearSearch);
    setisSearchPagination(false);
    setLoading(true);
    dispatch(getProjects())
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log("catchError:", err);
      });
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (isSearchPagination) {
      getProjectSearchPagination();
    } else {
      getPaginatedData();
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const getPaginatedData = () => {
    setLoading(true);
    dispatch(
      projectPagination({
        page: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      })
    )
      .unwrap()
      .then(() => {
        setLoading(false);
      });
  };

  const getProjectSearchPagination = () => {
    searchPayload.size = pagination.pageSize;
    const payload = {
      id: pagination.pageIndex + 1,
      data: searchPayload,
    };
    setLoading(true);
    dispatch(projectSearchPagination(payload))
      .unwrap()
      .then(() => {
        setLoading(false);
      });
  };
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [deleteProject, setDeleteProject] = useState(null);
  const onCanelAlertDialog = () => {
    setLoading(false);
    setIsAlertDialogOpen(false);
  };

  const onOkAlertDialog = () => {
    setLoading(false);
    setIsAlertDialogOpen(false);
    dispatch(deletedProject(deleteProject.id))
      .unwrap()
      .then(() => {
        if (isSearchPagination) {
          getProjectSearchPagination();
        } else {
          getPaginatedData();
        }
        setLoading(false);
      });
  };
  return (
    <>
      <Navbar
        title="Projects"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <AlertDialog
        isAlertDialogOpen={isAlertDialogOpen}
        onCanel={onCanelAlertDialog}
        onOk={onOkAlertDialog}
        display="Are You sure you want to delete?"
      />
      <Grid p={2} container item xs={12} className={styles.projectContainer}>
        <CustomLoader isOpen={loading} />
        <>
          <Box
            sx={{
              width: "100%",
              mb: 3,
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <Box sx={{ mr: 1, width: "65%" }}>
                <ProjectSearchBar
                  onChangeSelect={(value) => {
                    setTypeValue(value);
                    if (value === "category" || value === "visibility") {
                      setSeachInputValue("");
                    }
                  }}
                  onInputChange={(value) => setSeachInputValue(value)}
                  handleKeyDown={(e) => handleKeyDown(e)}
                  searchValue={seachInputValue}
                  options={optionsData}
                  categoryData={categoryOptionData}
                  onChangeSelectedCategory={(value) => {
                    onChangeCheckBox(typeValue, value);
                  }}
                  visibilityData={visibilityData}
                  isClearSearch={isClearSearch}
                />
              </Box>
              <Button
                variant="contained"
                onClick={() => onPressSearch()}
                sx={{ mr: 1 }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  onPressReset();
                }}
                sx={{ mr: 2, background: "#767676" }}
              >
                Reset
              </Button>
            </Box>
            <Button
              variant="contained"
              onClick={() => navigate("/create-project")}
            >
              Create Project
            </Button>
          </Box>
          <div style={{ height: 560, width: "100%" }}>
            <MaterialReactTable
              columns={columns}
              data={projectData ?? []}
              enableColumnActions={false}
              enableTopToolbar={false}
              manualPagination
              rowCount={paginationData?.total}
              onPaginationChange={setPagination}
              state={{ pagination }}
            />
          </div>
          <ShareModal
            isModalVisible={isShareModalVisible}
            handleClose={() => setIsShareModalVisible(false)}
            shareLink={shareLink}
            onPressShowIframeModal={() => {
              setIsShareModalVisible(false);
              setShowShareIframe(true);
            }}
            title={currentProject?.title}
            visibility={currentProject.visibility}
            projectId={currentProject.id}
          />
          <ShareWithIframe
            isModalVisible={showShareIframe}
            handleClose={() => setShowShareIframe(false)}
            shareLink={shareIframeLink}
            currentProjectFiles={currentProjectFiles}
            desiredHeight={currentProject.desired_height}
            desiredWidth={currentProject.desired_width}
          />
        </>
        <Snackbar
          open={publishedOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={6000}
          onClose={() => handleClose()}
        >
          <Alert
            onClose={() => handleClose()}
            severity="error"
            sx={{ width: "100%", alignItems: "right" }}
          >
            publish is failed
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { projects, paginationData } = state.project;
  const { categories } = state.category;
  return { projects, categories, paginationData };
}
export default connect(mapStateToProps)(MyProjects);
