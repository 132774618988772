import { googleLogout } from "@react-oauth/google";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { adminActions } from "./adminSlice";
import { projectActions } from "./projectSlice";

const apiUrl = process.env.REACT_APP_API_URL;

const headers = {
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

export const signInWithToken = createAsyncThunk(
  "signInWithToken",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.get(`${apiUrl}me`, headers);
    } catch (err) {
      // console.log("err", err);
      return err?.response;
    }
  }
);

export const signInWithGmail = createAsyncThunk(
  "signInWithGmail",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}auth/social-login/social`,
        payload,
        headers
      );
    } catch (err) {
      // console.log("err", err);
      return err?.response;
    }
  }
);

export const signIn = createAsyncThunk("signIn", async (payload) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  try {
    return await axios.post(`${apiUrl}auth/login`, payload, headers);
  } catch (err) {
    // console.log("err", err);
    return err?.response;
  }
});

export const signUp = createAsyncThunk("signUp", async (payload) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  try {
    return await axios.post(`${apiUrl}admin/register`, payload, headers);
  } catch (err) {
    return err?.response;
  }
});

export const forgetPassword = createAsyncThunk(
  "forgetPassword",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}auth/forgot-password`,
        payload,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const getUserDetailsWithForgetPasswordLink = createAsyncThunk(
  "getUserDetailsWithForgetPasswordLink",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.get(
        `${apiUrl}forget-password?verification=${payload.verification}=&key=${payload.key}`,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const updatePasswordAfterForget = createAsyncThunk(
  "updatePasswordAfterForget",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}auth/update-password`,
        payload,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const updatePasswordFromProfile = createAsyncThunk(
  "updatePasswordFromProfile",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(`${apiUrl}auth/reset-password`, payload, headers);
    } catch (err) {
      return err?.response;
    }
  }
);

export const updateEmailFromProfile = createAsyncThunk(
  "updateEmailFromProfile",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(`${apiUrl}auth/update-email`, payload, headers);
    } catch (err) {
      return err?.response;
    }
  }
);

export const verifyOtpforChangeEmail = createAsyncThunk(
  "verifyOtpforChangeEmail",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}auth/update-email-otp-verify`,
        payload,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const updateProfileFromProfile = createAsyncThunk(
  "updateProfileFromProfile",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(`${apiUrl}user/profile-update`, payload, headers);
    } catch (err) {
      return err?.response;
    }
  }
);

export const logout = createAsyncThunk("logout", async () => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  try {
    return await axios.post(`${apiUrl}auth/logout`, {}, headers);
  } catch (err) {
    return err?.response;
  }
});

export const verifyEmail = createAsyncThunk("verifyEmail", async (payload) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  // console.log("payload", payload, payload.verification);
  try {
    return await axios.get(
      `${apiUrl}verify-email?verification=${payload.verification}=&key=${payload.key}`,
      {},
      headers
    );
  } catch (err) {
    return err?.response;
  }
});

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    accessToken: null,
    loader: false,
    status: false,
    forgetPasswordLinkUserDetaisl: {},
    currentUser: null,
    redirect_path: "/",
    showShareIframe: false,
  },

  reducers: {
    setAppliedforBeingCreator(state, action) {
      state.currentUser.applied_for_creator = 1;
    },
    remeberPathForGuest(state, action) {
      state.redirect_path = action.payload;
    },
    showShareIframe(state, action) {
      state.showShareIframe = action.payload;
    },
  },
  extraReducers: (builder) => {
    // logout
    builder.addCase(logout.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.loader = false;
      localStorage.clear();
      sessionStorage.clear();
      state.accessToken = null;
      state.loader = false;
      state.status = false;
      state.forgetPasswordLinkUserDetaisl = {};
      state.currentUser = null;
      state.redirect_path = "/login";
      projectActions.setNull();
      adminActions.setNull();
      googleLogout();
    });
    builder.addCase(logout.rejected, (state) => {
      state.loader = false;
      state.status = false;
    });

    // verifyEmail
    builder.addCase(verifyEmail.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      localStorage.clear();
      sessionStorage.clear();
      state.accessToken = null;
      state.loader = false;
      localStorage.clear();
      localStorage.clear();
      state.accessToken = null;
      state.loader = false;
      state.status = false;
      state.forgetPasswordLinkUserDetaisl = {};
      state.currentUser = null;
      state.redirect_path = "/my-projects";
      projectActions.setNull();
    });
    builder.addCase(verifyEmail.rejected, (state) => {
      state.loader = false;
      state.status = false;
    });

    // signIn
    builder.addCase(signIn.pending, (state) => {});
    builder.addCase(signIn.fulfilled, (state, action) => {
      if (action?.payload?.data?.status) {
        localStorage.setItem("accessToken", action?.payload?.data.data?.token);
        state.accessToken = action.payload.data.data?.token;
        state.currentUser = action.payload.data.data;
      }
    });

    // gMail
    builder.addCase(signInWithGmail.pending, (state) => {});
    builder.addCase(signInWithGmail.fulfilled, (state, action) => {
      if (action?.payload?.data?.status) {
        localStorage.setItem("accessToken", action?.payload?.data.data?.token);
        state.accessToken = action.payload.data.data?.token;

        state.currentUser = action.payload.data.data;
      }
    });
    builder.addCase(signInWithGmail.rejected, (state, action) => {
      // console.log("signIn rejected: ", action);
    });

    // signInWithToken
    builder.addCase(signInWithToken.pending, (state) => {});
    builder.addCase(signInWithToken.fulfilled, (state, action) => {
      if (action?.payload?.data?.status) {
        localStorage.setItem("accessToken", action?.payload?.data.data?.token);
        state.accessToken = action.payload.data.data?.token;
        state.currentUser = action.payload.data.data;
      }
    });
    builder.addCase(signInWithToken.rejected, (state, action) => {
      // console.log("signIn rejected: ", action);
    });

    // SignUp
    builder.addCase(signUp.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.loader = false;
      state.status = action.payload.data?.status;
    });
    builder.addCase(signUp.rejected, (state) => {
      state.loader = false;
      state.status = false;
    });

    // forget Password
    builder.addCase(forgetPassword.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(forgetPassword.fulfilled, (state, action) => {
      state.loader = false;
      state.status = action?.payload?.data?.status;
    });
    builder.addCase(forgetPassword.rejected, (state) => {
      state.loader = false;
      state.status = false;
    });

    // getUserDetailsWithForgetPasswordLink
    builder.addCase(getUserDetailsWithForgetPasswordLink.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(
      getUserDetailsWithForgetPasswordLink.fulfilled,
      (state, action) => {
        state.loader = false;
        if (action?.payload?.data?.status) {
          state.status = action?.payload?.data?.status;
          state.forgetPasswordLinkUserDetaisl = action?.payload?.data?.data;
        }
      }
    );
    builder.addCase(getUserDetailsWithForgetPasswordLink.rejected, (state) => {
      state.loader = false;
      state.status = false;
    });

    // updatePasswordAfterForget
    builder.addCase(updatePasswordAfterForget.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(updatePasswordAfterForget.fulfilled, (state, action) => {
      state.loader = false;
      state.status = action?.payload?.data?.status;
    });
    builder.addCase(updatePasswordAfterForget.rejected, (state) => {
      state.loader = false;
      state.status = false;
    });

    // updateProfileFromProfile
    // state.currentUser = action.payload.data.data;
  },
});
export const authenticationActions = authenticationSlice.actions;
export default authenticationSlice.reducer;
