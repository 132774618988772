import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { getProjectById } from "../../api/project";
import CustomLoader from "../../components/CustomLoader";
import FlipbookComponent from "../../components/Discover/Flipbook/Flipbook";
import Navbar from "../../components/Navbar";
import ProjectComment from "../../components/Project/Comments/ProjectComment";
import useSystemStore from "../../store/system";

function DiscoverDetails() {
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const { id } = useParams();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState(null);
  const { isFullScreen, viewportDimension } = useSystemStore();

  const { data, isLoading, error } = useQuery({
    queryKey: ["project", id],
    queryFn: () => getProjectById(id),
  });

  useEffect(() => {
    if (!data) return;

    const isSelfDesignAndBackCoverReqAppend =
      data.design_type === "self-design" &&
      data.layout_type === "back_cover" &&
      data.croppedfiles.length % 2 !== 0;

    const isLayFlat =
      data.design_type === "photobook" ||
      data.design_type === "layflat photobook";

    const isSelfDesignAndFrontCover =
      data.design_type === "self-design" && data.layout_type === "front_cover";

    let filesAsPerLayout = data.croppedfiles ? [...data.croppedfiles] : [];
    filesAsPerLayout = filesAsPerLayout.filter((item) => item.is_cropped === 1);

    if (
      isLayFlat ||
      isSelfDesignAndFrontCover ||
      isSelfDesignAndBackCoverReqAppend
    ) {
      const n1 = [...filesAsPerLayout];
      const nn = { ...n1[0] };
      nn.id = "unsortable-layflat";
      nn.image_url = data.grey ?? "../../assets/layflat.png";
      if (!isSelfDesignAndBackCoverReqAppend) {
        nn.sort_order = null;
        n1.splice(1, 0, nn);
      }
      const nn1 = { ...nn };
      nn1.id = "unsortable-front";
      n1.splice(n1.length - 1, 0, nn1);
      filesAsPerLayout = n1;
    }

    data.files = filesAsPerLayout;
    setProjectData(data);
  }, [data]);

  if (isLoading) {
    return <CustomLoader isOpen />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (!projectData) return null;

  if (projectData.is_published === 0 && !projectData.published_at) {
    navigate("/aceess-denied");
  }

  return (
    <div
      className="wrapper"
      style={{ backgroundColor: isFullScreen ? "black" : "" }}
    >
      <Navbar
        title="Discover"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />

      <Stack
        sx={{
          ...(!isFullScreen && { mt: 8 }),
          ...(isFullScreen && { height: viewportDimension.height }),
        }}
      >
        <Box
          sx={{
            width: "100%",
            ...(isFullScreen && { height: "100%" }),
          }}
        >
          <FlipbookComponent files={projectData.files} />
        </Box>
        <Box
          sx={{
            p: 4,
            display: isFullScreen ? "none" : "block",
          }}
        >
          <ProjectComment
            projectDetails={projectData}
            projectId={id}
            commentData={projectData.comments}
            isProjectTitle
            isUserSection
            isCommentBoxVisible
          />
        </Box>
      </Stack>
    </div>
  );
}

export default DiscoverDetails;
