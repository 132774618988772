import { Alert, Button, FormControl, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { categoryValidationMessage } from "../../../common/constants";
import CustomInput from "../../../components/CustomInput";
import CustomLoader from "../../../components/CustomLoader";
import Navbar from "../../../components/Navbar";
import {
  getCategoryById,
  updateCategory,
} from "../../../store/slices/CategorySlice";

function EditCategory(props) {
  const { id } = useParams();
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const dispatch = useDispatch();
  const { status, loader, categoryDetails } = props;

  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [isBtnDisable, setIsBtnDisable] = useState(true);
  const [loading, setLoading] = useState(loader ?? false);
  const [responseStatus, setResponseStatus] = useState(false);
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState(
    []
  );
  const [categoryValidationMsg, setCategoryValidationMsg] = useState("");

  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  useEffect(() => {
    setResponseStatus(status);
  }, [status]);

  useEffect(() => {
    if (id) {
      dispatch(getCategoryById(id));
    }
  }, [id, dispatch]);

  // Autofill all user data
  useEffect(() => {
    setCategory(categoryDetails?.name);
  }, [categoryDetails]);

  const categoryValid = (value) => {
    if (value.length === 0) {
      setCategoryValidationMsg(categoryValidationMessage);
    } else {
      setCategoryValidationMsg("");
    }
  };

  useEffect(() => {
    if (category) {
      setIsBtnDisable(false);
    } else {
      setIsBtnDisable(true);
    }
  }, [category]);

  const onPressUpdateCategory = () => {
    const payload = {
      data: {
        name: category,
        active: true,
      },
      id,
    };

    if (!isBtnDisable) {
      dispatch(updateCategory(payload)).then((res) => {
        console.log(res?.payload.data?.errors);
        if (res?.payload.data?.errors) {
          setServerSideValidationErrors(
            Object.values(res?.payload.data?.errors)
          );
        } else {
          navigate("/category");
        }
        setLoading(false);
      });
    } else {
      categoryValid(category);
    }
  };

  return (
    <>
      <Navbar
        title="Modify Category"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      {loading ? (
        <CustomLoader />
      ) : (
        <Grid p={2} container item xs={12}>
          <Box
            sx={{ width: "100%", mb: 1 }}
            display="flex"
            justifyContent="flex-end"
          >
            <Button variant="contained" onClick={() => onPressUpdateCategory()}>
              Save
            </Button>
          </Box>
          <FormControl sx={{ mb: 3, mt: 2, width: "60%" }}>
            <CustomInput
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
                categoryValid(e.target.value);
              }}
              placeholder="Please input Category name"
              label="Category Name"
              validationMessage={categoryValidationMsg}
            />
            {serverSideValidationErrors &&
              serverSideValidationErrors.map((item, i) => (
                <Alert key={`${item}_${i}`} sx={{ mt: 2.5 }} severity="error">
                  {item}
                </Alert>
              ))}
          </FormControl>
        </Grid>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { loader, status, categoryDetails } = state.category;
  return { loader, status, categoryDetails };
}

export default connect(mapStateToProps)(EditCategory);
