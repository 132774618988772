import { Alert, Box } from "@mui/material";
import { useEffect, useState } from "react";

function ImageErrorHandling({ imgTypeError, imgSizeError }) {
  const [showGeneralMessage, setshowGeneralMessage] = useState(true);

  useEffect(() => {
    if (imgTypeError) {
      setshowGeneralMessage(false);
    } else if (imgSizeError) {
      setshowGeneralMessage(false);
    }
  }, [imgTypeError, imgSizeError]);

  return (
    <Box
      sx={{
        p: 1,
        background: "#EEEEEE",
        height: 400,
        border: "1px solid gray",
        borderStyle: "dotted",
        borderRadius: "1em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "-0.2em 0.5em 0.5em -0.2em",
      }}
    >
      {showGeneralMessage && (
        <p
          style={{
            color: "#000000",
            textAlign: "center",
          }}
        >
          Simply drag & drop images here <br /> or Browse Files
        </p>
      )}

      {imgTypeError && (
        <Alert sx={{ mb: 1.5 }} severity="error">
          {imgTypeError}
        </Alert>
      )}

      {imgSizeError && <Alert severity="error">{imgSizeError}</Alert>}
    </Box>
  );
}

export default ImageErrorHandling;
