import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useSelector } from "react-redux";
import Photobook from "./Photobook";
import SelfDesign from "./SelfDesign";

function FileType({
  designType,
  handleChange,
  handleClickOpen,
  uploadedFiles,
  albumRatio,
  layoutType,
  setLayoutType,
  setAlbumRatio,
  setAlertAlbumRatio,
  setAlertLayoutType,
  setAlertDesignType,
}) {
  const croppingConfiguration = useSelector(
    (state) => state.project.croppingConfiguration
  );
  return (
    <FormControl>
      <FormLabel
        id="demo-controlled-radio-buttons-group"
        sx={{ color: "#000000", fontSize: 18 }}
      >
        Choose your file type
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={designType}
        onChange={handleChange}
      >
        <FormControlLabel
          value="self-design"
          control={<Radio />}
          label="Self-design"
        />
        {designType === "self-design" && (
          <SelfDesign
            onChangeAspectRatio={(value) => {
              console.log(uploadedFiles);
              if (
                "undefined" !== typeof uploadedFiles &&
                uploadedFiles.length !== 0
              ) {
                handleClickOpen();
                setAlertAlbumRatio(value);
                setAlertLayoutType(layoutType);
                setAlertDesignType(designType);
              } else {
                setAlbumRatio(value);
              }
            }}
            selectedAlbumRatio={albumRatio}
            selectedLayoutType={layoutType}
            onChangeLayoutType={(value) => {
              setLayoutType(value);
            }}
          />
        )}
        {croppingConfiguration &&
          croppingConfiguration.map((item) => (
            <RadioGroup
              key={item.name}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={designType}
              onChange={handleChange}
            >
              <FormControlLabel
                value={item.type}
                control={<Radio />}
                label={item.name}
              />
              {designType === item.type && (
                <Photobook
                  checkBoxData={item.sizes}
                  onChangeLayoutType={(value) => {
                    if (
                      "undefined" !== typeof uploadedFiles &&
                      uploadedFiles.length !== 0
                    ) {
                      handleClickOpen();
                      setAlertAlbumRatio(value);
                      setAlertDesignType(designType);
                    } else {
                      setAlbumRatio(value);
                    }
                  }}
                  label="Select your file size (width x height)"
                  selectedAlbumRatio={albumRatio}
                />
              )}
            </RadioGroup>
          ))}

        {/* {designType === "self-design" && (
                <SelfDesign
                  onChangeAspectRatio={(value) => {
                    if (uploadedFiles.length !== 0) {
                      handleClickOpen();
                      setAlertAlbumRatio(value);
                      setAlertLayoutType(layoutType);
                    } else {
                      setAlbumRatio(value);
                    }
                  }}
                  selectedAlbumRatio={albumRatio}
                  selectedLayoutType={layoutType}
                  onChangeLayoutType={(value) => {
                    setLayoutType(value);
                  }}
                />
              )}
              <FormControlLabel
                value="ubabybaby/ubbpro_layflat_photobook"
                control={<Radio />}
                label="ubabybaby/ubbpro layflat photobook"
              />

              {designType === "ubabybaby/ubbpro_layflat_photobook" && (
                <Photobook
                  checkBoxData={PhotoBookCheckBoxData}
                  onChangeLayoutType={(value) => {
                    if (uploadedFiles.length !== 0) {
                      handleClickOpen();
                      setAlertAlbumRatio(value);
                    } else {
                      setAlbumRatio(value);
                    }
                  }}
                  label={"Select your file size (width x height)"}
                  selectedAlbumRatio={albumRatio}
                />
              )}
              <FormControlLabel
                value="ubabybaby/ubbpro_photoalbum"
                control={<Radio />}
                label="ubabybaby/ubbpro photoalbum"
              />
              {designType === "ubabybaby/ubbpro_photoalbum" && (
                <Photobook
                  checkBoxData={PhotoBookCheckBoxData}
                  onChangeLayoutType={(value) => {
                    if (uploadedFiles.length !== 0) {
                      handleClickOpen();
                      setAlertAlbumRatio(value);
                    } else {
                      setAlbumRatio(value);
                    }
                  }}
                  label={"Select your file size (width x height)"}
                  selectedAlbumRatio={albumRatio}
                />
              )} */}
      </RadioGroup>
    </FormControl>
  );
}

export default FileType;
