import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import configData from "../configData.json";

function RestrictBecomeCreator({ children }) {
  const currentUser = useSelector((state) => state.authentication.currentUser);
  const location = useLocation();

  if (currentUser) {
    const canBecomeCreator = configData.become_creator.includes(
      currentUser?.role_id
    );
    if (currentUser.role_id === 4) {
      return children;
    }
    if (!canBecomeCreator) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  }
}
export default RestrictBecomeCreator;
