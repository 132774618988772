import { Box, Button, FormControl, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import Photobook from "./Photobook";

function LayoutType(type) {
  const imgType = type.type;
  const imgPath = `/assets/${imgType}.png`;
  return (
    <img
      style={{ marginBottom: "-7px" }}
      src={imgPath}
      alt="front-cover"
      loading="lazy"
    />
  );
}

export default function SelfDesign({
  onChangeAspectRatio,
  onChangeLayoutType,
  selectedAlbumRatio,
  selectedLayoutType,
}) {
  const [selectedLayout, setSelectedLayout] = useState(
    selectedLayoutType ?? ""
  );

  useEffect(() => {
    if (selectedLayoutType) {
      setSelectedLayout(selectedLayoutType);
    }
  }, [selectedLayoutType]);

  const checkBoxData = [
    { name: "1:1" },
    { name: "2:3" },
    { name: "3:2" },
    { name: "4:5" },
    { name: "5:4" },
  ];

  return (
    <Box>
      <Photobook
        checkBoxData={checkBoxData}
        onChangeLayoutType={(value) => onChangeAspectRatio(value)}
        label="Select your file’s aspect ratio (width x height)"
        selectedAlbumRatio={selectedAlbumRatio}
      />
      <FormControl sx={{ mb: 3, ml: 3, width: "100%" }}>
        <label style={{ marginBottom: "0.5em", color: "#767676" }}>
          Select layout type :
        </label>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <Button
            onClick={() => {
              onChangeLayoutType("front_cover");
              if (selectedLayout === "front_cover") {
                setSelectedLayout("front_cover");
              }
            }}
            
          >
            <Box
              sx={{
                border:
                  selectedLayout === "front_cover" ? "2px solid #074EE8" : 0,
              }}
            >
              <LayoutType type="front-cover" />
            </Box>
          </Button>

          <Button
            onClick={() => {
              onChangeLayoutType("back_cover");
              if (selectedLayout === "back_cover") {
                setSelectedLayout("back_cover");
              }
            }}
            style={{ marginRight: "-1em" }}
          >
            <Box
              sx={{
                border:
                  selectedLayout === "back_cover" ? "2px solid #074EE8" : 0,
              }}
            >
              <LayoutType type="back-cover" />
            </Box>
          </Button>
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
