import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const headers = {
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

export const getUsers = createAsyncThunk("getUsers", async () => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  try {
    return await axios.get(`${apiUrl}admin/register`, headers);
  } catch (err) {
    return err?.response;
  }
});

export const lockUserById = createAsyncThunk("lockUserById", async (id) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;

  return await axios.patch(
    `${apiUrl}admin/updatestatus/${id}`,
    {
      status: 2,
      _method: "PATCH",
    },
    headers
  );
});

export const deleteUserById = createAsyncThunk("deleteUserById", async (id) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return await axios.delete(`${apiUrl}admin/register/${id}`, headers);
});

export const register = createAsyncThunk("register", async (payload) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  try {
    return await axios.post(`${apiUrl}admin/register`, payload, headers);
  } catch (err) {
    return err?.response;
  }
});

export const fetchEditUserData = createAsyncThunk(
  "fetchEditUserData",
  async (id) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return await axios.get(`${apiUrl}admin/register/${id}`, headers);
  }
);

export const updateUser = createAsyncThunk("updateUser", async (payload) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  payload.data._method = "PATCH";
  return await axios.patch(
    `${apiUrl}admin/register/${payload.id}`,
    payload.data,
    headers
  );
});

export const approveUserById = createAsyncThunk(
  "approveUserById",
  async (id) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return await axios.patch(
      `${apiUrl}admin/updatestatus/${id}`,
      {
        status: 3,
        _method: "PATCH",
      },
      headers
    );
  }
);

export const userSearch = createAsyncThunk("userSearch", async (payload) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  try {
    return await axios.post(`${apiUrl}user-search`, payload, headers);
  } catch (err) {
    return err?.response;
  }
});

export const userSearchPagination = createAsyncThunk(
  "userSearchPagination",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}user-search?page=${payload.id}&size=${payload.pageSize}`,
        payload.data,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const getChannel = createAsyncThunk("getChannel", async () => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  try {
    return await axios.get(`${apiUrl}admin/channel`, headers);
  } catch (err) {
    return err?.response;
  }
});

export const getChannelById = createAsyncThunk(
  "getChannelById",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.get(`${apiUrl}admin/channel/${payload}`, headers);
    } catch (err) {
      return err?.response;
    }
  }
);

export const createChannel = createAsyncThunk(
  "createChannel",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(`${apiUrl}admin/channel`, payload, headers);
    } catch (err) {
      return err?.response;
    }
  }
);

export const updateChannel = createAsyncThunk(
  "updateChannel",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}admin/channel/${payload.id}`,
        payload.data,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const userPagination = createAsyncThunk(
  "userPagination",
  async (payObj) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.get(
        `${apiUrl}admin/register?page=${payObj.page}&size=${payObj.pageSize}`,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);
