import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";

import {
  channelValidationMessage,
  emailValidationMessage,
  firstNameValidationMessage,
  lastNameValidationMessage,
  passwordValidationMessage,
  roleValidationMessage,
} from "../../common/constants";
import { isPasswordValid, validateEmail } from "../../common/helper";
import CustomInput from "../../components/CustomInput";
import CustomLoader from "../../components/CustomLoader";
import ErrorComponent from "../../components/Error";
import Navbar from "../../components/Navbar";
import { register } from "../../store/slices/admin/adminAction";
import { getRoles } from "../../store/slices/roleSlice";

function CreateUser(props) {
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { roles } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [channel, setChannel] = useState("");
  const [isBtnDisable, setIsBtnDisable] = useState(true);
  const [roleList, setRoleList] = useState(props?.roles ?? []);
  const [loading, setLoading] = useState(false);

  const [emailValidationMsg, setEmailValidationMsg] = useState("");
  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [firstNameValidationMsg, setFirstNameValidationMsg] = useState("");
  const [lastNameValidationMsg, setLastNameValidationMsg] = useState("");
  const [channelValidationMsg, setChannelValidationMsg] = useState("");
  const [roleValidationMsg, setRoleValidationMsg] = useState("");
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState(
    []
  );
  const [isVisibleChannel, setIsVisibleChannel] = useState(true);
  useEffect(() => {
    if (firstName && lastName && email && password && role && channel) {
      setIsBtnDisable(false);
    } else {
      setIsBtnDisable(true);
    }
    if (role === 3 && !channel) {
      setIsBtnDisable(false);
    }
  }, [firstName, lastName, email, password, role, channel]);

  const onPressRegister = () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      role_id: role,
      active: true,
      channel,
      status: 0,
    };

    if (!isBtnDisable) {
      setLoading(true);
      dispatch(register(payload))
        .unwrap()
        .then((res) => {
          if (res?.data?.errors) {
            setServerSideValidationErrors(Object.values(res?.data?.errors));
          } else {
            navigate("/users");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      firstNameLength(firstName);
      lastNameValid(lastName);
      emailValid(email);
      passwordValid(password);
      channelValid(channel);
      roleValid(role);
    }
  };

  useEffect(() => {
    dispatch(getRoles())
      .unwrap()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("catchError:", err);
      });
  }, [dispatch]);

  useEffect(() => {
    setRoleList(roles);
  }, [roles]);

  const firstNameLength = (value) => {
    if (value.length === 0) {
      setFirstNameValidationMsg(firstNameValidationMessage);
    } else {
      setFirstNameValidationMsg("");
    }
  };

  const lastNameValid = (value) => {
    if (value.length === 0) {
      setLastNameValidationMsg(lastNameValidationMessage);
    } else {
      setLastNameValidationMsg("");
    }
  };

  const emailValid = (value) => {
    if (value.length === 0) {
      setEmailValidationMsg(emailValidationMessage);
    }

    if (!validateEmail(value)) {
      setEmailValidationMsg(emailValidationMessage);
    } else {
      setEmailValidationMsg("");
    }
  };

  const passwordValid = (value) => {
    if (isPasswordValid(value)) {
      setPasswordValidationMsg(passwordValidationMessage);
    } else {
      setPasswordValidationMsg("");
    }
  };

  const channelValid = (value) => {
    if (value.length === 0 && role !== 3) {
      setChannelValidationMsg(channelValidationMessage);
    } else {
      setChannelValidationMsg("");
    }
  };

  const roleValid = (value) => {
    if (value.length === 0) {
      setRoleValidationMsg(roleValidationMessage);
    } else {
      setRoleValidationMsg("");
    }
  };
  useEffect(() => {
    if (role === 3) {
      setChannel("");
      setIsVisibleChannel(false);
    } else {
      setIsVisibleChannel(true);
    }
  }, [role]);
  return (
    <>
      <Navbar
        title="Create User"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <CustomLoader isOpen={loading} />
      <Grid p={2} pl={3} container item xs={12}>
        <Box
          sx={{ width: "100%", mb: 1 }}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            disabled={isBtnDisable}
            variant="contained"
            onClick={() => onPressRegister()}
          >
            Save
          </Button>
        </Box>
        <FormControl
          sx={{
            mb: 3,
            mt: 2,
            width: "60%",
            "@media only screen and (max-width: 768px)": { width: "100%" },
          }}
        >
          <CustomInput
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              firstNameLength(e.target.value);
            }}
            placeholder="Please input your first name"
            label="First Name"
            validationMessage={firstNameValidationMsg}
          />
        </FormControl>
        <FormControl
          sx={{
            mb: 3,
            width: "60%",
            "@media only screen and (max-width: 768px)": { width: "100%" },
          }}
        >
          <CustomInput
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              lastNameValid(e.target.value);
            }}
            placeholder="Please input your last name"
            label="Last Name"
            validationMessage={lastNameValidationMsg}
          />
        </FormControl>
        <FormControl
          sx={{
            mb: 3,
            width: "60%",
            "@media only screen and (max-width: 768px)": { width: "100%" },
          }}
          variant="outlined"
        >
          <CustomInput
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              emailValid(e.target.value);
              setServerSideValidationErrors();
            }}
            placeholder="Please input your email"
            label="Email Address"
            type="email"
            validationMessage={emailValidationMsg}
          />
        </FormControl>
        <FormControl
          sx={{
            mb: 3,
            width: "60%",
            "@media only screen and (max-width: 768px)": { width: "100%" },
          }}
        >
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            label="Role"
            disableUnderline
            onChange={(e) => {
              setRole(e.target.value);
              roleValid(e.target.value);
            }}
          >
            {roleList &&
              roleList.map((item, i) => (
                <MenuItem key={item + i} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
          {roleValidationMsg && <ErrorComponent error={roleValidationMsg} />}
        </FormControl>
        {isVisibleChannel && (
          <FormControl
            sx={{
              mb: 3,
              width: "60%",
              "@media only screen and (max-width: 768px)": { width: "100%" },
            }}
          >
            <CustomInput
              value={channel}
              onChange={(e) => {
                setChannel(e.target.value);
                channelValid(e.target.value);
              }}
              placeholder="Please input your Channel"
              label="Channel"
              validationMessage={channelValidationMsg}
            />
          </FormControl>
        )}
        <FormControl
          sx={{
            mb: 1,
            width: "60%",
            "@media only screen and (max-width: 768px)": { width: "100%" },
          }}
        >
          <CustomInput
            id="outlined-adornment-password"
            validationMessage={passwordValidationMsg}
            placeholder="******************"
            onChange={(e) => {
              setPassword(e.target.value);
              passwordValid(e.target.value);
            }}
            isInputTypePassword
            label="Password"
            value={password}
          />
          {serverSideValidationErrors &&
            serverSideValidationErrors.map((item, i) => (
              <Alert key={`${item}_${i}`} sx={{ mt: 2.5 }} severity="error">
                {item}
              </Alert>
            ))}
        </FormControl>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { roles } = state.role;
  return { roles };
}
export default connect(mapStateToProps)(CreateUser);
