import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const headers = {
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

export const getCategories = createAsyncThunk("getCategories", async () => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return await axios.get(`${apiUrl}admin/category`, headers);
});

export const getuniqueCategories = createAsyncThunk(
  "getuniqueCategories",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    const data = { uniqueCategories: payload.uniqueCategories };
    return await axios.post(`${apiUrl}admin/unique-category`, data, headers);
  }
);

export const getCategoryById = createAsyncThunk(
  "getCategoryById",
  async (id) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return await axios.get(`${apiUrl}admin/category/${id}`, headers);
  }
);

export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    payload.data._method = "PATCH";
    try {
      return await axios.post(
        `${apiUrl}admin/category/${payload.id}`,
        payload.data,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const createCategory = createAsyncThunk(
  "createCategory",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;

    try {
      return await axios.post(`${apiUrl}admin/category`, payload, headers);
    } catch (err) {
      console.log(err.response);
      return err?.response;
    }
  }
);

export const deleteCategory = createAsyncThunk("deleteCategory", async (id) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  try {
    return await axios.delete(`${apiUrl}admin/category/${id}`, headers);
  } catch (err) {
    return err?.response;
  }
});

export const categoySearch = createAsyncThunk(
  "categoySearch",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}admin/category-search`,
        payload,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const categorySearchPagination = createAsyncThunk(
  "categorySearchPagination",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}admin/category-search?page=${payload.id}`,
        payload.data,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const categoryPagination = createAsyncThunk(
  "categoryPagination",
  async (payObj) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.get(
        `${apiUrl}admin/category?page=${payObj.page}&size=${payObj.pageSize}`,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const CategorySlice = createSlice({
  name: "category",
  initialState: {
    categories: [],
    categoryDetails: {},
    paginationData: {},
    loader: false,
    status: false,
    pagination: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    // get categories: get total list of category
    builder.addCase(getCategories.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.loader = false;
      state.categories = action.payload?.data?.data;
      state.paginationData = action.payload?.data?.meta;
    });
    builder.addCase(getCategories.rejected, (state) => {
      state.loader = false;
    });

    builder.addCase(getuniqueCategories.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getuniqueCategories.fulfilled, (state, action) => {
      state.loader = false;
      state.categories = action.payload?.data?.data;
      state.paginationData = action.payload?.data?.meta;
    });
    builder.addCase(getuniqueCategories.rejected, (state) => {
      state.loader = false;
    });

    // get categories: get total list of category
    builder.addCase(categoySearch.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(categoySearch.fulfilled, (state, action) => {
      state.loader = false;
      state.categories = action.payload.data.data;
      state.pagination = action.payload?.data?.meta;
    });
    builder.addCase(categoySearch.rejected, (state) => {
      state.loader = false;
    });

    // category pagination
    builder.addCase(categoryPagination.pending, (state) => {});
    builder.addCase(categoryPagination.fulfilled, (state, action) => {
      state.categories = action.payload?.data.data;
      state.pagination = action.payload?.data?.meta;
    });
    builder.addCase(categoryPagination.rejected, (state) => {});

    // categorySearchPagination
    builder.addCase(categorySearchPagination.pending, (state) => {});
    builder.addCase(categorySearchPagination.fulfilled, (state, action) => {
      state.categories = action.payload.data.data;
      state.pagination = action.payload?.data?.meta;
    });
    builder.addCase(categorySearchPagination.rejected, (state) => {});

    // get category by id: category details
    builder.addCase(getCategoryById.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(getCategoryById.fulfilled, (state, action) => {
      state.loader = false;
      // state.status = true;
      state.categoryDetails = action.payload.data.data;
    });
    builder.addCase(getCategoryById.rejected, (state) => {
      state.loader = false;
    });

    // update Category
    builder.addCase(updateCategory.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      const categoryData = action.payload.data;
      const categories = JSON.parse(JSON.stringify(state.categories));
      const catIndex = categories.findIndex(
        (element) => element.id === categoryData?.data?.id
      );
      if (catIndex !== -1) {
        categories[catIndex] = categoryData?.data;
      }
      state.categories = categories;
      state.loader = false;
      state.status = true;
    });
    builder.addCase(updateCategory.rejected, (state) => {
      state.loader = false;
    });

    // Create Category
    builder.addCase(createCategory.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(createCategory.fulfilled, (state, action) => {
      const categories = JSON.parse(JSON.stringify(state.categories));
      if (action.payload.data.data) {
        state.categories = [action.payload.data.data, ...categories];
      }

      state.loader = false;
      state.status = true;
    });
    builder.addCase(createCategory.rejected, (state) => {
      state.loader = false;
    });

    // Delete Category
    builder.addCase(deleteCategory.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      const categoryData = action.payload.data;
      const categories = JSON.parse(JSON.stringify(state.categories));
      const catIndex = categories.findIndex(
        (element) => element.id === parseInt(categoryData?.data?.id)
      );
      if (catIndex !== -1) {
        categories.splice(catIndex, 1);
      }
      state.categories = categories;
      state.loader = false;
      state.status = true;
    });
    builder.addCase(deleteCategory.rejected, (state) => {
      state.loader = false;
    });
  },
});

export default CategorySlice.reducer;
