import { Stack } from "@mui/material";
import $ from "jquery";
import { useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import useSystemStore from "../../../store/system";
import styles from "../../../styles/Components/discover/Flipbook.module.scss";
import Turn from "../../Turn/Turn";
import FlipBottomSection from "./FlipBottomSection";

function FlipbookComponent({ files }) {
  const { isIos, isFullScreen, setIsFullScreen, viewportDimension } =
    useSystemStore();
  const handle = useFullScreenHandle();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [imageOriginalDimension, setImageOriginalDimension] = useState({
    width: 0,
    height: 0,
  });
  const [flipbookDisplayDimension, setFlipbookDisplayDimension] = useState({
    width: 0,
    height: 0,
  });
  const [isTurning, setIsTurning] = useState(false);
  // True when user starts to turn the page (e.g. the mouse pointer is at the page corner)
  const [isStartingToTurnPage, setisStartingToTurnPage] = useState(false);
  const [showShadow, setShowShadow] = useState(true);
  const [scale, setScale] = useState(1);

  const containerRef = useRef(null);
  const [containerDimension, setContainerDimension] = useState({
    width: 0,
    height: 550,
  });

  const handleContainerDimensionChange = () => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();

      setContainerDimension({
        width,
        height: containerDimension.height,
      });
    }
  };

  useEffect(() => {
    handleContainerDimensionChange();
    window.addEventListener("resize", handleContainerDimensionChange);
    return () => {
      window.removeEventListener("resize", handleContainerDimensionChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTotalPages(files.length);

    // Use the first image to decide the original image dimension
    const imagePath = files[0]?.image_url;
    const img = new Image();
    img.src = imagePath;

    img.onload = () => {
      setImageOriginalDimension({ width: img.width, height: img.height });
    };
  }, [files]);

  const updateFlipbookDisplayDimension = (width, height) => {
    setFlipbookDisplayDimension({
      width: width * 0.9,
      height: height * 0.9,
    });
  };

  const bottomBarHeight = 50;

  useEffect(() => {
    const flipbookRatio =
      (imageOriginalDimension.width * 2) / imageOriginalDimension.height;

    if (isFullScreen) {
      const viewportRatio =
        viewportDimension.width / (viewportDimension.height - bottomBarHeight);
      if (viewportRatio >= flipbookRatio) {
        const height = Math.round(viewportDimension.height - bottomBarHeight);
        const width = Math.round(height * flipbookRatio);
        updateFlipbookDisplayDimension(width, height);
      } else {
        const width = Math.round(viewportDimension.width);
        const height = Math.round(width / flipbookRatio);
        updateFlipbookDisplayDimension(width, height);
      }
    } else {
      const containerRatio =
        containerDimension.width / containerDimension.height;
      if (containerRatio >= flipbookRatio) {
        const height = Math.round(containerDimension.height);
        const width = Math.round(height * flipbookRatio);
        updateFlipbookDisplayDimension(width, height);
      } else {
        const width = Math.round(containerDimension.width);
        const height = Math.round(width / flipbookRatio);
        updateFlipbookDisplayDimension(width, height);
      }
    }
  }, [
    imageOriginalDimension,
    viewportDimension,
    containerDimension,
    isFullScreen,
  ]);

  const options = {
    autoCenter: true,
    display: "double",
    acceleration: true,
    elevation: 50,
    gradients: !$.isTouch,
    when: {
      turning(event, page) {
        setCurrentPage(page);
        setIsTurning(true);
      },
      turned() {
        setIsTurning(false);
      },
      start() {
        setisStartingToTurnPage(true);
      },
      end() {
        setisStartingToTurnPage(false);
      },
    },
  };

  useEffect(() => {
    if (isTurning || isStartingToTurnPage) {
      if (currentPage === 2 || currentPage === totalPages - 1) {
        setShowShadow(false);
      }
    } else {
      setShowShadow(true);
    }
  }, [isTurning, isStartingToTurnPage, currentPage, totalPages]);

  if (files.length === 0) return null;

  return (
    <Stack
      flexDirection="column"
      width="100%"
      maxWidth={viewportDimension.width}
      sx={{
        ...(isFullScreen && { height: "100%" }),
      }}
      ref={containerRef}
    >
      <FullScreen handle={handle} className={`${styles.fakeFullScreen}`}>
        <TransformWrapper
          wheel={{
            disabled: true,
          }}
          pinch={{
            disabled: true,
          }}
          doubleClick={{
            disabled: true,
          }}
          panning={{
            disabled: scale === 1,
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            p={2}
            sx={{
              ...(isFullScreen && {
                flex: 1,
              }),
            }}
          >
            <TransformComponent wrapperClass={`${showShadow && styles.shadow}`}>
              <Turn
                className="magazine"
                options={options}
                flipbookDisplayDimension={flipbookDisplayDimension}
                currentPage={currentPage}
                totalPages={totalPages}
              >
                {files.map((page, index) => (
                  // eslint-disable-next-line
                  <div key={index}>
                    <img
                      src={page.image_url}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                ))}
              </Turn>
            </TransformComponent>
          </Stack>
          <FlipBottomSection
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            scale={scale}
            setScale={setScale}
            onPressFullScreen={() => {
              setIsFullScreen(true);
              if (!isIos) {
                handle.enter();
              }
            }}
            onPressExitFullScreen={() => {
              setIsFullScreen(false);
              if (!isIos) {
                handle.exit();
              }
            }}
            isFullScreen={!!isFullScreen}
          />
        </TransformWrapper>
      </FullScreen>
    </Stack>
  );
}

export default FlipbookComponent;
