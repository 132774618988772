import { ThemeProvider, createTheme } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import "./config/https";
import router from "./router";
import store, { persistor } from "./store";
import "./styles/main.scss";

const theme = createTheme({
  typography: {
    h7: {
      fontWeight: 400,
      fontSize: "1.125rem",
      lineHeight: 1.5,
      letterSpacing: "0.007em",
    },
  },
});

const queryClient = new QueryClient();

export default function MyApp() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PersistGate loading={null} persistor={persistor}>
          <GoogleOAuthProvider clientId="272504267431-qv6iri8cgkde1ujqn7ks2vb0ribanmb7.apps.googleusercontent.com">
            <ThemeProvider theme={theme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </GoogleOAuthProvider>
        </PersistGate>
      </QueryClientProvider>
    </Provider>
  );
}
