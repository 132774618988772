import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import CustomLoader from "../../components/CustomLoader";
import Navbar from "../../components/Navbar";
import {
  fecthCroppingConfiguration,
  getProjectById,
  projectFileDelete,
  projectFileUpload,
  updateProject,
} from "../../store/slices/project/projectAction";

import AlertDialog from "../../components/Project/AlertDialog";
import ErrorDialog from "../../components/Project/ErrorDialog";
import FileType from "../../components/Project/FileType";
import FileUploadModal from "../../components/Project/FileUploadModal";
import UploadedImg from "../../components/Project/UploadedImg";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import { projectActions } from "../../store/slices/projectSlice";

function ChooseLayout(props) {
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { categoryId, projectTitle, visibility, projectId, description } =
    location?.state;
  const dispatch = useDispatch();

  const [designType, setDesignType] = useState("self-design");
  const [albumRatio, setAlbumRatio] = useState("");
  const [layoutType, setLayoutType] = useState("");

  // alert states'
  const [alertDesignType, setAlertDesignType] = useState("self-design");
  const [alertAlbumRatio, setAlertAlbumRatio] = useState("");
  const [alertLayoutType, setAlertLayoutType] = useState("");
  const [isCoverRequired, setIsCoverRequired] = useState(true);
  const [isBtnDisable, setIsBtnDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isCover, setIsCover] = useState(false);
  const [isErrorAlertDialogOpen, setIsErrorAlertDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const filesData = useSelector((state) => state.project.projectDetails.files);
  const projectDetail = useSelector((state) => state.project.projectDetails);
  const croppingConfiguration = useSelector(
    (state) => state.project.croppingConfiguration
  );
  useEffect(() => {
    dispatch(fecthCroppingConfiguration());
  }, []);
  useEffect(() => {
    if ("undefined" !== typeof filesData) {
      setIsCover(
        designType !== "self-design" &&
          filesData.filter((item) =>
            item?.original_name?.toLowerCase().includes("cover")
          ).length
      );
    }
  }, [filesData, designType]);

  const disableNextBtn =
    filesData &&
    filesData.length > 0 &&
    filesData.some((item) => item?.status === false);

  const getCleanName = (value) => value;
  // file upload state's
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedarr, setUploadedarr] = useState(uploadedFiles?.length);
  const [isFileUploadModalOpen, setisFileUploadModalOpen] = useState(false);
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  let newUploadedListFiles = [];
  const onPressUploadFile = useCallback(() => {
    const payload = {
      data: {
        category_id: categoryId,
        title: projectTitle,
        visibility,
        design_type: designType,
        layout_type: layoutType,
        album_ratio: getCleanName(albumRatio),
      },
      id: projectId,
    };

    dispatch(updateProject(payload));
  }, [
    dispatch,
    visibility,
    layoutType,
    categoryId,
    projectTitle,
    designType,
    albumRatio,
    projectId,
  ]);

  useEffect(() => {
    if (designType && albumRatio) {
      setIsBtnDisable(false);
      if (designType === "self-design" && layoutType === "") {
        setIsBtnDisable(true);
      }
    } else {
      setIsBtnDisable(true);
    }
    if (layoutType !== "") {
      onPressUploadFile();
    }
  }, [designType, albumRatio, layoutType, onPressUploadFile]);

  useEffect(() => {
    setUploadedFiles(filesData);
  }, [filesData]);
  useEffect(() => {
    if (uploadedarr > 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [uploadedarr]);
  useEffect(() => {
    setDesignType(projectDetail.design_type ?? "self-design");
    setAlbumRatio(projectDetail.album_ratio ?? "");
    setLayoutType(projectDetail.layout_type ?? "");
  }, [
    projectDetail.design_type,
    projectDetail.album_ratio,
    projectDetail.layout_type,
  ]);

  // Alert dialog
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setIsAlertDialogOpen(true);
  };

  const onCanelAlertDialog = () => {
    setAlbumRatio(albumRatio);
    setLayoutType(layoutType);
    setIsAlertDialogOpen(!isAlertDialogOpen);
  };

  const onOkAlertDialog = () => {
    setAlbumRatio(alertAlbumRatio);
    setLayoutType(alertLayoutType);
    setDesignType(alertDesignType);
    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((item, i) => {
        deleteFileAfterResponse(item, i, alertAlbumRatio);
      });
    }

    setUploadedFiles([]);
    dispatch(projectActions.setFilestoBlank());
  };
  const onErrorOkAlertDialog = () => {
    setIsErrorAlertDialogOpen(false);
  };

  const onPressFileUpload = async (payload, index = null, item = null) => {
    // To attach already uploaded files
    const formData = payload;
    // When refresh will remove file and upload again

    newUploadedListFiles = [...uploadedFiles];

    if (index !== null) {
      dispatch(projectFileDelete(item.id)).then((res) => {
        if (res.payload.data.status) {
          deleteFileFromUI(index);
        }
      });
      newUploadedListFiles.splice(index, 1);
    }
    dispatch(projectFileUpload(payload))
      .then((res) => {
        setisFileUploadModalOpen(false);
        console.log(res);
        if (res?.payload?.data?.errors) {
          setIsErrorAlertDialogOpen(true);
          setAlertMessage(res?.payload?.data?.message);
          setLoading(false);
        } else {
          const projectFiles = newUploadedListFiles;
          const response = res?.payload?.data?.status
            ? res?.payload?.data?.data
            : res?.payload?.data;
          const uploadedResponse = { ...response };
          uploadedResponse.status = res?.payload?.status !== 422;
          uploadedResponse.file = formData;
          const newUploadedFiles = [...projectFiles];
          if (index !== null) {
            newUploadedFiles.splice(index, 0, uploadedResponse);
          } else if (
            designType !== "self-design" &&
            res.payload.data.data.original_name.toLowerCase().includes("cover")
          ) {
            newUploadedFiles.unshift(uploadedResponse);
          } else {
            newUploadedFiles.push(uploadedResponse);
          }
          newUploadedListFiles = newUploadedFiles;
          setUploadedFiles(newUploadedListFiles);
          console.log(newUploadedListFiles, "newUploadedListFiles");
          dispatch(
            projectActions.setFilesToRetrieveOnBack(newUploadedListFiles)
          );
        }
        setUploadedarr((prev) => prev - 1);
      })
      .catch((err) => {
        setPristine();
        setLoading(false);
      });
  };

  const deleteFileFromUI = (index) => {
    if (index >= 0) {
      const uploadedFilesData = [...uploadedFiles];
      uploadedFilesData.splice(index, 1);
      setUploadedFiles(uploadedFilesData);
      dispatch(projectActions.setFilesToRetrieveOnBack(uploadedFilesData));
    }
    setLoading(false);
  };

  const onDeleteFile = (item, index) => {
    setLoading(true);
    if (item.status) {
      dispatch(projectFileDelete(item.id)).then((res) => {
        if (res.payload.data.status) {
          deleteFileFromUI(index);
        }
      });
    } else {
      deleteFileFromUI(index);
    }
  };

  const deleteFileAfterResponse = (item, index, album_ratio = albumRatio) => {
    if (item.status) {
      setLoading(true);
      dispatch(projectFileDelete(item.id)).then((res) => {
        setLoading(false);
        if (res.payload.data.status) {
          setIsAlertDialogOpen(false);
          dispatch(projectActions.setFilesToRetrieveOnBack([]));
        }
      });
    } else {
      deleteFileFromUI(index);
    }
  };

  const backToCreateProject = () => {
    navigate("/create-project", {
      state: {
        categoryId,
        projectTitle,
        visibility,
        projectId,
        description,
      },
    });
  };

  const handleChange = (event) => {
    if ("undefined" !== typeof uploadedFiles && uploadedFiles.length > 0) {
      setIsAlertDialogOpen(true);
      setAlertDesignType(event.target.value);
    } else {
      setDesignType(event.target.value);
    }
    setAlbumRatio("");
    setLayoutType("");
  };
  const checkValidationBasedOnCountAndRearrange = () => {
    setPristine();
    let length = null;
    let allowed_rearrange = true;

    switch (designType) {
      case "self-design":
        length = 4;
        if (layoutType === "front_cover") {
          allowed_rearrange = uploadedFiles.length % 2 === 0;
        }
        break;
      case "photoalbum":
        length = 2;
        break;
      case "layflat_photobook":
      case "photobook":
        length = 3;
        allowed_rearrange = uploadedFiles.length % 2 !== 0;
        break;
      default:
    }

    if (
      designType !== "self-design" &&
      filesData.filter((item) =>
        item.original_name.toLowerCase().includes("cover")
      ).length === 0
    ) {
      setIsErrorAlertDialogOpen(true);
      setAlertMessage(`Please upload cover image`);
    } else if (uploadedFiles.length < length) {
      setIsErrorAlertDialogOpen(true);
      setAlertMessage(`Minimum ${length} files required`);
    } else if (!allowed_rearrange) {
      setIsErrorAlertDialogOpen(true);
      const type = designType === "self-design" ? "Even " : "Odd";
      setAlertMessage(`${type} number of files required`);
    } else {
      dispatch(projectActions.setSortOrder(uploadedFiles));
      dispatch(getProjectById(projectDetail.id))
        .unwrap()
        .then((res) => {
          navigate(`/project/${projectDetail.id}/rearrange`, {
            state: {
              projectId,
            },
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err, err.response);
          if (err.code === "ERR_BAD_REQUEST") {
            window.location.href = "/404";
          }
        });
    }
  };
  const onChangeFileUpload = (file) => {
    setDirty();
    setisFileUploadModalOpen(false);
    const highestSortOrderIndex =
      projectDetail.files.length === 0
        ? 0
        : Math.max(
            ...projectDetail.files.map((item) => Number(item.sort_order) + 1)
          );
    const arr = Object.keys(file);
    setUploadedarr(arr.length);

    arr.forEach((item, idx) => {
      let index =
        projectDetail.files.length === 0 && idx === 0
          ? 0
          : highestSortOrderIndex + idx;
      const formdata = new FormData();
      let extra_data = [];
      if (designType !== "self-design") {
        const v = croppingConfiguration.find(
          (item) => item.type === designType
        );

        const { sizes } = v;
        extra_data = JSON.stringify(
          sizes.find((item) => item.name === getCleanName(albumRatio))
        );
        formdata.append("extra_data", extra_data);
      }
      if (
        designType !== "self-design" &&
        !file[idx]?.name?.toLowerCase().includes("cover") &&
        !projectDetail?.files[0]?.original_name.toLowerCase().includes("cover")
      ) {
        index += 1;
      }

      formdata.append("file", file[idx]);
      formdata.append("project_id", projectId);
      if (!file[idx]?.name.toLowerCase().includes("cover")) {
        formdata.append("order", checkOrder(file[idx]?.name?.toLowerCase()));
      }

      if (
        designType !== "self-design" &&
        file[idx].name.toLowerCase().includes("cover")
      ) {
        formdata.append("sort_order", 0);
        formdata.append("is_cover_image", 1);
      } else {
        let sort = index;
        let number = getSortOrder(file[idx]?.name?.toLowerCase(), idx);
        if (number) {
          sort = number;
        }
        formdata.append("sort_order", sort);
        formdata.append("is_cover_image", 0);
      }

      onPressFileUpload(formdata);
    });
  };
  const getSortOrder = (fileName, idx) => {
    let curName = fileName.split("_");
    let lastName = curName[curName.length - 1];
    let fName = lastName.split(".");
    const fileTypes = ["jpg", "jpeg", "png"];
    let notProperName = fileTypes.some((fileTypes) =>
      fName[0].includes(fileTypes)
    );
    if (notProperName) {
      return idx + 1;
    }
    if (fName.length > 0 && fName.includes()) {
      return parseInt(fName[0].replace(/\D/g, ""));
    }
    return null;
  };
  const checkOrder = (fileName) => {
    let number = getSortOrder(fileName);
    if (number) {
      if (number % 2 === 0) {
        return "right";
      } else {
        return "left";
      }
    }
    return "";
  };

  return (
    <>
      <Navbar
        title="Create Project"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <CustomLoader isOpen={loading} />
      {Prompt}
      <Grid p={2} pl={3} container item xs={12}>
        <Box
          sx={{ width: "100%", mb: 1 }}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            sx={{
              mr: 2,
            }}
            variant="contained"
            onClick={backToCreateProject}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onPressUploadFile();
              setisFileUploadModalOpen(true);
            }}
            disabled={isBtnDisable}
          >
            Upload File
          </Button>
        </Box>
        <Box sx={{ width: "100%" }}>
          <FileType
            designType={designType}
            handleChange={handleChange}
            handleClickOpen={handleClickOpen}
            uploadedFiles={uploadedFiles}
            albumRatio={albumRatio}
            setLayoutType={setLayoutType}
            layoutType={layoutType}
            setAlbumRatio={setAlbumRatio}
            setAlertAlbumRatio={setAlertAlbumRatio}
            setAlertLayoutType={setAlertLayoutType}
            setAlertDesignType={setAlertDesignType}
          />
        </Box>
        <Box>
          <h3>Files Uploaded</h3>
          {Prompt}
          {uploadedFiles?.length > 0 && uploadedFiles && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              {uploadedFiles.map((item, i) => (
                <UploadedImg
                  key={`${item}_${i}`}
                  index={i}
                  {...item}
                  item={item}
                  onPressFileUpload={onPressFileUpload}
                  onDeleteFile={onDeleteFile}
                />
              ))}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={uploadedFiles?.length === 0 || disableNextBtn || loading}
            variant="contained"
            onClick={checkValidationBasedOnCountAndRearrange}
          >
            Next, arrage pages
          </Button>
        </Box>
        <FileUploadModal
          isModalVisible={isFileUploadModalOpen}
          onClose={() => setisFileUploadModalOpen(false)}
          onChangeFileUpload={onChangeFileUpload}
        />
        <AlertDialog
          isAlertDialogOpen={isAlertDialogOpen}
          onCanel={onCanelAlertDialog}
          onOk={onOkAlertDialog}
          display="All the uploaded images will be lost!"
        />
        <ErrorDialog
          isErrorAlertDialogOpen={isErrorAlertDialogOpen}
          onOk={onErrorOkAlertDialog}
          message={alertMessage}
        />
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { projectFiles } = state.project;
  return { projectFiles };
}
export default connect(mapStateToProps)(ChooseLayout);
