import { Avatar, Box, IconButton } from "@mui/material";
import React from "react";
const imgUploadPlaceholder1 = "/assets/imgUploadPlaceholder.png";

function ImgUploadPlaceholder({
  browserFiles,
  onChangeFile,
  imgPath,
  variant = "circle",
  radius = 25,
}) {
  return (
    <Box>
      <IconButton
        sx={{
          padding: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={() => document.getElementById("browserFile").click()}
      >
        {imgPath ? (
          <Box
            sx={{
              width: 110,
              overflow: "hidden",
              height: 110,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 0,
            }}
          >
            {/* <img src={imgPath} alt="imgUploadPlaceholder" width={"100%"} /> */}
            <Avatar
              sx={{ width: 110, height: 110, borderRadius: radius }}
              alt="imgUploadPlaceholder"
              src={imgPath}
              variant={variant}
            />
          </Box>
        ) : (
          <img
            src={imgUploadPlaceholder1}
            alt="imgUploadPlaceholder"
            width={100}
          />
        )}

        <input
          type="file"
          onChange={(e) => onChangeFile(e)}
          placeholder="Please input your channel name"
          label="Your Channel Name"
          style={{ display: "none" }}
          id="browserFile"
          accept=".jpg, .png"
        />
      </IconButton>
    </Box>
  );
}

export default ImgUploadPlaceholder;
