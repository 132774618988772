import { Alert, Button, FormControl, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { categoryValidationMessage } from "../../../common/constants";
import CustomInput from "../../../components/CustomInput";
import CustomLoader from "../../../components/CustomLoader";
import Navbar from "../../../components/Navbar";
import { createCategory } from "../../../store/slices/CategorySlice";

function CreateCategory(props) {
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loader } = props;

  const [category, setCategory] = useState("");
  const [isBtnDisable, setIsBtnDisable] = useState(true);
  const [loading, setLoading] = useState(loader ?? false);
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState(
    []
  );
  const [categoryValidationMsg, setCategoryValidationMsg] = useState("");

  useEffect(() => {
    if (category) {
      setIsBtnDisable(false);
    } else {
      setIsBtnDisable(true);
    }
  }, [category]);

  const categoryValid = (value) => {
    if (value.length === 0) {
      setCategoryValidationMsg(categoryValidationMessage);
    } else {
      setCategoryValidationMsg("");
    }
  };

  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  const onPressCreateCategory = () => {
    const payload = {
      name: category,
      active: true,
    };

    if (!isBtnDisable) {
      dispatch(createCategory(payload)).then((res) => {
        if (res?.payload.data?.errors) {
          setServerSideValidationErrors(
            Object.values(res?.payload.data?.errors)
          );
        } else {
          navigate("/category");
        }
        setLoading(false);
      });
    } else {
      categoryValid(category);
    }
  };

  return (
    <>
      <Navbar
        title="Create Category"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      {loading ? (
        <CustomLoader />
      ) : (
        <Grid p={2} container item xs={12}>
          <Box
            sx={{ width: "100%", mb: 1 }}
            display="flex"
            justifyContent="flex-end"
          >
            <Button variant="contained" onClick={() => onPressCreateCategory()}>
              Save
            </Button>
          </Box>
          <FormControl sx={{ 
            mb: 3, 
            mt: 2, 
            width: "60%",
            '@media only screen and (max-width: 768px)': {
              width: '100%',
            }
            }}>
            <CustomInput
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
                categoryValid(e.target.value);
              }}
              placeholder="Please input Category name"
              label="Category Name"
              validationMessage={categoryValidationMsg}
            />
            {serverSideValidationErrors &&
              serverSideValidationErrors.map((item, i) => (
                <Alert key={`${item}_${i}`} sx={{ mt: 2.5 }} severity="error">
                  {item}
                </Alert>
              ))}
          </FormControl>
        </Grid>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { loader } = state.category;
  return { loader };
}
export default connect(mapStateToProps)(CreateCategory);
