import { Box } from "@mui/material";

export default function DeleteDataInstructions() {
  return (
    <Box sx={{ padding: "30px" }}>
      <h1>User Data Deletion Policy</h1>

      <p>
        ubabybaby respects your privacy and provides you with the right to
        remove your data from our services. This policy outlines the steps for
        requesting data deletion and guides you on how to remove "ubabybaby
        flipbook" from your Facebook account.
      </p>

      <h3>Removing "ubabybaby flipbook" from Your Facebook Account:</h3>

      <ol>
        <li>Log in to your Facebook account.</li>
        <li>
          Click the down arrow at the top right of Facebook and select "Settings
          & Privacy" &gt; "Settings."
        </li>
        <li>In the left column, click "Apps and Websites."</li>
        <li>
          You will see a list of all the apps and websites linked to your
          Facebook account. Find "ubabybaby flipbook" in the list and check the
          box next to it.
        </li>
        <li>Click "Remove" and confirm the removal when prompted.</li>
        <li>
          Once you remove the app, "ubabybaby flipbook" will no longer have
          access to your data via Facebook, but data previously collected will
          still reside in ubabybaby's systems.
        </li>
      </ol>

      <h3>Requesting Data Deletion from ubabybaby:</h3>

      <p>
        If you wish to have all your data removed from ubabybaby's systems,
        please follow the steps below:
      </p>

      <ol>
        <li>
          <strong>Email Request:</strong>
        </li>
        <ul>
          <li>
            Send an email to privacy@ubabybaby.com with the subject "Request for
            User Data Deletion."
          </li>
          <li>
            Include your full name and email address associated with your
            account in "ubabybaby flipbook" to help us accurately identify your
            data for deletion.
          </li>
        </ul>
        <li>
          <strong>Verification and Processing:</strong>
        </li>
        <ul>
          <li>
            Our team will verify your identity and proceed with your data
            deletion request upon validation.
          </li>
          <li>
            We commit to deleting your data from our records and any third
            parties involved within 30 days of your request's verification.
          </li>
        </ul>
        <li>
          <strong>Confirmation:</strong>
        </li>
        <ul>
          <li>
            You will receive a confirmation email once all your data has been
            deleted from our systems.
          </li>
        </ul>
      </ol>

      <h3>Need Assistance?</h3>

      <p>
        For any questions or further assistance, please contact our customer
        support at cs@ubabybaby.com. We are dedicated to ensuring your privacy
        and data security.
      </p>

      <h3>Our Commitment:</h3>

      <p>
        ubabybaby is committed to the protection of your data. We continually
        update our policies and practices to ensure the highest standards of
        data privacy are maintained. Thank you for trusting "ubabybaby flipbook"
        with your information.
      </p>
    </Box>
  );
}
