export const firstNameValidationMessage = "Please enter a first name";
export const lastNameValidationMessage = "Please enter a last name";
export const emailValidationMessage = "Please enter valid email address";
export const passwordValidationMessage =
  "Password must contain one uppercase, lowercase, number, special character & length should be at least 8 character";
export const channelValidationMessage = "Please enter channel name";
export const channeDescriptionValidationMessage =
  "Please enter channel description";
export const roleValidationMessage = "Please choose role";
export const categoryValidationMessage = "Please enter category name";
export const passwordValidationNonEmptyMessage = "Please enter password";

// width
export const currentWindoWidth = window?.innerWidth;

// token & headers
const token = localStorage.getItem("accessToken");
export const headers = {
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

export const PhotoBookCheckBoxData = [
  "A5 Portrait",
  "A5 Landscape",
  "A4 Portrait",
  "A4 Landscape",
  "A3 Portrait",
  "A3 Landscape",
  "12x18",
  "18x12",
  "11x17",
  "17x11",
  "11x15",
  "15x11",
  "10x15",
  "15x10",
  "8X12",
  "12x8",
  "8x10",
  "10x8",
  "11x11",
  "8x8",
];

export const optionsData = [
  { name: "Title" },
  { name: "Description" },
  { name: "Category" },
  { name: "Visibility" },
];

export const visibilityData = [
  { name: "Public" },
  { name: "Unlisted" },
  { name: "Private" },
];

export const userSearchOptions = [
  { name: "Name" },
  { name: "Email" },
  { name: "Status" },
];

export const imgTypeError = "Only JPEG, JPG and PNG image is allowed!";
export const imgSizeError = "Please upload an image smaller than 5MB!";

export const discoverDropdownSearchOptions = [
  { name: "Title" },
  { name: "Category" },
  { name: "Channel" },
];

export const discoverDropdownSearchOptionsFromChannel = [
  { name: "Title" },
  { name: "Category" },
];
