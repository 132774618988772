/* eslint-disable no-unused-vars */
import { Alert, Box, Button, FormControl, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { passwordValidationMessage } from "../../common/constants";
import { isPasswordValid } from "../../common/helper";
import { updatePasswordFromProfile } from "../../store/slices/authenticationSlice";
import CustomInput from "../CustomInput";
import CustomLoader from "../CustomLoader";

function PasswordForm({ setChannel }) {
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState(
    []
  );

  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [passwordConfirmMsg, setPasswordConfirmMsg] = useState("");
  const [passwordNewMsg, setPasswordNewMsg] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  useEffect(() => {
    if (currentPassword && newPassword && confirmPassword) {
      setIsBtnDisabled(false);
      setServerSideValidationErrors();
    } else {
      setIsBtnDisabled(true);
      setIsFormValid(false);
    }
    setServerSideValidationErrors();
    setSuccessMessage();
  }, [currentPassword, newPassword, confirmPassword, isFormValid]);

  const updatePassword = () => {
    setLoading(true);
    const payload = {
      current_password: currentPassword,
      password: newPassword,
      confirmed_password: confirmPassword,
    };

    dispatch(updatePasswordFromProfile(payload))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res?.data?.errors) {
          setServerSideValidationErrors(Object.values(res?.data?.errors));
        } else {
          setChannel(false);
          setToastMessage(res?.data?.message);
          setIsToastOpen(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("catchError:", err);
      });
  };

  const passwordValid = (value) => {
    if (isPasswordValid(value)) {
      setPasswordValidationMsg(passwordValidationMessage);
      setIsFormValid(false);
    } else {
      setPasswordValidationMsg("");
      setIsFormValid(true);
    }
  };

  const isNewPasswordValid = (value) => {
    if (isPasswordValid(value)) {
      setPasswordNewMsg(passwordValidationMessage);
      setIsFormValid(false);
    } else {
      setPasswordNewMsg("");
      setIsFormValid(true);
    }
  };

  const isconfirmPasswordValid = (value) => {
    // if (isPasswordValid(value)) {
    //   setPasswordConfirmMsg(passwordValidationMessage);
    // } else {
    //   setPasswordConfirmMsg("");
    // }
  };

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setPasswordConfirmMsg(confirmPassword && "Password did not match");
      setIsBtnDisabled(true);
    } else {
      setPasswordConfirmMsg("");
    }
  }, [confirmPassword, newPassword]);

  return (
    <>
      <CustomLoader isOpen={loading} />
      <Box
        sx={{
          width: "100%",
          pt: 2,
        }}
      >
        <Box sx={{ 
          width: "60%",
          '@media only screen and (max-width: 768px)': {
            width: '100%',
          }, 
          }}>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <CustomInput
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
                passwordValid(e.target.value);
                setChannel(true);
              }}
              placeholder="Please input you current password"
              label="Current Password"
              isInputTypePassword
              validationMessage={passwordValidationMsg}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <CustomInput
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                isNewPasswordValid(e.target.value);
                setChannel(true);
              }}
              placeholder="Please input you new password"
              label="New Password"
              isInputTypePassword
              validationMessage={passwordNewMsg}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <CustomInput
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                isconfirmPasswordValid(e.target.value);
              }}
              placeholder="Please confirm you new password"
              label="Confirm New Password"
              isInputTypePassword
              validationMessage={passwordConfirmMsg}
            />
          </FormControl>
          {serverSideValidationErrors &&
            serverSideValidationErrors.map((item, i) => (
              <Alert key={`${item}_${i}`} sx={{ mt: 2.5 }} severity="error">
                {item}
              </Alert>
            ))}
          {successMessage && !serverSideValidationErrors && (
            <Alert severity="success">{successMessage}</Alert>
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            mt: 1,
          }}
        >
          <Button
            disabled={isBtnDisabled && !isFormValid}
            variant="contained"
            onClick={updatePassword}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={6000}
        onClose={() => setIsToastOpen(false)}
      >
        <Alert
          onClose={() => setIsToastOpen(false)}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default PasswordForm;
