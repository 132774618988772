import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const headers = {
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
};

export const getProjectById = createAsyncThunk("getProjectById", async (id) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return await axios.get(`${apiUrl}project/${id}`, headers);
});

export const getFlipBookHeightWidth = createAsyncThunk(
  "getFlipBookHeightWidth",
  async (id) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return await axios.get(
      `${apiUrl}project/flip-book-height-width/${id}`,
      headers
    );
  }
);

export const addComment = createAsyncThunk("addComment", async (payload) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return await axios.post(`${apiUrl}comment`, payload, headers);
});

export const addReply = createAsyncThunk("addReply", async (payload) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  try {
    return await axios.post(`${apiUrl}comment`, payload, headers);
  } catch (err) {
    console.log("err", err);
    return err.response;
  }
});

export const getProjects = createAsyncThunk("getProjects", async (payload) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return await axios.get(`${apiUrl}project`, headers);
});
export const fecthCroppingConfiguration = createAsyncThunk(
  "fecthCroppingConfiguration",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return await axios.get(`${apiUrl}cropping-rules`, headers);
  }
);
export const getCroppedFilesByProject = createAsyncThunk(
  "getCroppedFilesByProject",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return await axios.get(`${apiUrl}discovery/images/${payload}`, headers);
  }
);

export const subscribeProject = createAsyncThunk(
  "subscribeProject",
  async (payload) => {
    try {
      return await axios.post(`${apiUrl}channel/subscribe`, payload, headers);
    } catch (err) {
      return err.response;
    }
  }
);

export const likeProject = createAsyncThunk(
  "likeProject",
  async (payload) =>
    await axios.get(`${apiUrl}project/${payload}/like`, headers)
);

export const dislikeProject = createAsyncThunk(
  "dislikeProject",
  async (payload) =>
    await axios.get(`${apiUrl}project/${payload}/dislike`, headers)
);
export const sortComments = createAsyncThunk(
  "sortComments",
  async (payload) =>
    await axios.get(
      `${apiUrl}project/${payload.id}/sortComments/${payload.by}`,
      headers
    )
);
export const increaseViewProject = createAsyncThunk(
  "increaseViewProject",
  async (payload) =>
    await axios.get(`${apiUrl}project/${payload}/increase-view`, headers)
);
export const createProject = createAsyncThunk(
  "createProject",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(`${apiUrl}project`, payload, headers);
    } catch (err) {
      return err.response;
    }
  }
);

export const updateProject = createAsyncThunk(
  "updateProject",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    payload.data._method = "PATCH";
    return await axios.post(
      `${apiUrl}project/${payload.id}`,
      payload.data,
      headers
    );
  }
);

export const projectFileUpload = createAsyncThunk(
  "projectFileUpload",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}project/uploadAndSave`,
        payload,
        headers
      );
    } catch (err) {
      return err.response;
    }
  }
);

export const projectFileDelete = createAsyncThunk(
  "projectFileDelete",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.delete(
        `${apiUrl}project/delete-uploaded-file/${payload}`,
        headers
      );
    } catch (err) {
      return err.response;
    }
  }
);

export const deletedProject = createAsyncThunk("deletedProject", async (id) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  try {
    return await axios.delete(`${apiUrl}project/${id}`, headers);
  } catch (err) {
    return err.response;
  }
});

export const publishProject = createAsyncThunk("publishProject", async (id) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  try {
    return await axios.post(
      `${apiUrl}project/publish/${id}`,
      { _method: "PATCH" },
      headers
    );
  } catch (err) {
    return err.response;
  }
});

export const likeComment = createAsyncThunk("likeComment", async (payload) => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return await axios.post(`${apiUrl}comment/${payload}/like`, [], headers);
});

export const disLikeComment = createAsyncThunk(
  "disLikeComment",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    return await axios.post(`${apiUrl}comment/${payload}/dislike`, [], headers);
  }
);

export const projectSearch = createAsyncThunk(
  "projectSearch",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}project/project-search`,
        payload,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const reArrangeImages = createAsyncThunk(
  "reArrangeImages",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.put(
        `${apiUrl}project/rearrange-images/${payload.id}`,
        payload.data,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const projectPagination = createAsyncThunk(
  "projectPagination",
  async (payObj) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.get(
        `${apiUrl}project?page=${payObj.page}&size=${payObj.pageSize}`,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const projectSearchPagination = createAsyncThunk(
  "projectSearchPagination",
  async (payload) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.post(
        `${apiUrl}project/project-search?page=${payload.id}`,
        payload.data,
        headers
      );
    } catch (err) {
      return err?.response;
    }
  }
);

export const getSubscription = createAsyncThunk("getSubscription", async () => {
  headers.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  try {
    return await axios.get(`${apiUrl}project/my-subscribed/projects`, headers);
  } catch (err) {
    return err?.response;
  }
});

export const deleteCroppedOnBackButton = createAsyncThunk(
  "deleteCroppedOnBackButton",
  async (id) => {
    headers.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    try {
      return await axios.delete(
        `${apiUrl}project/delete-cropped-on-back/${id}`,
        headers
      );
    } catch (err) {
      return err.response;
    }
  }
);
