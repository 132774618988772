import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage"; // defaults to localS
import CategorySliceReducer from "./slices/CategorySlice";
import adminReducer from "./slices/adminSlice";
import authSliceReducer from "./slices/authenticationSlice";
import discoverSliceReducer from "./slices/discover/discoverSlice";
import projectSliceReducer from "./slices/projectSlice";
import roleSliceReducer from "./slices/roleSlice";

const persistConfig = {
  timeout: 100,
  key: "root",
  storage,
  blacklist: ["project", "admin.isFullScreen"],
};

const persistProjectConfig = {
  timeout: 100,
  key: "project",
  storage,
  blacklist: [""],
};

const reducers = combineReducers({
  admin: adminReducer,
  project: persistReducer(persistProjectConfig, projectSliceReducer),
  category: CategorySliceReducer,
  role: roleSliceReducer,
  authentication: authSliceReducer,
  discover: discoverSliceReducer,
});

const store = configureStore({
  reducer: persistReducer(persistConfig, reducers),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export default store;
