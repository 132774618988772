import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Fade, Menu, MenuItem } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { adminActions } from "../store/slices/adminSlice";
import { logout } from "../store/slices/authenticationSlice";
import { projectActions } from "../store/slices/projectSlice";
import useSystemStore from "../store/system";

function Navbar({ toggleDrawer, title, isDrawerOpen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    navigate("/profile");
  };
  const isLoggedIn = useSelector((state) => state.authentication.accessToken);
  const currentUser = useSelector((state) => state.authentication.currentUser);
  const isFullScreen = useSystemStore((state) => state.isFullScreen);

  const onPressLogout = () => {
    setLoading(true);
    handleClose();
    dispatch(projectActions.setNull());
    dispatch(adminActions.setNull());
    dispatch(logout()).then((res) => {
      if (res?.payload?.data?.status) {
        setLoading(false);
        navigate("/login");
      }
    });
  };
  const backToDiscover = () => {
    navigate("/discover");
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{ display: isFullScreen ? "none" : "inherit" }}
        className={isDrawerOpen && isLoggedIn ? "main-bar" : ""}
      >
        <Toolbar variant="dense">
          {isLoggedIn && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => (!isDrawerOpen ? toggleDrawer() : {})}
            >
              {!isDrawerOpen ? (
                <MenuIcon />
              ) : (
                <>
                  {title === "Users" && <PeopleIcon />}
                  {title === "Create User" && <PeopleIcon />}
                  {title === "Modify User" && <PeopleIcon />}
                  {title === "My Account" && <PersonIcon />}
                </>
              )}
            </IconButton>
          )}
          {/* <Typography variant="h6" color="inherit" component="div">
            {title === "Discover" ? (
              <Box sx={{ cursor: "pointer" }} onClick={backToDiscover}>
                {title || "FlippingBook"}
              </Box>
            ) : (
              title || "FlippingBook"
            )}
          </Typography> */}
          <Box sx={{ flexGrow: 1 }} />
          {isLoggedIn && (
            <IconButton
              onClick={handleClick}
              sx={{ ml: "auto", cursor: "pointer" }}
            >
              {isLoggedIn ? (
                <Avatar
                  sx={{ width: 25, height: 25, mr: 2 }}
                  alt={currentUser.first_name}
                  src={currentUser.image_url}
                />
              ) : (
                <AccountCircleIcon
                  sx={{ fontSize: "1.3em", color: "#EEEEEE" }}
                />
              )}
            </IconButton>
          )}
          {!isLoggedIn && location.pathname !== "/login" && (
            <Link
              to="/login"
              style={{
                textDecoration: "none",
              }}
            >
              <Typography color="white" variant="body2">
                Login
              </Typography>
            </Link>
          )}
          <Menu
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            PaperProps={{ sx: { width: "120px", ml: "-2em" } }}
          >
            <MenuItem onClick={handleProfile} disableRipple>
              Profile
            </MenuItem>
            <MenuItem onClick={onPressLogout} disableRipple>
              Sign Out
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

function mapStateToProps() {
  return {};
}
export default connect(mapStateToProps)(Navbar);
