import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Fade, IconButton, Menu, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import styles from "../../styles/Components/SelectInput.module.scss";
import MultipleSelectChip from "../Project/Search/MultipleSelectChip";
import MenuList from "./MenuList";

export default function UserSearchBar({
  onChangeSelect,
  onInputChange,
  handleKeyDown,
  onStatusChange,
  options,
  isClearSearch,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [value, setValue] = useState("name");
  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);
  const [isInputOpen, setIsInputOpen] = useState(true);
  const [visibilityArr, setVisibilityArr] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState("");

  // checkbox state's
  const [isActive, setIsActive] = useState(false);
  const [isLock, setIsLock] = useState(false);
  const [isWaitingApproval, setIsWaitingApproval] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const [approved, setApproved] = useState(false);
  const onCloseStatus = () => {
    setAnchorEl(null);
    setIsStatusMenuOpen(!isStatusMenuOpen);
  };

  useEffect(() => {
    setVisibilityArr([]);
    setInputSearchValue("");
    setIsActive(false);
    setIsLock(false);
    setIsWaitingApproval(false);
    setApproved(false);
    setIsInactive(false);
    if (value === "status") {
      setValue("name");
      setIsInputOpen(true);
      onChangeSelect("name");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClearSearch]);

  const onPressMenu = (type) => {
    if (type === "status") {
      setValue(type);
      onChangeSelect(type);
      setIsStatusMenuOpen(true);
      setIsInputOpen(false);
      setInputSearchValue("");
    } else {
      setValue(type);
      onChangeSelect(type);
      handleClose();
      setIsInputOpen(true);
      setVisibilityArr([]);
      setInputSearchValue("");
      setIsActive(false);
      setIsLock(false);
      setIsWaitingApproval(false);
      setIsInactive(false);
      setApproved(false);
    }
  };

  useEffect(() => {
    onStatusChange({
      active: isActive ? "1" : "0",
      is_locked: isLock ? "1" : "0",
      is_applied: isWaitingApproval ? "1" : "0",
      is_inactive: isInactive ? "1" : "0",
      is_approved: approved ? "1" : "0",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, isLock, isWaitingApproval, isInactive, approved]);
  const setStatusValue = (type, value) => {
    if (!type) {
      visibilityArr.push(value);
    } else {
      const idx = visibilityArr.findIndex((item) => item === value);
      visibilityArr.splice(idx, 1);
    }
    setVisibilityArr(visibilityArr);
  };

  const onRemove = (index, value) => {
    if (value === "Active") {
      const isActive = value !== "Active";
      setIsActive(isActive);
    } else if (value === "Lock") {
      const isLock = value !== "Lock";
      setIsLock(isLock);
    } else if (value === "InActive") {
      const isinActive = value !== "InActive";
      setIsInactive(isinActive);
    } else if (value === "Approved") {
      const isApproved = value !== "Approved";
      setApproved(isApproved);
    } else {
      const isAwaiting = value !== "Awaiting approval";
      setIsWaitingApproval(isAwaiting);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.select}>
        <Box
          sx={{
            border: "1px solid #EEEEEE",
            borderRadius: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {open ? (
            <IconButton onClick={handleClick}>
              <KeyboardDoubleArrowUpIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleClick}>
              <KeyboardDoubleArrowDownIcon />
            </IconButton>
          )}
          {isInputOpen ? (
            <Box sx={{ mt: -1 }}>
              <TextField
                value={inputSearchValue}
                onChange={(e) => {
                  onInputChange(e.target.value);
                  setInputSearchValue(e.target.value);
                }}
                onKeyDown={(e) => handleKeyDown(e)}
                placeholder={`Search ${value}`}
                size="small"
                InputProps={
                  {
                    // classes: { notchedOutline: classes.noBorder },
                  }
                }
              />
            </Box>
          ) : (
            <MultipleSelectChip
              options={visibilityArr}
              onRemove={(index, value) => onRemove(index, value)}
            />
          )}
        </Box>

        {!isStatusMenuOpen ? (
          <Menu
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            PaperProps={{ sx: { width: "250px" } }}
          >
            {options &&
              options?.map((item, i) => (
                <MenuItem
                  key={`${item.name}_${i}`}
                  onClick={() => onPressMenu(item.name.toLowerCase())}
                >
                  {item.name}
                </MenuItem>
              ))}
          </Menu>
        ) : (
          <Menu
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={onCloseStatus}
            TransitionComponent={Fade}
            PaperProps={{ sx: { width: "250px" } }}
          >
            <MenuList
              checked={isActive}
              onPressMenuList={() => {
                setIsActive(!isActive);
                setStatusValue(isActive, "Active");
              }}
              title="Active"
            />
            <MenuList
              checked={isLock}
              onPressMenuList={() => {
                setIsLock(!isLock);
                setStatusValue(isLock, "Lock");
              }}
              title="Lock"
            />
            <MenuList
              checked={isWaitingApproval}
              onPressMenuList={() => {
                setIsWaitingApproval(!isWaitingApproval);
                setStatusValue(isWaitingApproval, "Awaiting approval");
              }}
              title="Applied"
            />
            <MenuList
              checked={isInactive}
              onPressMenuList={() => {
                setIsInactive(!isInactive);
                setStatusValue(isInactive, "InActive");
              }}
              title="InActive"
            />
            <MenuList
              checked={approved}
              onPressMenuList={() => {
                setApproved(!approved);
                setStatusValue(approved, "Approved");
              }}
              title="Approved"
            />
          </Menu>
        )}
      </div>
    </div>
  );
}
