import { Alert, Box, Snackbar } from "@mui/material";
import { useState } from "react";
import Navbar from "../../components/Navbar";
import LoginStyle from "../../styles/Register.module.scss";

const imgPath = "/assets/Placeholder.png";

export default function ConfirmEmail() {
  const [isToastOpen, setIsToastOpen] = useState(true);
  return (
    <>
      <Navbar title="Confirm email" />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          sx={{ width: "35em", p: 1, mt: "8em" }}
          className={LoginStyle.inputContaier}
        >
          <Box textAlign="center">
            <h3>Confirm your email</h3>
            <p>We need to verify you’re human</p>
            <img src={imgPath} alt="logo" />
            <p>
              Click the link in the email we just sent you to confirm your email
              address.
            </p>
          </Box>
          <Snackbar
            open={isToastOpen}
            autoHideDuration={3000}
            onClose={() => setIsToastOpen(false)}
          >
            <Alert severity="success">Sign up successfully!</Alert>
          </Snackbar>
        </Box>
      </Box>
    </>
  );
}
