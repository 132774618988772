import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  channelValidationMessage,
  emailValidationMessage,
  firstNameValidationMessage,
  lastNameValidationMessage,
  passwordValidationMessage,
  roleValidationMessage,
} from "../../common/constants";
import { isPasswordValid, validateEmail } from "../../common/helper";
import CustomInput from "../../components/CustomInput";
import CustomLoader from "../../components/CustomLoader";
import ErrorComponent from "../../components/Error";
import Navbar from "../../components/Navbar";
import {
  fetchEditUserData,
  updateUser,
} from "../../store/slices/admin/adminAction";
import { getRoles } from "../../store/slices/roleSlice";

function EditUser(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: userId } = useParams(); // get user id from url
  const { loader, userDetails, roles } = props;

  const [openDrawer, setOpenDrawer] = useOutletContext();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [channel, setChannel] = useState("");
  const [password, setPassword] = useState("");
  const [channeldisplay, setChanneldisplay] = useState("");

  const [loading, setLoading] = useState(loader ?? false);
  const [isBtnDisable, setIsBtnDisable] = useState(true);

  const [emailValidationMsg, setEmailValidationMsg] = useState("");
  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [firstNameValidationMsg, setFirstNameValidationMsg] = useState("");
  const [lastNameValidationMsg, setLastNameValidationMsg] = useState("");
  const [channelValidationMsg, setChannelValidationMsg] = useState("");
  const [roleValidationMsg, setRoleValidationMsg] = useState("");
  const [isVisibleChannel, setIsVisibleChannel] = useState(true);
  const [roleList, setRoleList] = useState(roles ?? []);

  useEffect(() => {
    setLoading(loader);
  }, [loader]);
  useEffect(() => {
    if (role === 3) {
      setChannel("");
      setChanneldisplay("");
      setIsVisibleChannel(false);
    } else {
      setIsVisibleChannel(true);
    }
  }, [role]);
  // Autofill all user data
  useEffect(() => {
    setFirstName(userDetails?.first_name);
    setLastName(userDetails?.last_name);
    setEmail(userDetails?.email);
    setRole(userDetails?.role_id);
    setChannel(userDetails?.channel);
    setChanneldisplay(userDetails?.channel_name);
  }, [userDetails]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchEditUserData(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (firstName && lastName && email && role && channel) {
      setIsBtnDisable(false);
    } else if (firstName && lastName && email && (role || role === 3)) {
      setIsBtnDisable(false);
    } else {
      setIsBtnDisable(true);
    }
  }, [firstName, lastName, email, role, channel]);

  const onPressUpdate = () => {
    const payload = {
      data: {
        first_name: firstName,
        last_name: lastName,
        email,
        role_id: role,
        active: true,
        channel: channeldisplay,
        password,
      },
      id: userId,
    };

    if (!isBtnDisable) {
      setLoading(true);
      dispatch(updateUser(payload)).then(() => {
        setLoading(false);
        navigate("/users");
      });
    } else {
      firstNameLength(firstName);
      lastNameValid(lastName);
      emailValid(email);
      passwordValid(password);
      channelValid(channel);
      roleValid(role);
    }
  };

  const firstNameLength = (value) => {
    if (value.length === 0) {
      setFirstNameValidationMsg(firstNameValidationMessage);
    } else {
      setFirstNameValidationMsg("");
    }
  };

  const lastNameValid = (value) => {
    if (value.length === 0) {
      setLastNameValidationMsg(lastNameValidationMessage);
    } else {
      setLastNameValidationMsg("");
    }
  };

  const emailValid = (value) => {
    if (value.length === 0) {
      setEmailValidationMsg(emailValidationMessage);
    }

    if (!validateEmail(value)) {
      setEmailValidationMsg(emailValidationMessage);
    } else {
      setEmailValidationMsg("");
    }
  };

  const passwordValid = (value) => {
    if (password !== "") {
      if (isPasswordValid(value)) {
        setPasswordValidationMsg(passwordValidationMessage);
      } else {
        setPasswordValidationMsg("");
      }
    }
  };

  const channelValid = (value) => {
    if (value.length === 0 && role !== 3) {
      setChannelValidationMsg(channelValidationMessage);
    } else {
      setChannelValidationMsg("");
    }
  };

  const roleValid = (value) => {
    if (value.length === 0) {
      setRoleValidationMsg(roleValidationMessage);
    } else {
      setRoleValidationMsg("");
    }
  };

  // Roles
  useEffect(() => {
    // make dispatch request only if roleList is empty.
    dispatch(getRoles());
  }, [dispatch]);

  useEffect(() => {
    setRoleList(roles);
  }, [roles]);

  return (
    <>
      <Navbar
        title="Modify User"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <CustomLoader isOpen={loading} />
      <Grid p={2} container item xs={12}>
        <Box
          sx={{ width: "100%", mb: 2 }}
          display="flex"
          justifyContent="flex-end"
        >
          <Button variant="contained" onClick={() => onPressUpdate()}>
            Save
          </Button>
        </Box>
        <FormControl
          sx={{
            mb: 3,
            mt: 2,
            width: "60%",
            "@media only screen and (max-width: 768px)": { width: "100%" },
          }}
        >
          <CustomInput
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              firstNameLength(e.target.value);
            }}
            placeholder="Please input your first name"
            label="First Name" 
            validationMessage={firstNameValidationMsg}
          />
        </FormControl>
        <FormControl
          sx={{
            mb: 3,
            width: "60%",
            "@media only screen and (max-width: 768px)": { width: "100%" },
          }}
        >
          <CustomInput
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              lastNameValid(e.target.value);
            }}
            placeholder="Please input your last name"
            label="Last Name"
            validationMessage={lastNameValidationMsg}
          />
        </FormControl>
        <FormControl
          sx={{
            mb: 3,
            width: "60%",
            "@media only screen and (max-width: 768px)": { width: "100%" },
          }}
          variant="outlined"
        >
          <CustomInput
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              emailValid(e.target.value);
            }}
            placeholder="Please input your email"
            label="Email Address"
            type="email"
            validationMessage={emailValidationMsg}
          />
        </FormControl>
        <FormControl
          sx={{
            mb: 3,
            width: "60%",
            "@media only screen and (max-width: 768px)": { width: "100%" },
          }}
        >
          <label id="demo-simple-select-label">Role</label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            label="Role"
            variant="standard"
            disableUnderline
            onChange={(e) => {
              setRole(e.target.value);
              roleValid(e.target.value);
            }}
          >
            {roleList &&
              roleList.map((item, i) => (
                <MenuItem key={item + i} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
          {roleValidationMsg && <ErrorComponent error={roleValidationMsg} />}
        </FormControl>
        {isVisibleChannel && (
          <>
            <FormControl sx={{ mb: 3, width: "60%", display: "none", "@media only screen and (max-width: 768px)": { width: "100%" } }}>
              <CustomInput
                value={channel}
                onChange={(e) => {
                  setChannel(e.target.value);
                  channelValid(e.target.value);
                }}
                placeholder="Please input your Channel"
                label="Channel"
                validationMessage={channelValidationMsg}
              />
            </FormControl>

            <FormControl sx={{ mb: 3, width: "60%", "@media only screen and (max-width: 768px)": { width: "100%" } }}>
              <CustomInput
                value={channeldisplay}
                onChange={(e) => {
                  setChanneldisplay(e.target.value);
                  setChannel(e.target.value);
                  channelValid(e.target.value);
                }}
                placeholder="Please input your Channel"
                label="Channel"
                validationMessage={channelValidationMsg}
              />
            </FormControl>
          </>
        )}
        <FormControl
          sx={{
            mb: 1,
            width: "60%",
            "@media only screen and (max-width: 768px)": { width: "100%" },
          }}
        >
          <CustomInput
            id="outlined-adornment-password"
            validationMessage={passwordValidationMsg}
            placeholder="******************"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              passwordValid(e.target.value);
            }}
            isInputTypePassword
            label="Password"
          />
        </FormControl>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { roles } = state.role;

  const { loader, status, userDetails } = state.admin;
  return { loader, status, userDetails, roles };
}

export default connect(mapStateToProps)(EditUser);
