import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RedoIcon from "@mui/icons-material/Redo";
import ShareIcon from "@mui/icons-material/Share";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";

function FlipMorebtn({
  project,
  onProjectEdit,
  onProjectDelete,
  onPressPublish,
  onPressShare,
  onPressUpload,
  onRearrange,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 35,
          }}
        >
          <MoreHorizIcon />
          <span style={{ fontSize: "13px", color: "#111111" }}>More</span>
        </Box>
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "demo-positioned-menu",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {project?.files_count > 0 && project?.is_published === 0 && (
          <MenuItem
            onClick={() => {
              onPressPublish();
              handleClose();
            }}
          >
            <RedoIcon sx={{ mr: 1, fontSize: 20 }} />
            Publish
          </MenuItem>
        )}
        {project?.files_count > 0 &&
          project?.is_published === 1 &&
          project?.visibility !== "private" && (
            <MenuItem
              onClick={() => {
                onPressShare();
                handleClose();
              }}
            >
              <ShareIcon sx={{ mr: 1, fontSize: 20 }} />
              Share
            </MenuItem>
          )}
        {project?.is_published === 0 && (
          <MenuItem
            onClick={() => {
              onPressUpload();
              handleClose();
            }}
          >
            <CloudUploadIcon sx={{ mr: 1, fontSize: 20 }} />
            Upload
          </MenuItem>
        )}
        {(project?.is_published === 1 || project?.files_count > 0) && (
          <MenuItem
            onClick={() => {
              onRearrange();
              handleClose();
            }}
          >
            <RedoIcon sx={{ mr: 1, fontSize: 20 }} />
            ReArrange
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            onProjectEdit();
            handleClose();
          }}
        >
          <EditIcon sx={{ mr: 1, fontSize: 20 }} />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            onProjectDelete();
            handleClose();
          }}
        >
          <DeleteIcon sx={{ mr: 1, fontSize: 20 }} />
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default FlipMorebtn;
