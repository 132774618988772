import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import configData from "../configData.json";

function AdminProtected({ children }) {
  const currentUser = useSelector((state) => state.authentication.currentUser);
  const location = useLocation();
  if (currentUser) {
    const isAdmin = configData.admins.includes(currentUser?.role_id);
    if (!isAdmin) {
      return (
        <Navigate to="/aceess-denied" state={{ from: location }} replace />
      );
    }
    return children;
  }
}

export default AdminProtected;
