import { arrayMove as dndKitArrayMove } from "@dnd-kit/sortable";

const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

const validateEmail = (value) => {
  if (value && value.match(isValidEmail)) {
    return true;
  }
  return false;
};

const isPasswordValid = (value) => {
  const pattern = new RegExp(
    "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&#])([a-zA-Z0-9@$!%*-?&#]{8,})$"
  );
  return !pattern.test(value);
};

const removeAtIndex = (array, index) => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
];

const insertAtIndex = (array, index, item) => [
  ...array.slice(0, index),
  item,
  ...array.slice(index),
];

const arrayMove = (array, oldIndex, newIndex) =>
  dndKitArrayMove(array, oldIndex, newIndex);

export {
  arrayMove,
  insertAtIndex,
  isPasswordValid,
  removeAtIndex,
  validateEmail,
};

// return category name
export const categoryName = (categories, categoryId) => {
  const currentCatgory = categories?.filter((item) => item.id === categoryId);
  return currentCatgory[0]?.name;
};
