import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import CustomLoader from "../../components/CustomLoader";
import Navbar from "../../components/Navbar";
import ProjectComment from "../../components/Project/Comments/ProjectComment";
import { getCategories } from "../../store/slices/CategorySlice";
import {
  getProjectById,
  updateProject,
} from "../../store/slices/project/projectAction";

function EditProject(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { projectDetails, categories } = props;
  const [openDrawer, setOpenDrawer] = useOutletContext();

  const [projectTitle, setProjectTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [visibility, setVisibility] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [categoryData, setCategoryData] = useState(categories ?? []);

  useEffect(() => {
    if (projectDetails) {
      setProjectTitle(projectDetails?.title);
      setDescription(projectDetails?.description);
      setCategory(projectDetails?.category_id);
      setVisibility(projectDetails?.visibility);
      setCommentData(projectDetails?.comments);
    }
  }, [projectDetails]);

  const [loading, setLoading] = useState(false);
  const visibilityData = [
    { name: "public", id: 1 },
    { name: "private", id: 2 },
    { name: "unlisted", id: 3 },
  ];

  useEffect(() => {
    dispatch(getProjectById(id));
  }, [dispatch, id]);

  useEffect(() => {
    // make dispatch request only if categoryData is null
    if (!categoryData.length) {
      dispatch(getCategories());
    }
  }, [dispatch, categoryData]);

  useEffect(() => {
    setCategoryData(categories);
  }, [categories]);

  const onPressSave = () => {
    const payload = {
      data: {
        title: projectTitle,
        category_id: category,
        visibility,
        description,
      },
      id,
    };

    dispatch(updateProject(payload)).then(() => {
      navigate("/my-projects");
    });
  };

  return (
    <>
      <Navbar
        title="Projects"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      {loading ? (
        <CustomLoader />
      ) : (
        <Grid p={2} pl={3} container item xs={12}>
          <Box
            sx={{ width: "100%", mb: 1 }}
            display="flex"
            justifyContent="flex-end"
          >
            <Button variant="contained" onClick={() => onPressSave()}>
              Save Project
            </Button>
          </Box>
          <FormControl sx={{ mb: 3, mt: 2, width: "60%", '@media only screen and (max-width: 768px)': { width: '100%', } }}>
            <CustomInput
              value={projectTitle}
              onChange={(e) => {
                setProjectTitle(e.target.value);
              }}
              placeholder="Please give your project a title"
              label="Project Title"
            />
          </FormControl>

          <Box sx={{ width: "60%", '@media only screen and (max-width: 768px)': { width: '100%', }, }} display="flex" justifyContent="space-between">
            <FormControl sx={{ mb: 3, width: "45%" }}>
              <label id="demo-simple-select-label">Category</label>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                variant="standard"
                disableUnderline
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                {categoryData &&
                  categoryData.map((item, i) => (
                    <MenuItem key={item + i} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 3, width: "45%" }}>
              <label id="demo-simple-select-label2">Visibility</label>
              <Select
                labelId="demo-simple-select-label2"
                value={visibility}
                label="Visibility"
                variant="standard"
                disableUnderline
                onChange={(e) => {
                  setVisibility(e.target.value);
                }}
              >
                {visibilityData &&
                  visibilityData.map((item, i) => (
                    <MenuItem key={item + i} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <FormControl sx={{ mb: 3, width: "60%", '@media only screen and (max-width: 768px)': { width: '100%', } }}>
            <CustomInput
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              placeholder="Tell viewser about your flipBook"
              label="Description"
              isMultiline
            />
          </FormControl>

          <Box sx={{ width: "100%" }}>
            <ProjectComment
              projectDetails={projectDetails}
              projectId={id}
              commentData={commentData}
            />
          </Box>
        </Grid>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const { projectDetails } = state.project;
  const { categories } = state.category;
  return { projectDetails, categories };
}
export default connect(mapStateToProps)(EditProject);
