/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from "@mui/material";
import $ from "jquery";
import { useEffect, useRef, useState } from "react";
import "../../lib/turn.js";
// import "../../lib/zoom.js";
import "./assets/magazine.css";

function Turn({
  children,
  options,
  flipbookDisplayDimension,
  currentPage,
  totalPages,
}) {
  const flipbookRef = useRef(null);

  // The default margin to center the flipbook doesn't work, so a custom margin is used
  const [marginLeft, setMarginLeft] = useState(0);
  // The width of the container of the first/last page isn't correct, so a custom width is used
  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    if (flipbookRef.current) {
      $(flipbookRef.current).turn(options);
    }
  }, []);

  useEffect(() => {
    if (flipbookRef.current) {
      $(flipbookRef.current).turn(
        "size",
        flipbookDisplayDimension.width,
        flipbookDisplayDimension.height
      );
    }
  }, [flipbookDisplayDimension]);

  useEffect(() => {
    if (flipbookRef.current && currentPage) {
      $(flipbookRef.current).turn("page", currentPage);
    }
  }, [currentPage]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 37) {
      $(flipbookRef.current).turn("previous");
    }
    if (event.keyCode === 39) {
      $(flipbookRef.current).turn("next");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      if (flipbookRef.current) {
        $(flipbookRef.current).turn("destroy").remove();
      }
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, []);

  useEffect(() => {
    if (flipbookRef.current && currentPage !== "") {
      if (currentPage === 1) {
        setMarginLeft(-flipbookDisplayDimension.width / 2);
      } else if (currentPage === totalPages) {
        setMarginLeft(flipbookDisplayDimension.width / 2);
      } else {
        setMarginLeft(0);
      }
    }
  }, [currentPage, flipbookDisplayDimension]);

  useEffect(() => {
    if (flipbookRef.current && currentPage !== "") {
      if (currentPage === 1 || currentPage === totalPages) {
        setPageWidth(flipbookDisplayDimension.width / 2);
      } else {
        setPageWidth(flipbookDisplayDimension.width);
      }
    }
  }, [currentPage, totalPages, flipbookDisplayDimension]);

  return (
    <Stack justifyContent="center" alignItems="center" width={pageWidth}>
      <Stack
        className="magazine-viewport animated"
        ref={flipbookRef}
        sx={{
          marginLeft: `${marginLeft}px !important`,
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}

export default Turn;
