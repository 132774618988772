import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux/es";
import { Link, Navigate } from "react-router-dom";

export default function Layout() {
  const theme = useTheme();
  const isTabletUp = useMediaQuery(theme.breakpoints.up("md"));
  const isDesktopUp = useMediaQuery(theme.breakpoints.up("lg"));

  const isLoggedIn = useSelector((state) => state.authentication.accessToken);
  if (isLoggedIn) {
    return <Navigate to="/my-projects" />;
  }

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        gap={2}
        bgcolor="#1976D2"
        p={1.75}
        boxShadow="0 2px 10px rgb(0 0 0 / 0.3)"
      >
        <Link
          to="/register"
          style={{
            textDecoration: "none",
          }}
        >
          <Typography color="white" variant="body2">
            Sign Up
          </Typography>
        </Link>
      </Stack>

      <Stack p={8} gap={8}>
        <Stack
          direction={isTabletUp ? "row" : "column"}
          alignItems="center"
          justifyContent="center"
          gap={8}
        >
          <Stack flex={1} gap={4} order={isTabletUp ? 1 : 2}>
            <Typography variant={isDesktopUp ? "h3" : "h4"} fontWeight="bold">
              Turn Your Images into Interactive Flippingbooks
            </Typography>
            <Typography variant={isDesktopUp ? "h7" : "body1"}>
              With our flippingbook web application, you can easily upload your
              images and transform them into captivating, page-flippable
              electronic books. It's the perfect way to showcase your photos or
              create engaging presentations.
            </Typography>
            <Stack
              direction={isTabletUp ? "row" : "column"}
              alignItems="flex-start"
              justifyContent="center"
              gap={2}
            >
              <Stack alignItems="flex-start" justifyContent="center" gap={2}>
                <Typography
                  variant={isDesktopUp ? "h6" : "body1"}
                  fontWeight="bold"
                >
                  Easy Setup
                </Typography>
                <Typography variant={isDesktopUp ? "h7" : "body1"}>
                  Upload your images and let our tool do the rest. No coding or
                  design skills required.
                </Typography>
              </Stack>
              <Stack alignItems="flex-start" justifyContent="center" gap={2}>
                <Typography
                  variant={isDesktopUp ? "h6" : "body1"}
                  fontWeight="bold"
                >
                  Interactive Experience
                </Typography>
                <Typography variant={isDesktopUp ? "h7" : "body1"}>
                  Engage your audience with the realistic page-flipping effect
                  and interactive features.
                </Typography>
              </Stack>
            </Stack>
            <Link
              style={{
                textDecoration: "none",
              }}
              to="/discover"
            >
              <Stack
                alignItems="center"
                justifyContent="flex-start"
                direction="row"
                gap={1}
              >
                Discover
                <ChevronRightIcon />
              </Stack>
            </Link>
          </Stack>
          <Stack width="100%" flex={1} order={isTabletUp ? 2 : 1}>
            <img width="100%" src="/assets/home-01.jpeg" alt="" />
          </Stack>
        </Stack>

        <Stack
          direction={isTabletUp ? "row" : "column"}
          alignItems="center"
          justifyContent="center"
          gap={8}
        >
          <Stack width="100%" flex={1} py={isDesktopUp ? 8 : 0}>
            <img width="100%" src="/assets/home-02.jpeg" alt="" />
          </Stack>
          <Stack flex={1} gap={4}>
            <Typography variant={isDesktopUp ? "h3" : "h4"} fontWeight="bold">
              Convert Images into Interactive Flippingbooks with Ease
            </Typography>
            <Typography variant={isDesktopUp ? "h7" : "body1"}>
              Our web application simplifies the process of converting images
              into interactive flippingbooks. With just a few clicks, you can
              transform your static images into dynamic, page-flippable
              electronic books.
            </Typography>
            <Stack
              direction={isTabletUp ? "row" : "column"}
              alignItems="flex-start"
              justifyContent="center"
              gap={2}
            >
              <Stack alignItems="flex-start" justifyContent="center" gap={2}>
                <Typography
                  variant={isDesktopUp ? "h6" : "body1"}
                  fontWeight="bold"
                >
                  Simplicity
                </Typography>
                <Typography variant={isDesktopUp ? "h7" : "body1"}>
                  Easily convert your images into stunning flippingbooks that
                  captivate your audience.
                </Typography>
              </Stack>
              <Stack alignItems="flex-start" justifyContent="center" gap={2}>
                <Typography
                  variant={isDesktopUp ? "h6" : "body1"}
                  fontWeight="bold"
                >
                  Ease
                </Typography>
                <Typography variant={isDesktopUp ? "h7" : "body1"}>
                  Our tool makes it effortless to create interactive
                  flippingbooks from your images.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        <Stack
          alignItems="center"
          justifyContent="center"
          gap={2}
          direction={isTabletUp ? "row" : "column"}
        >
          <Typography textAlign="center">
            Copyright© 2024, Ubabybaby Limited. All Right Reserved
          </Typography>
          <Link
            style={{
              color: "black",
            }}
            to="privacy"
            target="_blank"
          >
            Privacy Policy
          </Link>
          <Link
            style={{
              color: "black",
            }}
            to="terms"
            target="_blank"
          >
            Terms of Service
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
}
