import CheckIcon from "@mui/icons-material/Check";
import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import MaterialReactTable from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { userSearchOptions } from "../../common/constants";
import CustomLoader from "../../components/CustomLoader";
import MenuButton from "../../components/MenuButton";
import Navbar from "../../components/Navbar";
import UserSearchBar from "../../components/Users/UserSearchBar";
import {
  approveUserById,
  deleteUserById,
  getUsers,
  lockUserById,
  userPagination,
  userSearch,
  userSearchPagination,
} from "../../store/slices/admin/adminAction";

function UserScreen(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users } = props;
  const paginationData = props.pagination;

  const [openDrawer, setOpenDrawer] = useOutletContext();
  const [userData, setUserData] = useState(props?.users ?? []);
  const [typeValue, setTypeValue] = useState("");
  const [seachInputValue, setSeachInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isClearSearch, setIsClearSearch] = useState(false);
  const [statusObject, setStatusObject] = useState({});
  const [isSearchPagination, setisSearchPagination] = useState(false);
  const [searchPayload, setSearchPayload] = useState({});

  useEffect(() => {
    setLoading(true);
    dispatch(getUsers())
      .unwrap()
      .then((res) => {
        console.log("res", res);
        setLoading(false);
      })
      .catch((err) => {
        console.log("catchError:", err);
      });
    return () => setUserData;
  }, []);
  const columnsData = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "email",
        header: "Email Address",
        accessorFn: (project) => {
          return project.email.toLowerCase();
        },
      },
      {
        accessorKey: "role_name",
        header: "Role",
        maxSize: 10,
      },
      {
        field: "",
        header: "Admin Created",
        accessorFn: (user) => user.created_by,
        Cell: ({ renderedCellValue, row }) =>
          row.original.created_by ? (
            <CheckIcon sx={{ ml: 3, color: "green" }} />
          ) : (
            ""
          ),
        maxSize: 5,
      },
      {
        accessorKey: "channel_name",
        header: "Channel",
      },
      {
        accessorKey: "status_text",
        header: "Status",
      },
      {
        accessorKey: "updatedTime",
        header: "Latest Activity",
        accessorFn: (user) => user.updated_at,
        Cell: ({ renderedCellValue, row }) => {
          return row.original.updatedTime;
        },
      },
      {
        field: "",
        header: "Action",
        sortable: false,
        accessorFn: (project) => {
          const navigateToEditScreen = () => {
            navigate(`/edit-user/${project?.id}`);
          };

          const onPressLock = () => {
            dispatch(lockUserById(project?.id));
          };

          const onPressUnLock = () => {
            dispatch(lockUserById(project?.id));
          };

          const onPressDelete = () => {
            dispatch(deleteUserById(project?.id)).then(() => {
              getUsersData();
            });
          };

          const onPressApprove = () => {
            dispatch(approveUserById(project?.id));
          };

          return (
            <MenuButton
              notRequiredPremission={false}
              onPressEdit={navigateToEditScreen}
              onPressLock={onPressLock}
              onPressUnLock={onPressUnLock}
              onPressDelete={onPressDelete}
              onPressApprove={onPressApprove}
              usertItem={project}
            />
          );
        },
      },
    ],
    [navigate, dispatch]
  );

  useEffect(() => {
    setUserData(users);
  }, [users]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const payload = {
        type: typeValue === "" ? "name" : typeValue?.toLowerCase(),
        value: seachInputValue?.toLowerCase(),
        size: pagination.pageSize,
      };
      callSearchApi(payload);
    }
  };

  const onStatusChange = (value) => {
    const statusPayload = {
      type: "status",
      value,
      size: pagination.pageSize,
    };
    setStatusObject(statusPayload);
  };

  const callSearchApi = (payload) => {
    setSearchPayload(payload);
    // Make api call only if, type & payload have data.
    if (payload.type && payload.value) {
      setLoading(true);
      setisSearchPagination(true);
      dispatch(userSearch(payload))
        .unwrap()
        .then((res) => {
          console.log("res", res);
          setLoading(false);
        });
    }
  };

  const onPressSearch = () => {
    // if serach type value is empty then call search api with default type as name.
    if (typeValue === "") {
      const payload = {
        type: "name",
        value: seachInputValue?.toLowerCase(),
        size: pagination.pageSize,
      };
      callSearchApi(payload);
    }
    // if type value is email
    else if (typeValue === "email") {
      const payload = {
        type: typeValue?.toLowerCase(),
        value: seachInputValue?.toLowerCase(),
        size: pagination.pageSize,
      };
      callSearchApi(payload);
    }
    // if type is status
    else if (typeValue === "status") {
      callSearchApi(statusObject);
    }
    // if type is name
    else {
      const payload = {
        type: typeValue?.toLowerCase(),
        value: seachInputValue?.toLowerCase(),
        size: pagination.pageSize,
      };
      callSearchApi(payload);
    }
  };

  const onPressReset = () => {
    setIsClearSearch(!isClearSearch);
    setSeachInputValue("");
    setisSearchPagination(false);
    setLoading(true);
    dispatch(getUsers())
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log("catchError:", err);
      });
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    // Get new pagination on pagination state change.
    if (isSearchPagination) {
      getSearchPagitionData();
    } else {
      getPaginatedData();
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const getPaginatedData = () => {
    setLoading(true);
    dispatch(
      userPagination({
        page: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      })
    )
      .unwrap()
      .then(() => {
        setLoading(false);
      });
  };

  const getSearchPagitionData = () => {
    searchPayload.size = pagination.pageSize;
    const payload = {
      id: pagination.pageIndex + 1,
      data: searchPayload,
      pageSize: pagination.pageSize,
    };
    setLoading(true);

    dispatch(userSearchPagination(payload))
      .unwrap()
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Navbar
        title="Users"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <Grid p={2} container item xs={12}>
        <CustomLoader isOpen={loading} />
        <>
          <Box
            sx={{
              width: "100%",
              mb: 3,
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Box sx={{ mr: 1, width: "65%" }}>
                <UserSearchBar
                  onChangeSelect={(value) => setTypeValue(value)}
                  onInputChange={(value) => setSeachInputValue(value)}
                  handleKeyDown={(e) => handleKeyDown(e)}
                  onStatusChange={(value) => onStatusChange(value)}
                  options={userSearchOptions}
                  isClearSearch={isClearSearch}
                />
              </Box>
              <Button
                variant="contained"
                onClick={() => onPressSearch()}
                sx={{ mr: 1 }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  onPressReset();
                }}
                sx={{ background: "#767676" }}
              >
                Reset
              </Button>
            </Box>
            <Button
              variant="contained"
              onClick={() => navigate("/create-user")}
            >
              Create User
            </Button>
          </Box>
          <div style={{ height: 560, width: "100%" }}>
            <MaterialReactTable
              columns={columnsData}
              data={userData}
              enableColumnActions={false}
              enableTopToolbar={false}
              manualPagination
              rowCount={paginationData?.total}
              onPaginationChange={setPagination}
              state={{ pagination }}
              columnOrder={[
                "name",
                "email",
                "role_name",
                "created_by",
                "channel_name",
                "status_text",
                "updatedTime",
              ]}
            />
          </div>
        </>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { users, pagination } = state.admin;
  return { users, pagination };
}
export default connect(mapStateToProps)(UserScreen);
