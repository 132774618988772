import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { Box } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { authenticationActions } from "../../store/slices/authenticationSlice";

import {
  dislikeProject,
  likeProject,
} from "../../store/slices/project/projectAction";
import styles from "../../styles/Components/discover/discover.module.scss";
import LightTooltip from "../Project/MyProject.js/LightTooltip";

function DiscoverItem({
  filterType,
  title,
  publishedTime,
  channel,
  id,
  croppedfiles,
  ...props
}) {
  const first_image =
    croppedfiles[1]?.thumbnail ?? "./assets/discoverPlaceholder.png";
  const second_image =
    croppedfiles[2]?.thumbnail ?? "./assets/discoverPlaceholder.png";
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState("hidden");
  const showImage = () => {
    setVisibility("visible");
  };
  const currentUser = useSelector((state) => state.authentication.currentUser);
  const likeProjectHandler = () => {
    if (!currentUser) {
      dispatch(authenticationActions.remeberPathForGuest(location.pathname));
      navigate("/login");
    } else {
      dispatch(likeProject(id));
    }
  };
  const dislikeProjectHandler = () => {
    dispatch(dislikeProject(id));
  };
  const toShow = `${title?.substring(0, 26)}...`;
  const showEcllipsText = title?.length > 26;
  const showTitle = showEcllipsText ? toShow : title;
  return (
    <Box className={styles.discoverContainer}>
      <span onClick={() => (window.location.href = `/discover-details/${id}`)}>
        <div className={styles.discoverImages}>
          <div className={`${styles.gradientOverlay} ${styles.gradientLeft}`} />
          <div
            className={`${styles.gradientOverlay} ${styles.gradientRight}`}
          />

          <div className={styles.imageContainer}>
            <img
              onLoad={showImage}
              className={styles.image}
              src={first_image}
              alt=""
              style={{ visibility }}
            />
          </div>
          <div className={styles.imageContainer}>
            <img
              onLoad={showImage}
              className={styles.image}
              src={second_image}
              alt=""
              style={{ visibility }}
            />
          </div>
        </div>
        <h4 style={{ textTransform: "capitalize" }}>
          {showEcllipsText ? (
            <LightTooltip title={title}>
              <p>{showTitle}</p>
            </LightTooltip>
          ) : (
            <p>{showTitle}</p>
          )}
        </h4>
      </span>
      <Box className={styles.subContainer}>
        <p>{publishedTime}</p>
        <Box sx={{ mr: 2, display: "flex", alignItems: "center" }}>
          <RemoveRedEyeOutlinedIcon sx={{ mr: 1, fontSize: 14 }} />
          <span> {props?.views ?? 0}</span>
        </Box>
        <Box sx={{ mr: 2, display: "flex", alignItems: "center" }}>
          {props?.is_liked_by_current_user === 1 ? (
            <ThumbUpIcon
              sx={{ mr: 1, fontSize: 14 }}
              onClick={dislikeProjectHandler}
            />
          ) : (
            <ThumbUpOutlinedIcon
              sx={{ mr: 1, fontSize: 14 }}
              onClick={likeProjectHandler}
            />
          )}
          <span> {props?.likes}</span>
        </Box>
      </Box>
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={`/channel/${channel?.id}`}
      >
        <p className={styles.channel}>{channel && channel?.name}</p>
      </Link>
    </Box>
  );
}

export default DiscoverItem;
