/* eslint-disable react-hooks/exhaustive-deps */
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CropFreeIcon from "@mui/icons-material/CropFree";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { IconButton, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useControls } from "react-zoom-pan-pinch";
import useSystemStore from "../../../store/system";

function FlipBottomSection({
  totalPages,
  currentPage,
  setCurrentPage,
  scale,
  setScale,
  onPressFullScreen,
  onPressExitFullScreen,
}) {
  const { isFullScreen } = useSystemStore();
  const { zoomIn, zoomOut } = useControls();
  const [previousPage, setPreviousPage] = useState(1);

  const onPressPreviousHandler = () => {
    if (currentPage === 1) return;

    const isEven = currentPage % 2 === 0;
    setCurrentPage(isEven ? currentPage - 1 : currentPage - 2);
  };

  const onPressNextHandler = () => {
    if (currentPage === totalPages) return;

    const isEven = currentPage % 2 === 0;
    setCurrentPage(isEven ? currentPage + 2 : currentPage + 1);
  };

  return (
    <Stack
      direction="row"
      gap={0.5}
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      sx={{
        backgroundColor: "white",
      }}
      p={0.2}
    >
      <Stack
        direction="row"
        gap={0.5}
        justifyContent="center"
        alignItems="center"
      >
        <Typography>pages: </Typography>
        <TextField
          sx={{
            width: "60px",
            mt: "0px !important",
            "& .MuiInputBase-input": {
              height: "1em",
            },
          }}
          size="small"
          value={currentPage}
          onFocus={() => {
            setPreviousPage(currentPage);
            setCurrentPage("");
          }}
          onBlur={() => {
            if (currentPage === "") {
              setCurrentPage(previousPage);
            }
          }}
          onChange={(e) => {
            const { value } = e.target;

            const num = parseInt(value, 10);
            if (Number.isNaN(num) || num < 1 || num > totalPages) {
              setPreviousPage(currentPage);
              setCurrentPage("");
              return;
            }

            setCurrentPage(num);
          }}
        />
        <Typography px={1}>/ {totalPages} </Typography>
      </Stack>

      <Stack
        direction="row"
        gap={0.5}
        justifyContent="center"
        alignItems="center"
      >
        <IconButton
          onClick={onPressPreviousHandler}
          disabled={currentPage === 1}
        >
          <ArrowBackIosIcon
            sx={{
              fontSize: 18,
            }}
          />
        </IconButton>
        <IconButton
          onClick={onPressNextHandler}
          disabled={currentPage === totalPages}
        >
          <ArrowForwardIosIcon
            sx={{
              fontSize: 18,
            }}
          />
        </IconButton>
      </Stack>

      {!isMobile && (
        <Stack justifyContent="center" alignItems="center">
          {scale === 1 ? (
            <IconButton
              onClick={() => {
                zoomIn();
                setScale(2);
              }}
            >
              <ZoomInIcon
                sx={{
                  fontSize: "1.2em",
                }}
              />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                zoomOut();
                setScale(1);
              }}
            >
              <ZoomOutIcon
                sx={{
                  fontSize: "1.2em",
                }}
              />
            </IconButton>
          )}
        </Stack>
      )}

      <Stack justifyContent="center" alignItems="center">
        {isFullScreen ? (
          <IconButton onClick={() => onPressExitFullScreen()}>
            <FullscreenExitIcon
              sx={{
                fontSize: "1.2em",
              }}
            />
          </IconButton>
        ) : (
          <IconButton onClick={() => onPressFullScreen()}>
            <CropFreeIcon sx={{ fontSize: "1.2em" }} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}

export default FlipBottomSection;
