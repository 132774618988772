import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import * as React from "react";
import styles from "../../../styles/Components/SelectInput.module.scss";

export default function MultipleSelectChip({
  selectedVisibility,
  onRemove,
  selectedCatgories,
  options,
}) {
  const [selectedCheckBox, setSelectedCheckBox] = React.useState([]);
  const [selectedCheckBoxVisibility, setSelectedCheckBoxVisibility] =
    React.useState([]);
  const [selectedCheckBoxCategory, setSelectedCheckBoxCategory] =
    React.useState([]);
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    setSelectedCheckBoxVisibility(selectedVisibility);
  }, [selectedVisibility]);

  React.useEffect(() => {
    setSelectedCheckBoxCategory(selectedCatgories);
  }, [selectedCatgories]);

  React.useEffect(() => {
    setSelectedCheckBox(options);
  }, [options]);

  const onPressRemove = (index, value) => {
    if (index !== -1) {
      selectedCheckBox.splice(index, 1);
    }
    setSelectedCheckBox(selectedCheckBox);
    setCounter(counter + 1);
  };

  const onPressRemoveVisibility = (index, value) => {
    if (index !== -1) {
      selectedCheckBoxVisibility.splice(index, 1);
    }
    setSelectedCheckBoxVisibility(selectedCheckBoxVisibility);
    setCounter(counter + 1);
  };

  const onPressRemoveCategory = (index, value) => {
    if (index !== -1) {
      selectedCheckBoxCategory.splice(index, 1);
    }
    setSelectedCheckBoxCategory(selectedCheckBoxCategory);
    setCounter(counter + 1);
  };

  return (
    <div>
      <FormControl
        sx={{
          m: 1,
          margin: 0,
          maxWidth: window.innerWidth > 768 ? ((window.innerWidth - 500) > 350 ? 350 : window.innerWidth - 500) : window.innerWidth - 250,
          overflow: "auto",
          maxHeight: 35,
          minWidth: window.innerWidth > 768 ? 210 : window.innerWidth - 250,
        }}
        className={styles.categoryInput}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {selectedCheckBox &&
            selectedCheckBox.map((value, i) => (
              <Box key={`${value}_${i}`}>
                <Chip
                  label={value}
                  onDelete={() => {
                    onPressRemove(i, value);
                    onRemove(i, value);
                  }}
                />
              </Box>
            ))}
        </Box>
        {selectedCheckBoxVisibility?.length > 0 && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selectedCheckBoxVisibility &&
              selectedCheckBoxVisibility.map((value, i) => (
                <Box key={`${value}_${i}`}>
                  <Chip
                    label={value}
                    onDelete={() => {
                      onPressRemoveVisibility(i, value);
                      onRemove(i, value);
                    }}
                  />
                </Box>
              ))}
          </Box>
        )}
        {selectedCheckBoxCategory?.length > 0 && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selectedCheckBoxCategory &&
              selectedCheckBoxCategory.map((value, i) => (
                <Box key={`${value}_${i}`}>
                  <Chip
                    label={value}
                    onDelete={() => {
                      onPressRemoveCategory(i, value);
                      onRemove(i, value);
                    }}
                  />
                </Box>
              ))}
          </Box>
        )}
      </FormControl>
    </div>
  );
}
