import AutorenewIcon from "@mui/icons-material/Autorenew";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  LinearProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

const uploadImgThumbnail = "/assets/img-upload-thumbnail.png";
// const loaderImg = "/assets/Spin.gif";
function UploadedImg(props) {
  const theme = createTheme({
    palette: {
      danger: {
        main: "#FF0000",
      },
    },
  });

  const [completed, setCompleted] = useState(100);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState();

  const errors = useMemo(
    () =>
      props.status === false && props?.errors
        ? Object.values(props?.errors)
        : [],
    [props.errors, props.status]
  );

  useEffect(() => {
    setStatus(props.status);
    errors && errors.map((item) => setMessage(item));
  }, [errors, props.status]);

  const refreshFile = () => {
    setCompleted(0);
    setStatus(null);
    setMessage("");
    props.onPressFileUpload(props.file, props.index, props.item);
  };

  const deleteFile = () => {
    props.onDeleteFile(props.item, props.index);
  };

  const filePath = props?.image_url ? props?.image_url : uploadImgThumbnail;

  return (
    <Box
      sx={{ mr: 2.5, mb: 2.2 }}
      style={{
        backgroundColor: "#f1f1f1",
        textAlign: "center",
        width: "160px",
        height: "110px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 22,
          marginLeft: "auto",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {errors && !status && (
          <IconButton
            size="small"
            sx={{ ml: "auto", marginBottom: "-2.5em" }}
            onClick={refreshFile}
          >
            <AutorenewIcon color="primary" sx={{ fontSize: "0.8em" }} />
          </IconButton>
        )}
        {errors && (
          <IconButton
            size="small"
            sx={{ ml: "auto", marginBottom: "-2.5em" }}
            onClick={deleteFile}
          >
            <CloseIcon color="primary" sx={{ fontSize: "0.8em" }} />
          </IconButton>
        )}
      </Box>
      <img
        src={filePath}
        alt={props?.id}
        height="111px"
        width="100%"
        style={{ objectFit: "contain" }}
      />
      <Box sx={{ width: "160px" }}>
        <ThemeProvider theme={theme}>
          <LinearProgress
            sx={{ height: 3 }}
            variant="determinate"
            value={completed}
            color={status === null || status === true ? "primary" : "danger"}
          />
        </ThemeProvider>
        {message && (
          <span style={{ color: "red", fontSize: 11, fontWeight: "400" }}>
            {message}
            <br />
          </span>
        )}
      </Box>
    </Box>
  );
}

export default UploadedImg;
