import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Avatar, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "../../../styles/Components/discover/Flipbook.module.scss";
import CustomInput from "../../CustomInput";

function CommentInput({
  placeholder,
  onAddComment,
  onCancel,
  onChange,
  commentValue,
  totalRows,
  userIconSize,
  fullWidth = false,
}) {
  const currentUser = useSelector((state) => state.authentication.currentUser);
  return (
    <Box sx={{ width: "100%" }}> 
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        className={fullWidth ? styles.discoveryCommentReply : styles.discoveryComment}
      >
        {currentUser.image_url ? (
          <Avatar
            sx={{ width: 50, height: 50, mr: 2, mt: 1, color: "lightgray" }}
            alt="Remy Sharp"
            src={currentUser.image_url}
          />
        ) : (
          <AccountCircleIcon
            sx={{
              width: userIconSize || 50,
              height: userIconSize || 50,
              mr: 2,
              color: "lightgray",
            }}
          />
        )}
        <CustomInput
          value={commentValue}
          isMultiline
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          totalRows={totalRows}
        />
      </Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
        className={fullWidth ? styles.discoveryCommentReply : styles.discoveryComment}
      >
        <Button sx={{ mr: 2 }} onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onAddComment}>
          Comment
        </Button>
      </Box>
    </Box>
  );
}

export default CommentInput;
