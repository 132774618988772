import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import CustomLoader from "../../components/CustomLoader";
import Navbar from "../../components/Navbar";
import { getCategories } from "../../store/slices/CategorySlice";
import {
  createProject,
  updateProject,
} from "../../store/slices/project/projectAction";
import { projectActions } from "../../store/slices/projectSlice";

function CreateProject(props) {
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categories } = props;

  const [projectTitle, setProjectTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [visibility, setVisibility] = useState("");
  const [projectId, setProjectId] = useState(null);

  const [categoryData, setCategoryData] = useState(props?.categories ?? []);

  const [isBtnDisable, setIsBtnDisable] = useState(true);
  const [loading, setLoading] = useState(false);

  const visibilityData = [
    { name: "public", id: 1 },
    { name: "private", id: 2 },
    { name: "unlisted", id: 3 },
  ];
  const location = useLocation();
  useEffect(() => {
    dispatch(projectActions.setNull());
  }, [dispatch]);
  useEffect(() => {
    if (location.state) {
      setProjectTitle(location.state.projectTitle);
      setDescription(location.state.description);
      setCategory(location.state.categoryId);
      setVisibility(location.state.visibility);
      setProjectId(location.state.projectId);
    }
  }, [location]);

  // Read values passed on state
  useEffect(() => {
    dispatch(
      projectActions.setFilesToRetrieveOnBack({
        filesPresent: [],
        designType: "self-design",
        albumRatio: "",
        layoutType: "",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCategories())
      .unwrap()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("catchError:", err);
      });
  }, [dispatch]);

  useEffect(() => {
    setCategoryData(categories);
  }, [categories]);

  useEffect(() => {
    // All input field has value then btn should enable else disabled
    if (projectTitle && description && category && visibility) {
      setIsBtnDisable(false);
    } else {
      setIsBtnDisable(true);
    }
  }, [projectTitle, description, category, visibility]);

  const onPressSave = () => {
    const payload = {
      title: projectTitle,
      category_id: category,
      visibility,
      description,
    };
    if (!isBtnDisable) {
      setLoading(true);
      if (projectId) {
        const payload = {
          data: {
            title: projectTitle,
            category_id: category,
            visibility,
            description,
          },
          id: projectId,
        };
        dispatch(updateProject(payload))
          .unwrap()
          .then((res) => {
            setLoading(false);
            const projectId = res?.data?.data?.id;
            navigate("/my-projects");
          })
          .catch((err) => {
            setLoading(false);
            console.log("catchError:", err);
          });
      } else {
        dispatch(createProject(payload))
          .unwrap()
          .then((res) => {
            setLoading(false);
            navigate("/my-projects");
          })
          .catch((err) => {
            setLoading(false);
            console.log("catchError:", err);
          });
      }
    }
  };

  const OnPressSaveAndUpload = () => {
    const payload = {
      title: projectTitle,
      category_id: category,
      visibility,
      description,
    };
    if (!isBtnDisable) {
      setLoading(true);
      if (projectId) {
        const payload = {
          data: {
            title: projectTitle,
            category_id: category,
            visibility,
            description,
          },
          id: projectId,
        };
        dispatch(updateProject(payload))
          .unwrap()
          .then((res) => {
            setLoading(false);
            const projectId = res?.data?.data?.id;
            navigate(`/project/${projectId}/upload`, {
              state: {
                categoryId: category,
                projectTitle,
                visibility,
                projectId,
                description,
              },
            });
          })
          .catch((err) => {
            setLoading(false);
            console.log("catchError:", err);
          });
      } else {
        dispatch(createProject(payload))
          .unwrap()
          .then((res) => {
            setLoading(false);
            const projectId = res?.data?.data?.id;
            navigate(`/project/${projectId}/upload`, {
              state: {
                categoryId: category,
                projectTitle,
                visibility,
                projectId,
                description,
              },
            });
          })
          .catch((err) => {
            setLoading(false);
            console.log("catchError:", err);
          });
      }
    }
  };

  return (
    <>
      <Navbar
        title="Create Project"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <CustomLoader isOpen={loading} />
      <Grid p={2} container item xs={12}>
        <Box
          sx={{ width: "100%", mb: 1 }}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            sx={{
              mr: 2,
            }}
            variant="contained"
            onClick={() => onPressSave()}
            disabled={isBtnDisable}
          >
            Save
          </Button>
          <Button
            variant="contained"
            onClick={() => OnPressSaveAndUpload()}
            disabled={isBtnDisable}
          >
            Save & Upload
          </Button>
        </Box>
        <FormControl sx={{ 
          mb: 3, 
          mt: 2, 
          width: "60%",  
          '@media only screen and (max-width: 768px)': {
            width: '100%',
          },  
          }}>
          <CustomInput
            value={projectTitle}
            onChange={(e) => {
              setProjectTitle(e.target.value);
            }}
            placeholder="Please give your project a title"
            label="Project Title"
          />
        </FormControl>

        <Box sx={{ 
          width: "60%",  
          '@media only screen and (max-width: 768px)': {
            width: '100%',
          },  
          }} display="flex" justifyContent="space-between">
          <FormControl sx={{ mb: 3, width: "45%" }}>
            <label id="demo-simple-select-label">Category</label>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category}
              label="Category"
              variant="standard"
              disableUnderline
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              {categoryData &&
                categoryData.map((item, i) => (
                  <MenuItem key={item + i} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl sx={{ mb: 3, width: "45%" }}>
            <label id="demo-simple-select-label2">Visibility</label>
            <Select
              labelId="demo-simple-select-label2"
              value={visibility}
              label="Visibility"
              variant="standard"
              disableUnderline
              onChange={(e) => {
                setVisibility(e.target.value);
                console.log("visibility", visibility);
              }}
            >
              {visibilityData &&
                visibilityData.map((item, i) => (
                  <MenuItem key={item + i} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <FormControl sx={{ 
          mb: 3, 
          width: "60%",
          '@media only screen and (max-width: 768px)': {
            width: '100%',
          }
           }}>
          <CustomInput
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            placeholder="Tell viewser about your flipBook"
            label="Description"
            isMultiline
          />
        </FormControl>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { categories } = state.category;
  const { status } = state.project;
  return { categories, status };
}
export default connect(mapStateToProps)(CreateProject);
