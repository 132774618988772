import Axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const token = sessionStorage.getItem("accessToken");

const headers = {
  headers: {
    Accept: "application/json",
    Authorization: token && `Bearer ${token}`,
  },
};

export const getProjectById = async (id) =>
  (await Axios.get(`${apiUrl}project/${id}`, headers)).data.data;
