import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Box, Fade, IconButton, Menu, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import styles from "../../styles/Components/SelectInput.module.scss";

import MultipleSelectChip from "../Project/Search/MultipleSelectChip";
import ProjectCategory from "../Project/Search/ProjectCategory";

export default function DiscoverSearchbar({
  onChangeSelect,
  onInputChange,
  handleKeyDown,
  options,
  categoryData,
  onChangeSelectedCategory,
  isClearSearch,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isInputOpen, setIsInputOpen] = useState(true);

  const [categoryArr, setCategoryArr] = useState([]);
  const [value, setValue] = useState("Title");
  const [inputValue, setInputValue] = useState("");
  const [catArr, setCatArr] = useState([]);

  const onCloseStatus = () => {
    setAnchorEl(null);
    setIsCategoryDropdownOpen(false);
  };

  useEffect(() => {
    setCatArr([]);
    setCategoryArr([]);
    setInputValue("");
    setValue("Title");
    setIsInputOpen(true);
  }, [isClearSearch]);

  const onPressMenu = (type) => {
    if (type === "category") {
      setValue(type);
      onChangeSelect(type);
      setIsCategoryDropdownOpen(true);
      setIsInputOpen(false);
      setInputValue("");
      if (categoryArr.length === 0) {
        setCatArr([]);
      }
    } else {
      setIsCategoryDropdownOpen(false);
      setValue(type);
      onChangeSelect(type);
      handleClose();
      setIsInputOpen(true);
      setCategoryArr([]);
      setInputValue("");
      setCatArr([]);
    }
  };

  // Categories
  const selectedCategories = [];
  const selectedCategoryNames = [];
  const onSelectCategoryOption = (id, isChecked, name) => {
    if (isChecked) {
      const idx = selectedCategories.findIndex((ele) => ele === id);
      if (idx === -1) {
        const newArr = [...catArr, id];
        setCatArr(newArr);
        selectedCategories.push(id);
        selectedCategoryNames.push(name);
      }
    } else {
      const idx = catArr.findIndex((ele) => ele === id);
      if (idx !== -1) {
        const oldArr = [...catArr];
        oldArr.splice(idx, 1);
        setCatArr(oldArr);
        selectedCategories.splice(idx, 1);
        selectedCategoryNames.splice(idx, 1);
      }
    }

    const arr1 = [...categoryArr, selectedCategoryNames[0]];
    const uniqueArray = arr1.filter(
      (item, pos) => arr1.indexOf(item) === pos && item !== undefined
    );
    if (!isChecked) {
      const idx = uniqueArray.findIndex((ele) => ele === name);
      if (idx !== -1) {
        uniqueArray.splice(idx, 1);
      }
    }
    setCategoryArr(uniqueArray);
  };

  useEffect(() => {
    onChangeSelectedCategory(catArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catArr]);

  const onRemove = (index) => {
    const arr = [];
    if (value === "category") {
      setValue("category");
      const oldArr = [...catArr];
      oldArr.splice(index, 1);
      setCatArr(oldArr);
      onChangeSelectedCategory(arr);
    }
  };

  return (
    <div className={`${styles.container} ${isInputOpen ? '' : styles.containerMedia}`}>
        <Box
          sx={{
            border: "1px solid #EEEEEE",
            borderRadius: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",            
            ...(!isInputOpen && {
              "@media only screen and (max-width: 768px)": {
                overflowX: "auto",
                overflowY: "hidden",
              },
            }),
          }}
        >
          {open ? (
            <IconButton onClick={handleClick}>
              <KeyboardDoubleArrowUpIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleClick}>
              <KeyboardDoubleArrowDownIcon />
            </IconButton>
          )}
          {isInputOpen ? (
            <Box sx={{ mt: -1, width: "100%" }}>
              <TextField
                 sx={{ width: "100%", border: 'none',  minWidth: window.innerWidth > 768 ? 0 : (window.innerWidth - 250) }}
                value={inputValue}
                onChange={(e) => {
                  onInputChange(e.target.value);
                  setInputValue(e.target.value);
                }}
                onKeyDown={(e) => handleKeyDown(e)}
                placeholder={`Search ${value}`}
                size="small"
                InputProps={
                  {
                    // classes: { notchedOutline: noBorder },
                  }
                }
              />
            </Box>
          ) : (
            <Box sx={{display: 'block'}}>
            <MultipleSelectChip              
              onRemove={(index, value) => onRemove(index, value)}
              selectedCatgories={categoryArr}
            />
            </Box>
          )}
        </Box>

        {anchorEl && (
          <Menu
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open
            onClose={handleClose}
            TransitionComponent={Fade}
            PaperProps={{ sx: { width: "250px" } }}
          >
            {options &&
              options.map((item, i) => (
                <MenuItem
                  key={`${item.name}_${i}`}
                  onClick={() => onPressMenu(item.name.toLowerCase())}
                >
                  {item.name}
                </MenuItem>
              ))}
          </Menu>
        )}

        <ProjectCategory
          categoryData={categoryData}
          onSelectCategoryOption={onSelectCategoryOption}
          onCloseStatus={onCloseStatus}
          isOpen={isCategoryDropdownOpen}
          dropdownAnchor={anchorEl}
          isDropdownOpen={open}
          selectedData={categoryArr}
        />
    </div>
  );
}
