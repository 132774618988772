import { Backdrop, CircularProgress } from "@mui/material";

function CustomLoader({ isOpen, handleClose }) {
  return (
    <Backdrop
      sx={{ color: "#bfbfbf", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default CustomLoader;
