/* eslint-disable jsx-a11y/anchor-is-valid */
import { Alert, Box, Button, FormControl, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  signInWithToken,
  updateProfileFromProfile,
} from "../../store/slices/authenticationSlice";
import CustomInput from "../CustomInput";
import CustomLoader from "../CustomLoader";
import ImgUploadPlaceholder from "../MyAccount/ImgUploadPlaceholder";

const profilePlaceholder = "/assets/Avatar.png";

function Contact({ currentUser, goToEmailTab, setChannel }) {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(currentUser?.first_name ?? "");
  const [lastName, setLastName] = useState(currentUser?.last_name ?? "");

  const [loading, setLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState(
    []
  );

  const [file, setFile] = useState(currentUser?.profilePic ?? null);
  const [imgPath, setImgPath] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  useEffect(() => {
    if (firstName && lastName) {
      setIsBtnDisabled(false);
      setServerSideValidationErrors();
      setSuccessMessage();
    } else {
      setIsBtnDisabled(true);
    }
    setImgPath(currentUser.image_url);
  }, [firstName, lastName, currentUser.image_url]);

  const updateProfile = () => {
    setLoading(true);

    const formdata = new FormData();
    formdata.append("first_name", firstName);
    formdata.append("last_name", lastName);
    formdata.append("file", file);

    dispatch(updateProfileFromProfile(formdata))
      .unwrap()
      .then((res) => {
        setLoading(false);
        dispatch(signInWithToken());
        if (res?.data?.errors) {
          setServerSideValidationErrors(Object.values(res?.data?.errors));
        } else {
          setChannel(false);
          setToastMessage(res?.data?.message);
          setAlertSeverity("success");
          setIsToastOpen(true);
          setImgPath(res?.data?.data?.image_url);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("catchError:", err);
      });
  };

  const onChangeFile = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setImgPath(URL.createObjectURL(e.target.files[0]));
      setChannel(true);
    }
  };

  return (
    <>
      <CustomLoader isOpen={loading} />
      <Box
        sx={{
          width: "100%",
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "center",
            width: "60%",
            mb: 2,
            '@media only screen and (max-width: 768px)': {
              width: '100%',
            },
          }}
        >
          <Box sx={{ mr: 2 }}>
            {imgPath ? (
              <ImgUploadPlaceholder
                onChangeFile={onChangeFile}
                imgPath={imgPath}
              />
            ) : (
              <ImgUploadPlaceholder
                onChangeFile={onChangeFile}
                imgPath={profilePlaceholder}
              />
            )}
          </Box>
          <Box sx={{
            width: "100%",
            display: "flex",
            '@media only screen and (max-width: 768px)': {
             display: "block"
            },
          }}>
            <p style={{ marginRight: "1.5em" }}>{currentUser?.email}</p>
            {!currentUser.isUserLoginViaSocial && (
             <p> <a href="#" className="sidebarLink" onClick={goToEmailTab}>
                Change Email
              </a></p>
                )}
          </Box>
        
        </Box>
        <Box sx={{ 
          width: "60%",
          '@media only screen and (max-width: 768px)': {
            width: '100%',
          },
          }}>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <CustomInput
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                setChannel(true);
              }}
              placeholder="Adam"
              label="First Name "
              // validationMessage={"not valid"}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <CustomInput
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setChannel(true);
              }}
              placeholder="Chan"
              label="Last Name"
              // validationMessage={"not valid"}
            />
          </FormControl>
        </Box>

        {serverSideValidationErrors &&
          serverSideValidationErrors.map((item, i) => (
            <Alert key={`${item}_${i}`} sx={{ mt: 2.5 }} severity="error">
              {item}
            </Alert>
          ))}
        {successMessage && !serverSideValidationErrors && (
          <Alert severity="success">{successMessage}</Alert>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            mt: 1,
          }}
        >
          <Button
            disabled={isBtnDisabled}
            variant="contained"
            onClick={updateProfile}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={6000}
        onClose={() => setIsToastOpen(false)}
      >
        <Alert
          onClose={() => setIsToastOpen(false)}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

function mapStateToProps(state) {
  const currentUser = state.authentication?.currentUser;
  return {
    currentUser,
  };
}
export default connect(mapStateToProps)(Contact);
