function ErrorComponent({ error }) {
  return (
    <div
      style={{
        color: "#BC3816",
        fontSize: "13px",
        paddingTop: "0.5em",
        paddingLeft: "1em",
      }}
    >
      {error}
    </div>
  );
}

export default ErrorComponent;
