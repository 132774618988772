import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { Avatar, Box, Button, IconButton } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authenticationActions } from "../../../store/slices/authenticationSlice";
import {
  addReply,
  disLikeComment,
  likeComment,
} from "../../../store/slices/project/projectAction";
import styles from "../../../styles/Components/discover/Flipbook.module.scss";
import CustomLoader from "../../CustomLoader";
import CommentInput from "./CommentInput";

function Comment({ body, user, id, project_id, ...props }) {
  const userName = `${user?.first_name} ${user?.last_name}`;
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.authentication.currentUser);
  const navigate = useNavigate();
  const location = useLocation();
  const [disable, setDisable] = useState(false);
  const likeCommentHandler = () => {
    if (!currentUser) {
      dispatch(authenticationActions.remeberPathForGuest(location.pathname));
      navigate("/login");
    } else if (props.likes === 0) {
      setDisable(true);
      dispatch(likeComment(id)).then(() => {
        setDisable(false);
      });
    }
  };
  const disLikeCommentHandler = () => {
    if (!currentUser) {
      dispatch(authenticationActions.remeberPathForGuest(location.pathname));
      navigate("/login");
    } else if (props.likes === 1) {
      setDisable(true);
      dispatch(disLikeComment(id)).then(() => {
        setDisable(false);
      });
    }
  };

  const [reply, setReply] = useState("");
  const [isReplyVisible, setIsReplyVisible] = useState(false);
  const { replies } = props;
  const [loading, setLoading] = useState(false);

  const [isReplyOpen, setIsReplyOpen] = useState(false);

  const onAddReply = () => {
    const payload = {
      body: reply,
      project_id,
      parent_id: id.toString(),
    };
    if (reply) {
      setLoading(true);
      dispatch(addReply(payload))
        .unwrap()
        .then((res) => {
          setLoading(false);
          console.log("res", res);
          setReply("");
        })
        .catch((err) => {
          console.log("catchError:", err);
        });
    }
  };
  const visibleCommentHandler = () => {
    if (!currentUser) {
      dispatch(authenticationActions.remeberPathForGuest(location.pathname));
      navigate("/login");
    } else {
      setIsReplyVisible(!isReplyVisible);
    }
  };

  return (
    <>
      <CustomLoader isOpen={loading} />
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          mb: 2,
        }}
        className={styles.commentBox}
      >
        {user.image_url ? (
          <Avatar
            sx={{ width: 50, height: 50, mr: 2, mt: 1, color: "lightgray" }}
            alt={user.first_name}
            src={user.image_url}
          />
        ) : (
          <AccountCircleIcon
            sx={{ width: 50, height: 50, mr: 2, mt: 1, color: "lightgray" }}
          />
        )}
        <Box sx={{ width: "100%" }}>
          <p
            style={{
              fontSize: "16px",
              textTransform: "capitalize",
              marginBottom: "0.5em",
            }}
          >
            {userName}{" "}
            <small style={{ textTransform: "lowercase", fontSize: "13px" }}>
              {props?.updatedTime}
            </small>
          </p>
          <p style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>{body}</p>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {props.likes === 1 && currentUser ? (
              <IconButton
                disabled={disable}
                sx={{ mr: 2 }}
                onClick={likeCommentHandler}
              >
                <ThumbUpIcon sx={{ fontSize: 20 }} />
              </IconButton>
            ) : (
              <IconButton
                disabled={disable}
                sx={{ mr: 2 }}
                onClick={likeCommentHandler}
              >
                <ThumbUpOutlinedIcon sx={{ fontSize: 20 }} />
              </IconButton>
            )}
            {props.likes === 0 && props.is_touched === 1 && currentUser ? (
              <IconButton
                disabled={disable}
                sx={{ mr: 2 }}
                onClick={disLikeCommentHandler}
              >
                <ThumbDownIcon sx={{ fontSize: 20 }} />
              </IconButton>
            ) : (
              <IconButton
                disabled={disable}
                sx={{ mr: 2 }}
                onClick={disLikeCommentHandler}
              >
                <ThumbDownOffAltOutlinedIcon sx={{ fontSize: 20 }} />
              </IconButton>
            )}
            <Button
              style={{ fontSize: 13, cursor: "pointer" }}
              onClick={visibleCommentHandler}
            >
              Reply
            </Button>
          </Box>
          <Box sx={{ width: "100%" }}>
            {isReplyVisible && (
              <CommentInput
                placeholder="Add a comment....."
                commentValue={reply}
                onAddComment={onAddReply}
                onCancel={() => setReply("")}
                onChange={(value) => setReply(value)}
                totalRows={1}
                userIconSize={35}
                fullWidth
              />
            )}
          </Box>
          <Box>
            {replies?.length > 0 && (
              <Button
                variant="text"
                onClick={() => setIsReplyOpen(!isReplyOpen)}
                sx={{ ml: -1 }}
              >
                {isReplyOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon sx={{ mr: 0.5 }} />
                )}
                {replies?.length} REPLIES
              </Button>
            )}
            {isReplyOpen &&
              replies &&
              replies.map((reply, i) => (
                <Box
                  key={`${reply}_${i}`}
                  sx={{ marginLeft: 1.5, mb: 2, mt: 0.5 }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <AccountCircleIcon
                      sx={{
                        width: 25,
                        height: 25,
                        mr: 1,
                        color: "lightgray",
                      }}
                    />
                    <span
                      style={{
                        marginRight: "1em",
                        fontSize: "13px",
                        fontWeight: "400",
                        textTransform: "capitalize",
                      }}
                    >
                      {reply?.user?.first_name}
                    </span>
                    <span style={{ fontSize: "11px" }}>
                      {reply?.updatedTime}
                    </span>
                  </Box>
                  <p style={{ margin: "0.5em 0 0.5em 2em" }}> {reply?.body}</p>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Comment;
