import { Checkbox, FormControlLabel, FormGroup, MenuItem } from "@mui/material";

function MenuList({ onPressMenuList, title, check }) {
  return (
    <MenuItem disableRipple>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => onPressMenuList(e.target.checked)}
              checked={check}
            />
          }
          label={title}
        />
      </FormGroup>
    </MenuItem>
  );
}

export default MenuList;
