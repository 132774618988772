import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Modal } from "@mui/material";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { imgSizeError, imgTypeError } from "../../common/constants";
import styles from "../../styles/Components/projects/FileUploadModal.module.scss";
import ImageErrorHandling from "./ImageErrorHandling";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "5px",
  '@media only screen and (max-width: 768px)': { width: '80%', }
};
const fileTypes = ["JPG", "PNG", "JPEG"];

function FileUploadModal({ isModalVisible, onClose, onChangeFileUpload }) {
  const [isSizeError, setIsSizeError] = useState(false);
  const [isTypeError, setIsTypeError] = useState(false);
  return (
    <div>
      <Modal
        open={isModalVisible}
        onClose={() => {
          onClose();
          setIsSizeError(false);
          setIsTypeError(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box className={styles.container}>
            <Box className={styles.header}>
              <h4>Upload Files</h4>
              <Button
                className={styles.close}
                onClick={() => {
                  onClose();
                  setIsSizeError(false);
                  setIsTypeError(false);
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            <hr style={{ marginBottom: "1em" }} />
            <FileUploader
              handleChange={onChangeFileUpload}
              name="file"
              types={fileTypes}
              hoverTitle={" "} // don't not remove this blank space as it's patch fix for don't show hoverTitle
              multiple
              classes={styles.dropfiles}
              onDrop={() => {
                setIsSizeError(false);
                setIsTypeError(false);
              }}
              maxSize={5}
              onTypeError={(err) => {
                console.log("err", err);
                setIsTypeError(true);
                setIsSizeError(false);
              }}
              onSizeError={(err) => {
                setIsTypeError(false);
                setIsSizeError(true);
              }}
              children={
                <ImageErrorHandling
                  imgTypeError={isTypeError && imgTypeError}
                  imgSizeError={isSizeError && imgSizeError}
                />
              }
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default FileUploadModal;
