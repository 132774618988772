/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Alert, Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import MaterialReactTable from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import CategorySearchBar from "../../../components/Category/CategorySearchBar";
import CustomLoader from "../../../components/CustomLoader";
import MenuButton from "../../../components/MenuButton";
import Navbar from "../../../components/Navbar";
import {
  categoryPagination,
  categorySearchPagination,
  categoySearch,
  deleteCategory,
  getCategories,
} from "../../../store/slices/CategorySlice";

function Category(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const { categories } = props;
  const paginationData = props.pagination;
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isClearSearch, setIsClearSearch] = useState(false);

  const [seachInputValue, setSeachInputValue] = useState("");
  const [isSearchPagination, setisSearchPagination] = useState(false);
  const [searchPayload, setSearchPayload] = useState({});
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState(
    []
  );
  useEffect(() => {
    setLoading(true);
    dispatch(getCategories())
      .unwrap()
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log("catchError:", err);
      });
  }, [dispatch]);

  useEffect(() => {
    setCategory(categories);
  }, [categories]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const payload = {
        name: seachInputValue?.toLowerCase(),
      };
      callSearchApi(payload);
    }
  };

  const callSearchApi = (payload) => {
    setSearchPayload(payload);
    if (payload.name) {
      setLoading(true);
      setisSearchPagination(true);
      dispatch(categoySearch(payload))
        .unwrap()
        .then((res) => {
          console.log("res", res);
          setLoading(false);
        });
    }
  };

  const columnsData = useMemo(
    () => [
      {
        id: "name",
        header: "Category Name",
        accessorKey: "name",
        accessorFn: (category) => {
          return category.name.charAt(0).toUpperCase() + category.name.slice(1);
        },
      },
      {
        accessorKey: "updated_time",
        header: "Date Created",
        accessorFn: (project) => project.updated_at,
        Cell: ({ renderedCellValue, row }) => {
          return row.original.updated_time;
        },
      },
      {
        id: "flipbooks",
        accessorKey: "flipbooks",
        header: "Flipbooks",
        accessorFn: (project) => project.flipbooks,
      },
      {
        field: "",
        header: "Action",
        sortable: false,
        accessorFn: (project) => {
          let action = null;
          const navigateToEditScreen = () => {
            navigate(`/edit-category/${project?.id}`);
          };

          const onPressDelete = () => {
            dispatch(deleteCategory(project?.id)).then((res) => {
              if (res?.payload.data?.errors) {
                setServerSideValidationErrors(
                  Object.values(res?.payload.data?.errors)
                );
              } else if (isSearchPagination) {
                getSearchPagitionData();
              } else {
                getPaginatedData();
              }
            });
          };
          action = (
            <MenuButton
              notRequiredPremission
              onPressEdit={navigateToEditScreen}
              onPressDelete={onPressDelete}
            />
          );

          return <>{action}</>;
        },
      },
    ],
    [navigate, dispatch]
  );

  const onPressReset = () => {
    setIsClearSearch(!isClearSearch);
    setSeachInputValue("");
    setisSearchPagination(false);
    setLoading(true);
    dispatch(getCategories())
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log("catchError:", err);
      });
  };

  // pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    // do something when the pagination state changes
    if (isSearchPagination) {
      getSearchPagitionData();
    } else {
      getPaginatedData();
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const getPaginatedData = () => {
    setLoading(true);

    dispatch(
      categoryPagination({
        page: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      })
    )
      .unwrap()
      .then(() => {
        setLoading(false);
      });
  };

  const getSearchPagitionData = () => {
    searchPayload.size = pagination.pageSize;
    const payload = {
      id: pagination.pageIndex + 1,
      data: searchPayload,
      size: pagination.pageSize,
    };
    setLoading(true);
    dispatch(categorySearchPagination(payload))
      .unwrap()
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Navbar
        title="Categories"
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <CustomLoader isOpen={loading} />

      <Grid p={2} container item xs={12}>
        {serverSideValidationErrors &&
          serverSideValidationErrors.map((item, i) => (
            <Alert key={`${item}_${i}`} sx={{ mt: 2.5 }} severity="error">
              {item}
            </Alert>
          ))}
        <Box
          sx={{ width: "100%", mb: 3, mt: 2 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
          >
            <Box sx={{ mr: 1, width: "50%" }}>
              <CategorySearchBar
                onInputChange={(value) => setSeachInputValue(value)}
                handleKeyDown={(e) => handleKeyDown(e)}
                isClearSearch={isClearSearch}
              />
            </Box>
            <Button
              variant="contained"
              onClick={() =>
                callSearchApi({
                  name: seachInputValue,
                  size: pagination.pageSize,
                })
              }
              sx={{ mr: 1, mt: 1 }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onPressReset();
              }}
              sx={{ mt: 1, background: "#767676" }}
            >
              Reset
            </Button>
          </Box>
          <Button
            variant="contained"
            onClick={() => navigate("/create-category")}
          >
            Create Category
          </Button>
        </Box>
        <div style={{ height: 560, width: "100%" }}>
          <MaterialReactTable
            columns={columnsData}
            data={category ?? []}
            enableColumnActions={false}
            enableTopToolbar={false}
            manualPagination
            rowCount={paginationData?.total}
            onPaginationChange={setPagination}
            state={{ pagination }}
          />
        </div>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { categories, pagination } = state.category;
  return { categories, pagination };
}
export default connect(mapStateToProps)(Category);
