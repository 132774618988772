import { createSlice } from "@reduxjs/toolkit";
import {
  discoverFilter,
  discoverPagination,
  discoverSearch,
  discoverSearchPagination,
  dislikeProject,
  getDiscovers,
  likeProject,
} from "./discoverAction";

export const discoverSlice = createSlice({
  name: "discover",
  initialState: {
    discovers: [],
    pagination: {},
  },

  reducers: {},
  extraReducers: (builder) => {
    // get projects.
    builder.addCase(getDiscovers.pending, (state) => {});
    builder.addCase(getDiscovers.fulfilled, (state, action) => {
      state.discovers = action.payload.data.data;
      state.pagination = action.payload?.data?.meta;
    });
    builder.addCase(getDiscovers.rejected, (state) => {});

    // discover pagination.
    builder.addCase(discoverPagination.pending, (state) => {});
    builder.addCase(discoverPagination.fulfilled, (state, action) => {
      state.discovers = action.payload?.data?.data;
      state.pagination = action.payload?.data?.meta;
    });
    builder.addCase(discoverPagination.rejected, (state) => {});

    // discoverSearch
    builder.addCase(discoverSearch.pending, (state) => {});
    builder.addCase(discoverSearch.fulfilled, (state, action) => {
      state.discovers = action.payload?.data?.data;
      state.pagination = action.payload?.data?.meta;
    });
    builder.addCase(discoverSearch.rejected, (state) => {});

    // discoverSearchPagination
    builder.addCase(discoverSearchPagination.pending, (state) => {});
    builder.addCase(discoverSearchPagination.fulfilled, (state, action) => {
      state.discovers = action.payload?.data?.data;
      state.pagination = action.payload?.data?.meta;
    });
    builder.addCase(discoverSearchPagination.rejected, (state) => {});

    builder.addCase(discoverFilter.pending, (state) => {});
    builder.addCase(discoverFilter.fulfilled, (state, action) => {
      state.discovers = action.payload?.data?.data;
      state.pagination = action.payload?.data?.meta;
    });
    builder.addCase(discoverFilter.rejected, (state) => {});

    builder.addCase(likeProject.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(likeProject.fulfilled, (state, action) => {
      const index = state.discovers.findIndex(
        (item) => item.id === action.payload.data.data.id
      );
      if (index >= 0) {
        state.discovers[index].likes = action.payload.data.data.likes;
        state.discovers[index].is_liked_by_current_user = 1;
        state.loader = false;
        state.fetched = true;
      }
    });
    builder.addCase(likeProject.rejected, (state) => {
      state.loader = false;
    });

    builder.addCase(dislikeProject.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(dislikeProject.fulfilled, (state, action) => {
      const index = state.discovers.findIndex(
        (item) => item.id === action.payload.data.data.id
      );
      if (index >= 0) {
        state.discovers[index].likes = action.payload.data.data.likes;
        state.discovers[index].is_liked_by_current_user = 0;
        state.loader = false;
        state.fetched = true;
      }
    });
    builder.addCase(dislikeProject.rejected, (state) => {
      state.loader = false;
    });
  },
});

export default discoverSlice.reducer;
