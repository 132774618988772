import { Box } from "@mui/material";

export default function Terms() {
  return (
    <Box sx={{ padding: "30px" }}>
      <h1 id="terms-of-use">Terms of use</h1>
      <p>
        These terms and conditions apply to all users of the ubabybaby flipbook
        website (
        <a href="https://flippbook-app.ubabybaby.com">
          https://flippbook-app.ubabybaby.com
        </a>
        ) (the &quot;Website&quot;) which is operated by ubabybaby limited
        (&quot;ubabybaby &quot;, &quot;Company&quot;, &quot;we&quot;,
        &quot;us&quot;, &quot;our&quot;). Your use of the Website is governed by
        these terms and conditions of use (&quot;Terms of Use&quot;). Please
        read these Terms of Use carefully before using the Website.
      </p>
      <p>
        Under this agreement, (i)&quot;Services&quot; shall mean any of the
        Internet based or other services offered by the Company, including, but
        not limited to, those listed at this Agreement, whether through a
        website owned or controlled by the Company, through a social networking
        system, a computer software, a mobile application, on your cell phone or
        otherwise; (ii) &quot;Published Content&quot; means digital assets
        uploaded and published via the Services; (iii) &quot;Pre-Publication
        Assets&quot; means raw editorial content (e.g., text, images, layout
        information) uploaded to our Platform; (iii) &quot;User
        Submissions&quot; means Published Content and Pre-Publication Assets,
        collectively, and also means each and every item of content or other
        materials (including but not limited to images, links, documents, text,
        writings, photographs, graphics, videos, greeting cards,
        &quot;Skins&quot;, &quot;Sketches&quot;, or files) uploaded by a user
        through the Service or otherwise integrated into the Service by a user;
        (iv) &quot;User Created Art&quot; shall mean any intellectual property
        created by a user as part of his or her use of the Services.
      </p>
      <p>
        By using the Services, you agree to be bound by these Terms. If you are
        using the Services on behalf of an organization, you are agreeing to
        these Terms for that organization and promising that you have the
        authority to bind that organization to these terms. In that case,
        &quot;you&quot; and &quot;your&quot; will refer to that organization.
      </p>
      <p>
        By accessing and using our website and Services, you agree to the Terms
        that are subject to ubabybaby’s Master Terms of Service (&quot;Master
        Terms&quot;) which are incorporated by reference, and you further agree
        to comply with all these provisions and terms.
      </p>
      <p>
        These Terms apply to your use of our website as well as other pages,
        information, software, services, products and contents which may be
        operated, hosted or managed by ubabybaby or its affiliates. Some of
        these products and services may require your use of an Account (the
        &quot;Account&quot;), and these terms contain terms which are applicable
        to the use of such products and services. By using our Services or by
        completing the registration process to obtain and use an Account, you
        agree to be bound by this Agreement for as long as you continue to be a
        member. If you do not agree to this Agreement, please do not register
        with our Website or otherwise access or use our Website.
      </p>
      <p>
        You may use the Service only in compliance with these Terms. The
        Services may continue to change over time as we refine and add more
        features.
      </p>
      <p>
        If you breach any of the Terms in these Terms of Services, your
        authorization to use our Services automatically terminates.
      </p>
      <h2 id="1-use-of-the-service">1. Use of the Service</h2>
      <h3 id="1-1-use-of-the-service-what-ubabybaby-provides">
        1.1 Use of the Service – What ubabybaby Provides
      </h3>
      <p>
        The Company grants you a non-exclusive, non-transferable, revocable,
        limited license to use the Service and related software and display the
        results of such Service. You agree not to copy or distribute the content
        of the Service except as specifically allowed in these Terms. You also
        agree that you have no right to access, view, or alter any Company&#39;s
        source code or object code.
      </p>
      <p>
        The Company grants you a limited, revocable license to post a copy of
        your User Created Content on your own website or on a third-party
        website that complies with all applicable laws and these Terms of
        Service.
      </p>
      <p>
        Finally, the Company reserves the right to discontinue the Services or
        to change the content of the Services in any way and at any time, with
        or without notice to you, without liability.
      </p>
      <h3 id="1-2-use-of-the-service-your-obligations">
        1.2 Use of the Service – Your Obligations
      </h3>
      <p>You represent and agree that:</p>
      <ul>
        <li>
          You are able to enter into this agreement. You have the right,
          authority, and capacity to enter into the agreement represented by
          these Terms and to abide by all of the terms and conditions of these
          Terms;
        </li>
        <li>
          If you are accepting these Terms and using the Services on behalf of a
          company, organization, institution, or other legal entity, you
          represent and warrant that you are authorized to do so and have the
          authority to bind such entity to these Terms, in which case the words
          “you” and “your” as used in these Terms shall refer to such entity.
        </li>
        <li>
          the Service requires all persons to be at least 13 years old before
          they create an account. If you are between age 13 and the age of
          majority where you reside, your legal guardian must review and agree
          to the terms of service. Creating an account using false information
          is a violation of our terms;
        </li>
        <li>
          You do not collect or harvest any personal data of any user of the
          Site or the Service.
        </li>
        <li>
          Except as expressly permitted, you will not copy, redistribute,
          publish or otherwise exploit material from the Service without the
          express prior written permission of the Company;
        </li>
        <li>
          Any User Created Art is your original work and your contribution to
          the User Created Art does not violate any third party’s privacy
          rights, publicity rights, copyright or other intellectual property
          rights;
        </li>
        <li>
          You agree to pay all royalties, fees, and any other amounts concerning
          your contribution to User Created Art;
        </li>
        <li>
          The Service may contain content that you find offensive and waive any
          objections you might have with respect to viewing such content.
        </li>
      </ul>
      <h2 id="2-account">2. Account</h2>
      <h3 id="2-1-guest">2.1 Guest</h3>
      <p>
        You may browse the Website without creating an account, subject to these
        Terms. We will record basic analytics info for improving our service,
        you can learn more in our Privacy Policy.
      </p>
      <h3 id="2-2-create-account">2.2 Create Account</h3>
      <p>
        You must ensure that your account information (the information you
        provided when you registered for ubabybaby) remains current, complete,
        accurate, and truthful. All ubabybaby accounts are non-transferable, and
        any rights to them terminate upon the account holder’s death. You can
        change or correct your account information by logging into your
        ubabybaby account.
      </p>
      <p>
        By connecting to ubabybaby with social media login, you accept that we
        will receive and consequently use your information, as permitted by the
        chosen social media, and store your log-in credentials. The collection
        and processing of your personal data are in accordance with our Privacy
        Policy.
      </p>
      <p>
        You are responsible for all activity that happens on or through your
        account. To protect your account, keep your password confidential. Do
        not reuse your account password with other services.
      </p>
      <p>
        If you become aware that your account has been compromised, you should
        notify ubabybaby immediately. Without prejudice to your statutory
        rights, if you forget your password and cannot validate your account to
        ubabybaby, you acknowledge and agree that your account may be
        inaccessible to you and that all data associated with the account may
        not be retrievable.
      </p>
      <h3 id="2-3-account-suspension">2.3 Account Suspension</h3>
      <p>
        We reserve the right to suspend or end the Services account at any time,
        with or without cause, and with or without notice. If inappropriate
        activity is detected, all accounts of the Customer in question will be
        deactivated until an investigation is complete. For example, we may
        suspend or terminate your use if you are not complying with these Terms,
        or use the Services in any way that would cause us legal liability or
        disrupt others’ use of the Services.{" "}
      </p>
      <h2 id="3-user-submissions">3. User Submissions</h2>
      <h3 id="3-1-pre-publication-assets">3.1 Pre-Publication Assets</h3>
      <p>
        By uploading Pre-Publication Assets to our Sites, you hereby grant to
        ubabybaby a worldwide, non-exclusive, transferable, assignable, fully
        paid-up, royalty-free, license to host, store, transfer, display,
        perform, reproduce, and distribute your Pre-Publication Assets on the
        ubabybaby Service solely in order to enable you to work with those
        Pre-Publication Assets in the manner selected by you.
      </p>
      <h3 id="3-2-representations">3.2 Representations</h3>
      <p>
        You are solely responsible for all of your User Submissions and you
        hereby recognize and affirm that the ubabybaby Service is merely
        providing you the means to collaborate on and make available your User
        Submissions. Accordingly, you shall be solely responsible for each of
        your User Submissions and the consequences of uploading them. By
        uploading your User Submissions, you affirm, represent, and warrant
        that: (1) you are the creator and owner of or have the necessary
        licenses, rights, consents, and permissions to use and to authorize
        ubabybaby and ubabybaby’s Users to use and distribute your User
        Submissions as necessary to exercise the licenses granted by you in this
        section and in the manner contemplated by ubabybaby and these Terms; (2)
        your User Submissions do not and will not: (i) infringe, violate, or
        misappropriate any third-party right, including any copyright,
        trademark, patent, trade secret, moral right, privacy right, right of
        publicity, or any other intellectual property or proprietary right or
        (ii) slander, defame, libel, or invade the right of privacy, publicity
        or other property rights of any other person; and (ii) your User
        Submissions do not contain any viruses, adware, spyware, worms, or other
        malicious code or any content or file that provides a method to access
        to potentially infringing content outside of the Services. Violators of
        these third-party rights may be subject to criminal and civil liability.
        We reserves all rights and remedies against any Users who violate these
        Terms.
      </p>
      <h3 id="3-3-removal-of-user-submissions">
        3.3 Removal of User Submissions
      </h3>
      <p>
        Although ubabybaby has no obligation to remove, screen, edit, or monitor
        any Published Content, ubabybaby reserves the right, and has absolute
        discretion, to remove, screen, edit, or disable any User Submission at
        any time and for any reason without notice. In addition, although
        ubabybaby has no obligation to remove, screen, edit, or monitor any
        Pre-Publication Assets, ubabybaby reserves the right, and has absolute
        discretion, to remove, screen, edit, or disable any Pre-Publication
        Asset at any time and for any reason.
      </p>
      <h3 id="3-4-no-endorsement">3.4 No Endorsement</h3>
      <p>
        Further, you understand that when using the Service you will be exposed
        to Published Content from a variety of sources, and that ubabybaby is
        not responsible for the accuracy, content, usefulness, or intellectual
        property rights of or relating to such Published Content. You further
        understand and acknowledge that you may be exposed to Published Content
        that is inaccurate, offensive, indecent or objectionable, and you agree
        to waive, and hereby do waive, any legal or equitable rights or remedies
        you have or may have against ubabybaby with respect thereto. ubabybaby
        does not endorse any User Submissions or any opinion, recommendation or
        advice expressed in any User Submission, and ubabybaby expressly
        disclaims any and all liability in connection with User Submissions. If
        notified by a User or a content owner of a User Submission that
        allegedly does not conform to these Terms, ubabybaby may investigate the
        allegation and determine in its sole discretion whether to remove the
        User Submission, which it reserves the right to in accordance with these
        Terms. For clarity, ubabybaby does not permit copyright infringing
        activities on the Services.
      </p>
      <h2 id="4-prohibited-content">4. Prohibited Content</h2>
      <p>
        User, who upload, post, or transmit prohibited content, result in
        service termination without prior notice. You agree not to upload, post,
        or transmit any following content :
      </p>
      <ul>
        <li>
          Adult Content – You are prohibited from storing or posting adult
          content, or links to adult content, through the Services. All material
          of pornographic nature is considered adult content. Online content
          whose primary purpose is the public display of fine art or artistic
          mediums are not considered to be adult content.
        </li>
        <li>
          Offensive Content – Intolerant, defamatory, stalks, abuse, harassment,
          invitations to fight, threatening, or any communication of any nature
          that we decide, in our sole discretion, as being improper, will result
          in service termination without prior notice.
        </li>
        <li>
          INTELLECTUAL PROPERTY PROVISIONS – You are prohibited from storing or
          posting content or links to content that infringe, or otherwise
          violate the intellectual property rights of third parties (e.g.
          trademark, patent, or copyright infringements).
        </li>
        <li>
          Violence Content – You are prohibited from uploading, posting, or
          otherwise transmitting any material that constitutes a direct threat
          of violence against any person or group of people;
        </li>
        <li>
          Hatred Content – You are prohibited from uploading, posting, or
          otherwise transmitting any material that promotes hatred towards
          groups based on race or ethnic origin, religion, disability, gender,
          age, and sexual orientation/gender identity;
        </li>
        <li>
          Misleading &amp; None-Good-Faith Content – You are prohibited from
          uploading, posting, or otherwise transmitting any material that
          conducts any misleading and none-good-faith behavior that we decide,
          in our sole discretion, as being improper.
        </li>
        <li>
          Unauthorized Personal Identical Info. You’re prohibited to upload any
          unauthorized Personally Identifiable Information (PII), including, but
          not limited to unauthorized personal images, contacts, address,
          emails, phone numbers, and social media accounts.
        </li>
      </ul>
      <h2 id="5-prohibited-conduct">5. Prohibited Conduct</h2>
      <p>
        You agree not to commit any act of the following prohibited conduct:
      </p>
      <ul>
        <li>
          use the Service for any purpose other than to disseminate or receive
          original or appropriately licensed content and to access the Service
          as such services are offered by ubabybaby;
        </li>
        <li>
          defame, harass, abuse, threaten or defraud Users of the Services, or
          post, upload, or distribute any content that is unlawful or otherwise
          inappropriate, or collect, or attempt to collect, personal information
          about Users or third parties without their consent, or use the content
          on Services for any commercial use, it being understood that the
          content available on the Services is for personal, non-commercial use
          only;
        </li>
        <li>
          impersonate any person or entity, falsely claim an affiliation with
          any person or entity, or access the Services accounts of others
          without permission, forge another persons’ digital signature,
          misrepresent the source, identity, or content of information
          transmitted via the Services, or perform any other similar fraudulent
          activity;
        </li>
        <li>
          hack, remove, circumvent, disable, damage or otherwise interfere with
          security-related features of the Services or User Submissions,
          features that prevent or restrict use or copying of any content
          accessible through the Services, or features that enforce limitations
          on the use of the Services or User Submissions, or intentionally
          interfere with or damage operation of the Services or any user’s
          enjoyment of them, by any means, including uploading or otherwise
          disseminating viruses, adware, spyware, worms, or other malicious
          code;
        </li>
        <li>
          reverse engineer, decompile, disassemble or otherwise attempt to
          discover the source code of the Services or any part thereof, except
          and only to the extent that such activity is expressly permitted by
          applicable law notwithstanding this limitation;
        </li>
        <li>
          harvest or otherwise collect information about others, including
          e-mail addresses, without their consent;
        </li>
        <li>
          create a false identity or forged e-mail address or header, or
          otherwise attempt to mislead others as to the identity of the sender
          or the origin of the message;
        </li>
        <li>interfere with another User’s use and enjoyment of the Service.</li>
      </ul>
      <h2 id="6-privacy-consent-to-electronic-communication">
        6. Privacy; Consent to Electronic Communication
      </h2>
      <h3 id="6-1-privacy-policy">6.1 Privacy Policy</h3>
      <p>
        Your privacy is important to us. ubabybaby’s Privacy Policy is hereby
        incorporated into these Terms by reference. Please read the Privacy
        Policy carefully for information relating to ubabybaby’s collection,
        use, and disclosure of your personal information. If you do not agree to
        Privacy Policy, please do not register with our Website or otherwise
        access or use our Website.
      </p>
      <h3 id="6-2-newsletters">6.2 Newsletters</h3>
      <p>
        When registering for a ubabybaby Service account, you are automatically
        opted-in to receive emails from us, which may include software update
        newsletters, announcements, and promotion emails about our software and
        other promotion deals from third parties. You may opt-out of emails at
        any time by following the instructions contained within the email. You
        agree that any notices, agreements, disclosures, or other communications
        that we send to you electronically will satisfy any legal communication
        requirements, including that such communications be in writing.
      </p>
      <h2 id="7-disclaimer-of-warranties">7. Disclaimer of Warranties</h2>
      <p>
        ubabybaby disclaims all warranties, statutory, express or implied,
        including, but not limited to, implied warranties of merchantability,
        fitness for a particular purpose, and non-infringement of proprietary
        rights. No advice or information, whether oral or written, obtained by
        you from ubabybaby or through the ubabybaby Service will create any
        warranty not expressly stated herein. You expressly acknowledge that
        this disclaimer includes ubabybaby’s officers, directors, employees,
        shareholders, agents, licensors and subcontractors. You expressly agree
        that the use of the Service is at your sole risk.
      </p>
      <p>
        The Services and any data, information, user submissions, linked sites,
        products, services, or applications made available in conjunction with
        or through the Service are provided on an &quot;as is&quot; and &quot;as
        available&quot;, &quot;with all faults&quot; basis and without
        warranties or representations of any kind either express or implied.
        ubabybaby, its licensors, affiliates, and partners do not warrant that
        the data, user submissions, or any other products, services or
        applications offered on or through the Service or any linked sites will
        be uninterrupted, or free of errors, viruses or other harmful components
        and do not warrant that any of the foregoing will be corrected.
        ubabybaby, its licensors, affiliates, and partners do not warrant or
        make any representations regarding the use or the results of the use of
        the Service or any linked sites in terms of correctness, accuracy,
        reliability, or otherwise.
      </p>
      <p>
        You understand and agree that you use, access, download, or otherwise
        obtain information, materials, or data through the Service or any linked
        sites at your own discretion and risk and that you will be solely
        responsible for any damage to your property (including your computer
        system) or loss of data that results from the download or use of such
        material or data.
      </p>
      <h2 id="8-modifications">8. Modifications</h2>
      <p>
        We may revise these Terms from time to time and the most current version
        will always be posted on our website. If a revision, at our sole
        discretion, is material we will notify you (for example via email to the
        email address associated with your account). Other changes may be posted
        to our terms page, so please check those pages regularly. By continuing
        to access or use the Services after revisions become effective, you
        agree to be bound by the revised Terms. If you do not agree to the new
        terms, please stop using the Services.
      </p>
      <h2 id="9-intellectual-property-rights">
        9. Intellectual Property Rights
      </h2>
      <h3 id="9-1-your-rights">9.1 Your rights</h3>
      <p>
        You retain full ownership of your content (&quot;User Content&quot;). By
        &quot;User Content,&quot; we understand both the stuff you upload on
        ubabybaby (&quot;User Contributed Content&quot;) and the content you
        generated/published on ubabybaby (&quot;User Created Content&quot;).
        ubabybaby does NOT claim ANY ownership rights in the text, files,
        images, photos, video, sounds, musical works, works of authorship, or
        any other materials (&quot;User Content&quot;) that you post on
        ubabybaby. However, we need you to grant us certain rights in the
        &quot;User Content&quot; so that we can incorporate such &quot;User
        Content&quot; in our services. Without such rights, we may be violating
        copyright and other laws by storing, posting, backing up, and allowing
        the download of User Content on or through ubabybaby. Therefore, by
        displaying or publishing any content on or through ubabybaby, you hereby
        grant ubabybaby a non-exclusive, fully paid, and royalty-free,
        worldwide, limited license to use, modify, delete from, add to, publicly
        perform, publicly display, reproduce, and translate such content,
        including without limitation distributing part or all of the Site in any
        media formats through any media channels, except content not shared
        publicly (&quot;private&quot;) which will not be distributed outside
        ubabybaby.
      </p>
      <p>
        ubabybaby expressly disclaims any and all liabilities in connection with
        any User Contributed Content or User Created Art.
      </p>
      <h3 id="9-2-copyrights">9.2 Copyrights</h3>
      <p>
        You retains any and all rights to the materials while using the service.
      </p>
      <p>
        If a grounded claim is received from the respective copyright holder
        with regard to infringement of its lawful rights, ubabybaby limited is
        entitled to delete the materials placed by the User or block access to
        them without notifying the User or giving reasons.
      </p>
      <p>
        The User assumes responsibility for any materials published by him/her.
        In case third parties assert a claim with respect to the materials
        placed by the User, the User shall settle such claims independently and
        at his/her own expense.
      </p>
      <p>
        If the User distributes the results of using the service he/she does it
        at his/her own risk, including the protection of confidential
        information. ubabybaby limited is not responsible for the actions of
        such individuals and cannot monitor their motives and goals. The service
        provides only the technical possibility of presenting the results of the
        service usage. The User agrees that after the publication of materials
        while using IS, their confidentiality may be violated and, the materials
        may become available to the public.
      </p>
      <p>
        ubabybaby limited has the right to discontinue providing the service to
        any person upon receiving from an interested person a reasonable claim
        fulfilling the requirements of current legislation.
      </p>
      <h2 id="10-consequences-of-violation-of-terms">
        10. Consequences of violation of terms
      </h2>
      <p>
        We may, without notice to you, suspend your Service or remove any
        materials transmitted via ubabybaby flipbook website if it discovers
        facts that lead it to reasonably believe that our service is being used
        in violation of these Terms.{" "}
      </p>
      <p>
        You acknowledge that your use of the Service is conditioned upon your
        compliance with these Terms, and any use of the Service in violation of
        these Terms will not only be regarded as a breach of these Terms but
        also as an infringement of the Company&#39;s copyrights in and to the
        Service. The Company reserves the right to terminate your access to the
        Service without notice if you violate these Terms.
      </p>
      <h2 id="11-miscellaneous">11. Miscellaneous</h2>
      <p>
        You are solely responsible for your conduct, the content of your files
        and folders. For example, it’s your responsibility to ensure that you
        have the rights or permission needed to comply with these Terms.
      </p>
      <p>
        We may choose to review public content for compliance with our community
        guidelines, but you acknowledge that the Company has no obligation to
        monitor any information on the Service. We are not responsible for the
        accuracy, completeness, appropriateness, or legality of files, user
        posts, or any other information you may be able to access using the
        Service.
      </p>
      <p>
        Files and other content in the Service may be protected by intellectual
        property rights of others. Please do not copy, upload or download files
        unless you have the right to do so. You, not ubabybaby, will be fully
        responsible and liable for what you copy, upload, download or otherwise
        use while using the Service. You must not upload spyware or any other
        malicious software to the Service.
      </p>
      <p>
        You, and not ubabybaby, are responsible for maintaining and protecting
        all of your stuff. ubabybaby will not be liable for any loss or
        corruption of your stuff, or for any costs or expenses associated with
        backing up or restoring any of your stuff.
      </p>
      <p>
        Please contact us at cs@ubabybaby.com with any questions regarding this
        Agreement.
      </p>
    </Box>
  );
}
