import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

import styles from "../../styles/Components/SelectInput.module.scss";

export default function CategorySearchBar({
  onInputChange,
  handleKeyDown,
  isClearSearch,
}) {
  const [inputSearchValue, setInputSearchValue] = useState("");

  useEffect(() => {
    setInputSearchValue("");
  }, [isClearSearch]);

  return (
    <div className={styles.container}>
      <div className={styles.select}>
        <TextField
          value={inputSearchValue}
          onChange={(e) => {
            onInputChange(e.target.value);
            setInputSearchValue(e.target.value);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          placeholder="Search name"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
}
