import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FlipbookComponent from "../Discover/Flipbook/Flipbook";

export default function ShareWithIframe({
  open,
  handleClose,
  shareLink,
  currentProjectFiles,
}) {
  const [width, setWidth] = useState(800);
  const [height, setHeight] = useState(550);
  const [showMessage, setShowMessage] = useState(false);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Embed into website
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Stack gap={2} direction="row" flexWrap="wrap">
          <Stack flex={3}>
            <FlipbookComponent files={currentProjectFiles} />
          </Stack>
          <Stack flex={1}>
            <CopyToClipboard
              text={`<iframe allow="fullscreen" allowfullscreen="allowfullscreen" src="${shareLink}" height="${height}" width="${width}" title="flipbook"></iframe>`}
            >
              <Stack gap={2} direction="row" flexWrap="wrap">
                <Stack flex={2}>
                  <textarea
                    rows={5}
                    style={{
                      border: "1px solid",
                      borderRadius: 3,
                    }}
                    disabled
                    value={`<iframe allow="fullscreen" allowfullscreen="allowfullscreen" src="${shareLink}" height="${height}" width="${width}" title="flipbook"></iframe>`}
                  />
                </Stack>
                <Stack width="100%">
                  <Stack
                    direction="row"
                    gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <p>Width</p>
                    <input
                      style={{
                        height: 25,
                        border: "1px solid",
                        borderRadius: 3,
                      }}
                      value={width}
                      onChange={(e) => setWidth(e.target.value)}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    gap={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <p>Height</p>
                    <input
                      style={{
                        height: 25,
                        border: "1px solid",
                        borderRadius: 3,
                      }}
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </Stack>
                </Stack>
                <Button
                  onClick={() => {
                    setShowMessage(true);
                    setTimeout(() => {
                      setShowMessage(false);
                    }, 2000);
                  }}
                  variant="outlined"
                  sx={{ width: "100%" }}
                >
                  COPY CODE
                </Button>
                {showMessage && (
                  <Stack sx={{ width: "100%" }} gap={2}>
                    <Alert
                      onClose={() => {
                        setShowMessage(false);
                      }}
                    >
                      Embed code copied to clipboard
                    </Alert>
                  </Stack>
                )}
              </Stack>
            </CopyToClipboard>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
