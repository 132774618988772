import axios from "axios";
import store from "../store";
import { logout } from "../store/slices/authenticationSlice";

const { dispatch } = store;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      dispatch(logout());
    }
    return Promise.reject(error);
  }
);
