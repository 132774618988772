import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <Box
      id="error-page"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      height="90vh"
    >
      <div>
        <p>
          <i>404 Page Not Found</i>
          <br />
          <Link to="/">Return to home</Link>
        </p>
      </div>
    </Box>
  );
}
