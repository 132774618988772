import { createSlice } from "@reduxjs/toolkit";
import {
  addComment,
  addReply,
  createProject,
  deleteCroppedOnBackButton,
  deletedProject,
  disLikeComment,
  dislikeProject,
  fecthCroppingConfiguration,
  getCroppedFilesByProject,
  getFlipBookHeightWidth,
  getProjectById,
  getProjects,
  getSubscription,
  increaseViewProject,
  likeComment,
  likeProject,
  projectFileDelete,
  projectFileUpload,
  projectPagination,
  projectSearch,
  projectSearchPagination,
  publishProject,
  reArrangeImages,
  subscribeProject,
  updateProject,
} from "./project/projectAction";

export const projectSlice = createSlice({
  name: "project",
  initialState: {
    projects: [],
    projectDetails: {},
    status: false,
    loader: false,
    projectFiles: [],
    paginationData: {},
    subscription: [],
    chooseLayoutFiles: [],
    croppingConfiguration: null,
  },

  reducers: {
    setFilesToRetrieveOnBack(state, action) {
      state.projectDetails.files = action.payload;
    },
    setFilesToFlipBook(state, action) {
      state.projectDetails.croppedfiles = action.payload;
    },
    setFilestoBlank(state, action) {
      state.projectDetails.files = [];
      state.projectDetails.croppedfile = [];
      state.projectFiles = [];
    },
    setProjectToInitial(state) {
      state.projectDetails = {};
    },
    setNull(state) {
      state = {
        projects: [],
        projectDetails: {},
        status: false,
        loader: false,
        projectFiles: [],
        paginationData: {},
        subscription: [],
        chooseLayoutFiles: [],
        croppingConfiguration: null,
      };
    },
    setSortOrder(state, action) {
      const groupItems = action.payload;
      let sort_order = 1;
      const groupFiles = groupItems.map((i) => {
        const item = { ...i };
        if (item.original_name?.toLowerCase().includes("cover")) {
          item.sort_order = 0;
        } else {
          item.sort_order = sort_order;
          sort_order++;
        }
        return item;
      });

      state.projectDetails.files = groupFiles;
    },
    sortComments(state, action) {
      const { comments } = action.payload;
      let sortedComments = null;
      if (action.payload.by === "top") {
        sortedComments = comments.slice().sort((a, b) => b.likes - a.likes);
      } else {
        sortedComments = comments.slice().sort((a, b) => {
          const c = new Date(a.updated_at).getTime();
          const d = new Date(b.updated_at).getTime();
          return d - c;
        });
      }
      state.projectDetails.comments = sortedComments;
    },
  },
  extraReducers: (builder) => {
    // project file upload
    builder.addCase(projectFileUpload.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(projectFileUpload.fulfilled, (state, action) => {
      if (!action.payload.data?.errors) {
        const projectFiles = JSON.parse(JSON.stringify(state?.projectFiles));
        const newUploadedFiles = [action.payload?.data, ...projectFiles];
        state.projectFiles = newUploadedFiles;
        state.projectDetails.files = newUploadedFiles;
        state.loader = false;
        state.status = true;
      }
    });
    builder.addCase(projectFileUpload.rejected, (state, action) => {
      state.loader = false;
      throw new Error(action);
    });

    // projectFileDelete
    builder.addCase(projectFileDelete.pending, (state) => {
      // state.loader = true;
      state.status = false;
    });
    builder.addCase(projectFileDelete.fulfilled, (state, action) => {
      const projectFileData = action.payload.data;
      const projectFiles = JSON.parse(JSON.stringify(state.projectFiles));
      const fileIndex = projectFiles.findIndex(
        (element) => element?.id === parseInt(projectFileData?.data?.id)
      );
      if (fileIndex !== -1) {
        projectFiles.splice(fileIndex, 1);
      }
      state.projectFiles = projectFiles;
      state.loader = false;
      state.status = true;
    });
    builder.addCase(projectFileDelete.rejected, (state, action) => {
      state.loader = false;
      throw new Error(action);
    });

    // delete project
    builder.addCase(deletedProject.pending, (state) => {
      // state.loader = true;
    });
    builder.addCase(deletedProject.fulfilled, (state, action) => {
      const projectData = action.payload.data;
      const projects = JSON.parse(JSON.stringify(state.projects));
      const fileIndex = projects.findIndex(
        (element) => element.id === parseInt(projectData?.data?.id)
      );
      if (fileIndex !== -1) {
        projects.splice(fileIndex, 1);
      }
      state.projects = projects;
    });
    builder.addCase(deletedProject.rejected, (state, action) => {
      throw new Error(action);
    });

    // publishProject
    builder.addCase(publishProject.pending, (state, action) => {});
    builder.addCase(publishProject.fulfilled, (state, action) => {
      const projectData = action.payload.data;
      const projects = JSON.parse(JSON.stringify(state.projects));

      const projectIndex = projects.findIndex(
        (element) => element.id === projectData?.data?.id
      );
      if (projectIndex !== -1) {
        projects[projectIndex] = projectData?.data;
      }
      state.projects = projects;
    });
    builder.addCase(publishProject.rejected, (state, action) => {
      throw new Error(action);
    });

    // Lock user by id
    builder.addCase(getProjectById.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(getProjectById.fulfilled, (state, action) => {
      state.loader = false;
      state.fetched = true;
      // console.log(action.payload.data.data);
      state.projectDetails = action.payload.data.data;
    });
    builder.addCase(getProjectById.rejected, (state) => {
      state.loader = false;
    });

    builder.addCase(getFlipBookHeightWidth.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(getFlipBookHeightWidth.fulfilled, (state, action) => {
      state.loader = false;
      state.fetched = true;
      state.projectDetails.desired_height =
        action.payload.data.data.desired_height;
      state.projectDetails.desired_width =
        action.payload.data.data.desired_width;
    });
    builder.addCase(getFlipBookHeightWidth.rejected, (state) => {
      state.loader = false;
    });
    // Add comment to project.
    builder.addCase(addComment.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(addComment.fulfilled, (state, action) => {
      const projectDetails = JSON.parse(JSON.stringify(state.projectDetails));
      const previousComments = projectDetails?.comments;
      const newComment = [action.payload?.data.data, ...previousComments];
      projectDetails.comments = newComment;
      state.projectDetails = projectDetails;
      state.loader = false;
      state.fetched = true;
    });
    builder.addCase(addComment.rejected, (state) => {
      state.loader = false;
    });

    // Add Reply to comment
    builder.addCase(addReply.pending, (state) => {});
    builder.addCase(addReply.fulfilled, (state, action) => {
      // console.log("action", action);
      const commentId = action?.payload?.data?.data?.parent_id;
      const projectDetails = JSON.parse(JSON.stringify(state.projectDetails));
      const comments = projectDetails?.comments;
      const commentIndex = comments.findIndex((item) => item.id === commentId);

      const previousReplies = comments[commentIndex].replies;

      const newReply = [action.payload?.data?.data, ...previousReplies];

      projectDetails.comments[commentIndex].replies = newReply;
      state.projectDetails = projectDetails;
    });
    builder.addCase(addReply.rejected, (state) => {
      state.loader = false;
    });

    // update project.
    builder.addCase(updateProject.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.projectDetails = action.payload.data.data;
      state.loader = false;
      state.fetched = true;
    });
    builder.addCase(updateProject.rejected, (state) => {
      state.loader = false;
    });

    // reArrangeImages
    builder.addCase(reArrangeImages.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reArrangeImages.fulfilled, (state, action) => {
      //  state.projectDetails.files = action.payload.data.data;
    });
    builder.addCase(reArrangeImages.rejected, (state) => {
      state.loader = false;
    });

    // get projects.
    builder.addCase(getProjects.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.projects = action.payload?.data?.data;
      state.paginationData = action.payload?.data?.meta;
    });
    builder.addCase(getProjects.rejected, (state) => {});

    // projectPagination
    builder.addCase(projectPagination.pending, (state) => {});
    builder.addCase(projectPagination.fulfilled, (state, action) => {
      state.projects = action.payload?.data?.data;
      state.paginationData = action.payload?.data?.meta;
    });
    builder.addCase(projectPagination.rejected, (state) => {});

    // project search.
    builder.addCase(projectSearch.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(projectSearch.fulfilled, (state, action) => {
      state.projects = action.payload.data.data;
      state.paginationData = action.payload?.data?.meta;
    });
    builder.addCase(projectSearch.rejected, (state) => {
      state.loader = false;
    });

    // projectSearchPagination
    builder.addCase(projectSearchPagination.pending, (state) => {});
    builder.addCase(projectSearchPagination.fulfilled, (state, action) => {
      state.projects = action.payload.data.data;
      state.paginationData = action.payload?.data?.meta;
    });
    builder.addCase(projectSearchPagination.rejected, (state) => {});

    // subscribeProject
    builder.addCase(subscribeProject.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(subscribeProject.fulfilled, (state, action) => {
      if (state.projectDetails.channel) {
        state.projectDetails.channel.is_subscribed =
          !state.projectDetails.channel.is_subscribed;
      }
      state.loader = false;
      state.fetched = true;
    });
    builder.addCase(subscribeProject.rejected, (state) => {
      state.loader = false;
    });

    builder.addCase(likeProject.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(likeProject.fulfilled, (state, action) => {
      state.projectDetails.likes = action.payload.data.data.likes;
      state.projectDetails.is_liked_by_current_user = 1;
      if (state.subscription.this_month) {
        const index = state.subscription.this_month.findIndex(
          (item) => item.id === action.payload.data.data.id
        );
        if (index >= 0) {
          state.subscription.this_month[index].likes =
            action.payload.data.data.likes;
          state.subscription.this_month[index].is_liked_by_current_user = 1;
        }
      }
      if (state.subscription.todays) {
        const index = state.subscription.todays.findIndex(
          (item) => item.id === action.payload.data.data.id
        );
        if (index >= 0) {
          state.subscription.todays[index].likes =
            action.payload.data.data.likes;
          state.subscription.todays[index].is_liked_by_current_user = 1;
        }
      }
      if (state.subscription.this_week) {
        const index = state.subscription.this_week.findIndex(
          (item) => item.id === action.payload.data.data.id
        );
        if (index >= 0) {
          state.subscription.this_week[index].likes =
            action.payload.data.data.likes;
          state.subscription.this_week[index].is_liked_by_current_user = 1;
        }
      }
      if (state.subscription.older) {
        const index = state.subscription.older.findIndex(
          (item) => item.id === action.payload.data.data.id
        );
        if (index >= 0) {
          state.subscription.older[index].likes =
            action.payload.data.data.likes;
          state.subscription.older[index].is_liked_by_current_user = 1;
        }
      }

      state.loader = false;
      state.fetched = true;
    });
    builder.addCase(likeProject.rejected, (state) => {
      state.loader = false;
    });

    builder.addCase(dislikeProject.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(dislikeProject.fulfilled, (state, action) => {
      state.projectDetails.likes = action.payload.data.data.likes;
      state.projectDetails.is_liked_by_current_user = 0;
      if (state.subscription.this_month) {
        const index = state.subscription.this_month.findIndex(
          (item) => item.id === action.payload.data.data.id
        );
        if (index >= 0) {
          state.subscription.this_month[index].likes =
            action.payload.data.data.likes;
          state.subscription.this_month[index].is_liked_by_current_user = 0;
        }
      }
      if (state.subscription.todays) {
        const index = state.subscription.todays.findIndex(
          (item) => item.id === action.payload.data.data.id
        );
        if (index >= 0) {
          state.subscription.todays[index].likes =
            action.payload.data.data.likes;
          state.subscription.todays[index].is_liked_by_current_user = 0;
        }
      }
      if (state.subscription.this_week) {
        const index = state.subscription.this_week.findIndex(
          (item) => item.id === action.payload.data.data.id
        );
        if (index >= 0) {
          state.subscription.this_week[index].likes =
            action.payload.data.data.likes;
          state.subscription.this_week[index].is_liked_by_current_user = 0;
        }
      }
      if (state.subscription.older) {
        const index = state.subscription.older.findIndex(
          (item) => item.id === action.payload.data.data.id
        );
        if (index >= 0) {
          state.subscription.older[index].likes =
            action.payload.data.data.likes;
          state.subscription.older[index].is_liked_by_current_user = 0;
        }
      }
      state.loader = false;
      state.fetched = true;
    });
    builder.addCase(dislikeProject.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(increaseViewProject.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(increaseViewProject.fulfilled, (state, action) => {
      sessionStorage.setItem("increased", 1);
      state.projectDetails.views = action.payload.data.data.views;
      state.loader = false;
      state.fetched = true;
    });
    builder.addCase(increaseViewProject.rejected, (state) => {
      state.loader = false;
    });
    // create projects.
    builder.addCase(createProject.pending, (state) => {
      state.loader = true;
      state.status = false;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      // const projects = JSON.parse(JSON.stringify(state.projects));
      // state.projects = [action.payload.data.data, ...projects];
      state.projectDetails = action.payload.data.data;
      state.projectDetails.files = [];
      state.loader = false;
      state.status = true;
    });
    builder.addCase(createProject.rejected, (state) => {
      state.loader = false;
    });
    // sort comment
    // builder.addCase(sortComments.fulfilled, (state, action) => {
    // let rr =  action.payload.data.data;
    //   var t = rr.sort((a, b) => parseFloat(a.likes) - parseFloat(b.likes));
    //   console.log(t);

    //    state.projectDetails.comments = action.payload.data.data;
    //   // state.loader = false;
    //   // state.fetched = true;
    // });
    // like comments
    builder.addCase(likeComment.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(likeComment.fulfilled, (state, action) => {
      const { comments } = state.projectDetails;
      const index = comments.findIndex(
        (i) => i.id === parseInt(action.payload.data.data.id)
      );
      state.projectDetails.comments[index].likes =
        state.projectDetails.comments[index].likes + 1;
      state.projectDetails.comments[index].is_touched = 1;
      state.loader = false;
      state.fetched = true;
    });
    builder.addCase(likeComment.rejected, (state) => {
      state.loader = false;
    });

    // dislike comments
    builder.addCase(disLikeComment.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(disLikeComment.fulfilled, (state, action) => {
      const { comments } = state.projectDetails;
      const index = comments.findIndex(
        (i) => i.id === parseInt(action.payload.data.data.id)
      );
      state.projectDetails.comments[index].likes =
        state.projectDetails.comments[index].likes - 1;
      state.projectDetails.comments[index].is_touched = 1;
      state.loader = false;
      state.fetched = true;
    });
    builder.addCase(disLikeComment.rejected, (state) => {
      state.loader = false;
    });

    // getSubscription
    builder.addCase(getSubscription.pending, (state) => {});
    builder.addCase(getSubscription.fulfilled, (state, action) => {
      state.subscription = action.payload?.data?.data;
    });
    builder.addCase(getSubscription.rejected, (state) => {});

    // get cropped Images
    // getSubscription
    builder.addCase(getCroppedFilesByProject.pending, (state) => {});
    builder.addCase(getCroppedFilesByProject.fulfilled, (state, action) => {
      // cropped and save
    });
    builder.addCase(getCroppedFilesByProject.rejected, (state) => {});
    // deleteCroppedOnBackButton
    builder.addCase(deleteCroppedOnBackButton.pending, (state) => {});
    builder.addCase(deleteCroppedOnBackButton.fulfilled, (state, action) => {});
    builder.addCase(deleteCroppedOnBackButton.rejected, (state) => {});
    builder.addCase(fecthCroppingConfiguration.rejected, (state) => {});
    builder.addCase(fecthCroppingConfiguration.pending, (state) => {});
    builder.addCase(fecthCroppingConfiguration.fulfilled, (state, action) => {
      state.croppingConfiguration = action.payload.data.data;
    });
  },
});

export const projectActions = projectSlice.actions;
export default projectSlice.reducer;
