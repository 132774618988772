import { Box, Button, FormControl, RadioGroup } from "@mui/material";

export default function Photobook({
  onChangeLayoutType,
  selectedAlbumRatio,
  checkBoxData,
  label,
}) {
  return (
    <Box>
      <FormControl sx={{ mb: 3, mt: 2, ml: 3, width: "100%" }}>
        <label style={{ color: "#767676" }}> {label}</label>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => onChangeLayoutType(e.target.value)}
        >
          {checkBoxData &&
            checkBoxData.map((item, i) => (
              <Button
                key={`${item}_${i}`}
                onClick={() => {
                  onChangeLayoutType(item.name);
                }}
                variant="outlined"
                sx={{
                  mr: 2,
                  mt: 2,
                  border:
                    selectedAlbumRatio === item.name && "3px solid #074EE8",
                }}
                color="inherit"
              >
                {item.name}
              </Button>
            ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
