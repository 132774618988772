import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export default function AccessDenied() {
  return (
    <Box
      id="error-page"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      height="90vh"
    >
      <div>
        <p>
          <i>403 Forbidden Access</i> <br />
          <Link to="/">retrun to home</Link>
        </p>
      </div>
    </Box>
  );
}
