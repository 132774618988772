import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import FlipbookComponent from "./Flipbook";

const style = {
  position: "absolute",
  top: "10%",
  left: "10%",
  // transform: "translate(-50%, -50%)",
  width: 1000,
  background: "white",
  boxShadow: 24,
  p: 4,
};

const previewStyle = {
  position: "absolute",
  top: "10%",
  left: "10%",
  width: "70%",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
  '@media (max-width: 768px)': {
    left: '11%',   
    top: "10%"
  },
};




function FlipbookModal({ isModalVisible, onClose, files, isPreview }) {
  const desired_height = useSelector(
    (state) => state.project.projectDetails.desired_height
  );
  const desired_width = useSelector(
    (state) => state.project.projectDetails.desired_width
  );
  
  const containerStyle = isPreview ? { ...previewStyle } : { ...style };
  const containerRef = useRef(null);
  useEffect(() => {
    const calculatePosition = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        containerStyle.left = `${(windowWidth - containerWidth) / 2}px`;
        containerStyle.top = `${(windowHeight - containerHeight) / 2}px`;
      }
    };

    calculatePosition();
    window.addEventListener('resize', calculatePosition);

    return () => {
      window.removeEventListener('resize', calculatePosition);
    };
  }, [isModalVisible, containerStyle]);

  return (
    <div>
      <Modal
        open={isModalVisible}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  sx={{ ...containerStyle }}
          ref={containerRef}
          id="modelContainer">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={() => onClose()}>
              <CloseIcon sx={{ fontSize: 28 }} />
            </IconButton>
          </Box>
          <FlipbookComponent
            files={files}
            desired_height={desired_height}
            desired_width={desired_width}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default FlipbookModal;
