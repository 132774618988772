import { Fade, Menu } from "@mui/material";
import MenuList from "./MenuList";

function ProjectVisibility({
  categoryData,
  onSelectCategoryOption,
  onCloseStatus,
  isOpen,
  dropdownAnchor,
  isDropdownOpen,
  selectedData,
}) {
  return (
    <>
      {isOpen && (
        <Menu
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={dropdownAnchor}
          open={isDropdownOpen}
          onClose={onCloseStatus}
          TransitionComponent={Fade}
          PaperProps={{ sx: { width: "250px" } }}
        >
          {categoryData &&
            categoryData.map((item, i) => (
              <MenuList
                key={`${item}_${i}`}
                onPressMenuList={(value) => {
                  onSelectCategoryOption(item.name, value);
                }}
                title={item?.name}
                check={!!selectedData.includes(item.name)}
              />
            ))}
        </Menu>
      )}
    </>
  );
}

export default ProjectVisibility;
