import { Box, Grid } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux/es";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import useSystemStore from "../store/system";

export default function Root() {
  const isLoggedIn = useSelector((state) => state.authentication.accessToken);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [openDrawer, setOpenDrawer] = useState(screenSize >= 600);
  const { isFullScreen, setIsFullScreen, setViewportDimension } =
    useSystemStore();
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const [visibility, setVisibility] = useState(false);
  const drawerWidth = 245;

  const handleViewportDimensionChange = () => {
    setViewportDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleViewportDimensionChange);
    return () => {
      window.removeEventListener("resize", handleViewportDimensionChange);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1024) {
        setOpenDrawer(false);
      } else {
        setOpenDrawer(true);
      }
    }
    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);
  });

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!window.location.pathname.includes("discover-details-with-iframe")) {
      setVisibility(true);
    }
  }, []);

  // On press back change fullscreen state
  useEffect(() => {
    const handleBackButton = () => {
      setIsFullScreen(false);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  return (
    <Grid container spacing={0}>
      {isLoggedIn && visibility && (
        <Box
          component="nav"
          sx={{
            ...(isFullScreen && { display: "none" }),
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant={screenSize < 600 ? "temporary" : "persistent"}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open={openDrawer}
            onClose={() => toggleDrawer()}
          >
            <Sidebar toggleDrawer={() => toggleDrawer()} />
          </Drawer>
        </Box>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: {
            sm: !openDrawer ? "100%" : `calc(100% - ${drawerWidth}px)`,
          },
        }}
        style={{ backgroundColor: isFullScreen ? "black" : "" }}
      >
        <Outlet context={[openDrawer, setOpenDrawer]} />
      </Box>
    </Grid>
  );
}
