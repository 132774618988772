import { Alert, Box, Button, FormControl, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { emailValidationMessage } from "../../common/constants";
import CustomInput from "../../components/CustomInput";
import CustomLoader from "../../components/CustomLoader";
import Navbar from "../../components/Navbar";
import { forgetPassword } from "../../store/slices/authenticationSlice";
import LoginStyle from "../../styles/Register.module.scss";

function ForgotPassword(props) {
  const dispatch = useDispatch();
  const { loader } = props;

  const [email, setEmail] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [mailSentSuccessfully, setMailSentSuccessfully] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [loading, setLoading] = useState(loader ?? false);
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState(
    []
  );

  const [emailValidationMsg, setEmailValidationMsg] = useState("");

  useEffect(() => {
    setLoading(loader);
  }, [loader]);

  const emailValid = (value) => {
    if (value.length === 0) {
      setEmailValidationMsg(emailValidationMessage);
    } else {
      setEmailValidationMsg("");
    }
  };

  const onPressResetPassword = () => {
    setLoading(true);
    const payload = {
      email,
    };
    dispatch(forgetPassword(payload))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          setServerSideValidationErrors("");
          setLoading(false);
          setMailSentSuccessfully(true);
          setIsToastOpen(true);
        } else if (res?.data?.status === false) {
          setServerSideValidationErrors(Object.values(res?.data?.errors));
        }
      })
      .catch((err) => {
        console.log("catchError:", err);
      });
  };

  useEffect(() => {
    if (email && !emailValidationMsg) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [email, emailValidationMsg]);

  return (
    <>
      <Navbar title="Reset Password" />
      <CustomLoader isOpen={loading} />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          sx={{ width: "45em", p: 1, mt: "8em" }}
          sm={{ width: "35em", p: 1, mt: "8em" }}
          className={LoginStyle.inputContaier}
        >
          <Box textAlign="center">
            <h3>Reset Password</h3>
            {!mailSentSuccessfully ? (
              <p>
                Enter the email address associated with your account, and we'll
                email you a link to reset your password
              </p>
            ) : (
              <p>
                We’ve sent you a password reset link to {email}. For your
                security, the link in the message will only be valid for the
                next 24 hours.
              </p>
            )}
          </Box>
          {!mailSentSuccessfully && (
            <>
              <FormControl fullWidth sx={{ mb: 3, mt: 2 }}>
                <CustomInput
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    emailValid(e.target.value);
                  }}
                  placeholder="aaa@abc.com"
                  label="Email Address"
                  validationMessage={emailValidationMsg}
                />
              </FormControl>

              <Button
                variant="contained"
                size="large"
                sx={{ display: "flex", width: "100%", mx: "auto", mt: 2 }}
                sm={{ display: "flex", width: "40%", mx: "auto", mt: 2 }}
                onClick={onPressResetPassword}
                disabled={isBtnDisabled}
              >
                Send reset link
              </Button>
            </>
          )}
          <>
            {serverSideValidationErrors &&
              serverSideValidationErrors.map((item, i) => (
                <Alert key={`${item}_${i}`} sx={{ mt: 2.5 }} severity="error">
                  {item}
                </Alert>
              ))}
          </>
        </Box>
        <Snackbar
          open={isToastOpen}
          autoHideDuration={3000}
          onClose={() => setIsToastOpen(false)}
        >
          <Alert severity="success">Reset link sent successfully!</Alert>
        </Snackbar>
      </Box>
    </>
  );
}

function mapStateToProps(state) {
  const { loader, status } = state.authentication;
  return { loader, status };
}
export default connect(mapStateToProps)(ForgotPassword);
