import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

function AlreadyLoggedIn({ children }) {
  const currentUser = useSelector((state) => state.authentication.currentUser);
  const location = useLocation();
  if (currentUser) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
}

export default AlreadyLoggedIn;
