import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";
import "./SortableItem.css";

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.id.id,
      transition: {
        duration: 500, // milliseconds
        easing: "cubic-bezier(0.25, 1, 0.5, 1)",
      },
    });
  const [isloaded, setIsLoaded] = useState(false);
  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "1em",
    userSelect: "none",
    cursor: "grab",
    boxSizing: "border-box",
    marginRight: "1em",
    objectFit: "contain",
  };
  if (window.innerWidth <= 768) {
    itemStyle.marginRight = "0.5em";
  }
  const [imgStyle, setImgStyle] = useState({
    width: "auto",
    maxHeight: "200px",
  });
  const isTouchScreenDevice = () => {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  };

  const loadImg = () => {
    setIsLoaded(true);
    setImgStyle({
      width: isTouchScreenDevice() ? "50px" : "auto",
      border: "1px solid grey",
      maxHeight: "200px",
    });
    props.onload();
  };
  return (
    <div style={{ visibility: props.visibility }}>
      <div
        className={props.id.sort_order !== null ? "" : "not_draggeble"}
        style={itemStyle}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
      >
        <img
          style={imgStyle}
          src={props?.id.compressed}
          alt={props.id.id}
          onLoad={loadImg}
        />
      </div>
      {isloaded && (
        <p
          style={
            isTouchScreenDevice()
              ? {
                  margin: "auto",
                  width: "min-content",
                  marginBottom: "1em",
                  marginTop: "-0.6em",
                }
              : {
                  textAlign: "center",
                  marginTop: "0.5em",
                  marginRight: "0.5em",
                }
          }
        >
          {props.id.ind}
        </p>
      )}
    </div>
  );
}

export default SortableItem;
