import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Avatar,
  Box,
  Button,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ShareIcon from "@mui/icons-material/Share";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { authenticationActions } from "../../../store/slices/authenticationSlice";
import {
  addComment,
  dislikeProject,
  likeProject,
  subscribeProject,
} from "../../../store/slices/project/projectAction";
import { projectActions } from "../../../store/slices/projectSlice";
import styles from "../../../styles/Components/discover/discover.module.scss";
import ShareModal from "../../Share/ShareModal";
import ShareWithIframe from "../../Share/ShareWithIframe";
import Comment from "./Comment";
import CommentInput from "./CommentInput";

function UserHeaderSection({ projectDetails, isProjectTitle, isUserSection }) {
  const dispatch = useDispatch();
  // const user = projectDetails?.user;
  // const userName = user?.first_name + " " + user?.last_name;
  const commentsLength = projectDetails?.comments?.length;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const currentUser = useSelector((state) => state.authentication.currentUser);
  const location = useLocation();
  const [sortBy, setSortBy] = useState("latest");
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [showShareIframe, setShowShareIframe] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [shareIframeLink, setShareIframeLink] = useState("");
  const subscribeHandler = () => {
    const projectId = projectDetails?.channel?.id;
    const payload = {
      channel_id: projectId.toString(),
    };
    if (!currentUser) {
      dispatch(authenticationActions.remeberPathForGuest(location.pathname));
      navigate("/login");
    } else {
      dispatch(subscribeProject(payload));
    }
  };
  useEffect(() => {
    dispatch(authenticationActions.showShareIframe(showShareIframe));
  }, [dispatch, showShareIframe]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const sortCommentsby = (by) => {
    setSortBy(by);
    const payload = {
      comments: projectDetails.comments,
      by,
    };

    dispatch(projectActions.sortComments(payload));
  };
  const likeProjectHandler = () => {
    if (!currentUser) {
      dispatch(authenticationActions.remeberPathForGuest(location.pathname));
      navigate("/login");
    } else {
      dispatch(likeProject(projectDetails.id));
    }
  };

  const dislikeProjectHandler = () => {
    dispatch(dislikeProject(projectDetails.id));
  };

  const showShareModal = () => {
    setIsShareModalVisible(true);
    setShareLink(`${process.env.REACT_APP_URL}share/${projectDetails?.id}`);
    setShareIframeLink(
      `${process.env.REACT_APP_URL}discover-details-with-iframe/${projectDetails?.id}`
    );
  };
  const handleCloseShareModal = () => {
    setIsShareModalVisible(false);
  };
  const handleCloseShareWithIframe = () => {
    setShowShareIframe(false);
  };

  return (
    <Stack gap={1}>
      {isProjectTitle && (
        <h3 style={{ textTransform: "capitalize", marginBottom: 0 }}>
          {projectDetails?.title}
        </h3>
      )}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        gap={2}
        flexWrap="wrap"
      >
        <Stack alignItems="center">
          Published {projectDetails?.updatedTime}
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <VisibilityOutlinedIcon />
          {projectDetails?.views ?? 0}
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          {projectDetails?.is_liked_by_current_user === 1 ? (
            <IconButton onClick={dislikeProjectHandler}>
              <ThumbUpIcon />
            </IconButton>
          ) : (
            <IconButton onClick={likeProjectHandler}>
              <ThumbUpOutlinedIcon />
            </IconButton>
          )}
          <span> {projectDetails?.likes}</span>
        </Stack>

        <Stack alignItems="center">
          <Button
            onClick={() => showShareModal()}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <ShareIcon />
            <p style={{ margin: 0 }}>Share</p>
          </Button>
        </Stack>
      </Stack>

      <ShareModal
        isModalVisible={isShareModalVisible}
        handleClose={handleCloseShareModal}
        shareLink={shareLink}
        onPressShowIframeModal={() => {
          setIsShareModalVisible(false);
          setShowShareIframe(true);
        }}
        title={projectDetails?.title}
        visibility={projectDetails.visibility}
        projectId={projectDetails.id}
      />

      {showShareIframe && (
        <ShareWithIframe
          open={showShareIframe}
          handleClose={handleCloseShareWithIframe}
          shareLink={shareIframeLink}
          currentProjectFiles={projectDetails?.croppedfiles}
        />
      )}

      {isUserSection && (
        <Box className={styles.projectUserInfo}>
          <Box display="flex" alignItems="center">
            <Link to={`/channel/${projectDetails?.channel?.id}`}>
              {projectDetails?.channel?.image_url ? (
                <Avatar
                  sx={{
                    width: 50,
                    height: 50,
                    mr: 2,
                    mt: 1,
                    color: "lightgray",
                  }}
                  alt={projectDetails?.channel?.name}
                  src={projectDetails?.channel?.image_url}
                />
              ) : (
                <AccountCircleIcon
                  sx={{ width: 50, height: 50, mr: 2, color: "lightgray" }}
                />
              )}
            </Link>
            <Box>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={`/channel/${projectDetails?.channel?.id}`}
              >
                <p
                  style={{
                    textTransform: "capitalize",
                    marginBottom: "0.5em",
                    fontWeight: "500",
                  }}
                >
                  {projectDetails.channel?.name ?? ""}
                </p>
                <p
                  style={{
                    textTransform: "capitalize",
                    fontSize: "14px",
                    marginTop: 0,
                  }}
                >
                  By {projectDetails.channel?.createdby}
                </p>
              </Link>
            </Box>
          </Box>
          <Box className={styles.sortByDropdown}>
            <Button
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p style={{ margin: 3 }}>
                  Sort by "
                  {sortBy === "latest" ? "Newest first" : "Top Comments"}"
                </p>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Box>
            </Button>
          </Box>
        </Box>
      )}
      {isUserSection && (
        <Box className={styles.projectUserInfo}>
          <Box display="flex" alignItems="center">
            <Box sx={{ width: 50, color: "lightgray" }} />
          </Box>
          <Button
            onClick={subscribeHandler}
            variant="contained"
            className={styles.subscribeButton}
          >
            {projectDetails.channel?.is_subscribed
              ? "UnSubscribe"
              : "Subscribe"}
          </Button>
        </Box>
      )}
      <Box
        sx={{
          mr: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3
          style={{
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          {commentsLength} {commentsLength > 1 ? "Comments" : "Comment"}
        </h3>

        <Menu
          className={styles.discoverCommentSort}
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            selected={sortBy === "top"}
            onClick={() => {
              sortCommentsby("top");
              handleClose();
            }}
            disableRipple
          >
            Sort by "Top Comments"
          </MenuItem>
          <MenuItem
            selected={sortBy === "latest"}
            onClick={() => {
              sortCommentsby("latest");
              handleClose();
            }}
            disableRipple
          >
            Sort by: "Newest first"
          </MenuItem>
        </Menu>
      </Box>
    </Stack>
  );
}

function ProjectComment({
  projectId,
  commentData,
  projectDetails,
  isCommentBoxVisible = false,
  isProjectTitle = false,
  isUserSection = false,
}) {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const currentUser = useSelector((state) => state.authentication.currentUser);
  const onAddComment = () => {
    const payload = {
      body: comment,
      project_id: projectId.toString(),
    };
    if (comment) {
      dispatch(addComment(payload));
    }

    setComment("");
  };
  return (
    <Box>
      <UserHeaderSection
        projectDetails={projectDetails}
        isProjectTitle={isProjectTitle}
        isUserSection={isUserSection}
      />
      {isCommentBoxVisible && currentUser && (
        <CommentInput
          placeholder="Add a comment....."
          commentValue={comment}
          onAddComment={onAddComment}
          onCancel={() => setComment("")}
          onChange={(value) => setComment(value)}
          currentUser={currentUser}
        />
      )}
      <Box>
        {commentData &&
          commentData.map((item) => <Comment key={item.id} {...item} />)}
      </Box>
    </Box>
  );
}

export default ProjectComment;
