import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";

import styles from "../styles/Components/CustomInput.module.scss";

export default function CustomInput({
  label = "",
  value,
  onChange,
  placeholder = "",
  type = "",
  isInputTypePassword,
  validationMessage = "",
  isMultiline = false,
  totalRows = "4",
}) {
  const [showPassword, setShowPassword] = useState(false);
  const isError = !!validationMessage;
  return (
    <div className={styles.inputContainer}>
      <label className={styles.label}>{label}</label>
      {isInputTypePassword ? (
        <TextField
          value={value ?? ""}
          type={showPassword ? "text" : "password"}
          onChange={onChange}
          placeholder={placeholder}
          className={styles.input}
          sx={{ width: "100%" }}
          helperText={validationMessage}
          error={isError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <TextField
          type={type}
          className={styles.input}
          value={value ?? ""}
          onChange={onChange}
          placeholder={placeholder}
          error={isError}
          helperText={validationMessage}
          sx={{ width: "100%" }}
          multiline={isMultiline}
          rows={totalRows}
        />
      )}
    </div>
  );
}
