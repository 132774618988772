import { Alert, Box, Button, FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from "@greatsumini/react-facebook-login";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  emailValidationMessage,
  passwordValidationNonEmptyMessage,
} from "../../common/constants";
import { validateEmail } from "../../common/helper";
import CustomInput from "../../components/CustomInput";
import CustomLoader from "../../components/CustomLoader";
import ErrorComponent from "../../components/Error";
import Navbar from "../../components/Navbar";
import {
  signIn,
  signInWithGmail,
} from "../../store/slices/authenticationSlice";
import LoginStyle from "../../styles/Register.module.scss";

// import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';

function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const [emailValidationMsg, setEmailValidationMsg] = useState("");
  const [passwordValidationMsg, setPasswordValidationMsg] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [serverSideValidationErrors, setServerSideValidationErrors] = useState(
    []
  );
  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem("persist:root");
    }, 1);
  });
  useEffect(() => {
    if (
      email &&
      password &&
      isFormValid &&
      !emailValidationMsg &&
      !passwordValidationMsg
    ) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
      setIsFormValid(false);
    }
  }, [email, password, isFormValid, emailValidationMsg, passwordValidationMsg]);
  const redirect_path = useSelector(
    (state) => state.authentication.redirect_path
  );
  const emailValid = (value) => {
    if (value.length === 0) {
      setEmailValidationMsg(emailValidationMessage);
      setIsFormValid(false);
    }

    if (!validateEmail(value)) {
      setEmailValidationMsg(emailValidationMessage);
      setIsFormValid(false);
    } else {
      setEmailValidationMsg("");
      setIsFormValid(true);
    }
  };

  const passwordValid = (value) => {
    if (!value) {
      setPasswordValidationMsg(passwordValidationNonEmptyMessage);
      setIsFormValid(false);
    } else {
      setPasswordValidationMsg("");
      setIsFormValid(true);
    }
  };

  const onPressSignIn = async () => {
    const payload = {
      email,
      password,
    };
    if (!isBtnDisabled && isFormValid) {
      setLoading(true);
      dispatch(signIn(payload))
        .unwrap()
        .then((res) => {
          setLoading(false);
          if (res?.data?.status) {
            navigate("/my-projects");
          } else {
            setServerSideValidationErrors(Object.values(res?.data?.errors));
          }
        })
        .catch((err) => {
          setLoading(false);
          // console.log("catchError:", err);
        });
    } else {
      emailValid(email);
      passwordValid(password);
      setIsFormValid(false);
      setErrorMsg("");
    }
  };

  return (
    <>
      <Navbar isDrawerOpen={false} />
      <CustomLoader isOpen={loading} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box
          sx={{ width: "45em", marginTop: "4em", padding: "15px" }}
          sm={{ width: "30em", marginTop: "4em", padding: "15px" }}
          className={LoginStyle.inputContaier}
        >
          <Box textAlign="center">
            <h3>Welcome back!</h3>
            <p>Log in to your flippingBook account</p>
          </Box>
          <FormControl fullWidth sx={{ mb: 3, mt: 2 }}>
            <CustomInput
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                emailValid(e.target.value);
              }}
              placeholder="aaa@abc.com"
              label="Email Address"
              validationMessage={emailValidationMsg}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3, mt: 2 }}>
            <CustomInput
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                passwordValid(e.target.value);
              }}
              placeholder="******************************"
              label="Password"
              isInputTypePassword
              validationMessage={passwordValidationMsg}
            />
          </FormControl>
          {errorMsg && <ErrorComponent error={errorMsg} />}
          <Button
            variant="contained"
            size="large"
            sx={{ display: "flex", width: "100%", mx: "auto", mt: 2 }}
            onClick={onPressSignIn}
          >
            Sign In
          </Button>
          {serverSideValidationErrors &&
            serverSideValidationErrors.map((item, i) => (
              <Alert key={`${item}_${i}`} sx={{ mt: 2.5 }} severity="error">
                {item}
              </Alert>
            ))}
          <p className={LoginStyle["text-center"]}>
            Don't have an account?{" "}
            <Link to="/register" className={LoginStyle.linkButton}>
              {" "}
              Sign up
            </Link>
            .
          </p>
          <p className={LoginStyle["text-center"]}>
            <Link to="/forget-password" className={LoginStyle.linkButton}>
              Forgot password?
            </Link>
          </p>
          <hr />

          <Box className={LoginStyle.socialBox} sx={{ marginTop: "1em" }}>
            {/* <Button  variant="outlined" startIcon={<FacebookSharpIcon />}>Log in with Facebook</Button>  */}
            <div className={LoginStyle.gooogleButtonLink}>
              <GoogleLogin
                text="login_with"
                width="100px"
                onSuccess={(credentialResponse) => {
                  const responsePayload = jwtDecode(
                    credentialResponse.credential
                  );

                  const payload = {
                    social_id: responsePayload.sub,
                    type: "gmail",
                    first_name: responsePayload.given_name,
                    last_name: responsePayload.family_name,
                    email: responsePayload.email,
                    role_id: "1",
                    status: responsePayload.email_verified ? 1 : 0,
                  };
                  dispatch(signInWithGmail(payload))
                    .unwrap()
                    .then((res) => {
                      setLoading(false);
                      if (res?.data?.status) {
                        navigate(redirect_path);
                      } else {
                        setServerSideValidationErrors(
                          Object.values(res?.data?.errors)
                        );
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      // console.log("catchError:", err);
                    });
                }}
                onError={() => {
                  // console.log("Login Failed");
                }}
              />
            </div>
            <FacebookLogin
              className={LoginStyle.facebookButtonLink}
              appId="119741961092513"
              onSuccess={(response) => {
                // console.log("Login Success!", response);
              }}
              onFail={(error) => {
                // console.log("Login Failed!", error);
              }}
              onProfileSuccess={(responsePayload) => {
                if (responsePayload?.name !== "") {
                  const fb_name = responsePayload?.name ?? "";
                  const payload = {
                    social_id: responsePayload.id,
                    type: "facebook",
                    first_name: fb_name.substring(0, fb_name.indexOf(" ")),
                    last_name:
                      fb_name.substring(fb_name.indexOf(" ") + 1) ?? "",
                    email: responsePayload.email,
                    role_id: "1",
                    status: 1,
                  };
                  // console.log(payload);
                  dispatch(signInWithGmail(payload))
                    .unwrap()
                    .then((res) => {
                      setLoading(false);
                      if (res?.status === 200 && !res?.data?.errors) {
                        navigate(redirect_path);
                      } else {
                        setServerSideValidationErrors(
                          Object.values(res?.data?.errors)
                        );
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      // console.log("catchError:", err);
                    });
                }
              }}
            />
            {/* <FacebookLogin 
              appId="119741961092513"
              autoLoad={false}
              fields="name,email,picture"
              cssClass={`${LoginStyle.facebookButton}`}
              icon={<FacebookSharpIcon />}
              callback={responsePayload => {
               if(responsePayload?.status !== 'unknown') {
                const fb_name = responsePayload?.name ?? '';
                const payload = {
                  social_id:responsePayload.userID,
                  type : "facebook",
                  first_name:fb_name.substring(0, fb_name.indexOf(' ')),
                  last_name:fb_name.substring(fb_name.indexOf(' ') + 1) ?? '',
                  email:responsePayload.email,
                  role_id:"1",  
                  status: 1  
                }
                dispatch(signInWithGmail(payload)).unwrap()
                .then((res) => {
                  console.log(res);
                  setLoading(false);
                  if (res?.data?.success) {
                    navigate(redirect_path); 
                  } else {
                    setServerSideValidationErrors(Object.values(res?.data?.errors));
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  console.log("catchError:", err);
                });
               }
                
              }}
              ></FacebookLogin>  */}
          </Box>
        </Box>
      </Box>
    </>
  );
}

function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(Login);
