import { Button, Grid, TablePagination } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  discoverDropdownSearchOptions,
  discoverDropdownSearchOptionsFromChannel,
} from "../../common/constants";
import CustomLoader from "../../components/CustomLoader";
import DiscoverItem from "../../components/Discover/DiscoverItem";
import DiscoverSearchbar from "../../components/Discover/DiscoverSearchbar";
import DiscoverSortDropdown from "../../components/Discover/DiscoverSortDropdown";
import Navbar from "../../components/Navbar";
import {
  getCategories,
  getuniqueCategories,
} from "../../store/slices/CategorySlice";
import {
  discoverFilter,
  discoverPagination,
  discoverSearch,
  discoverSearchPagination,
  getDiscovers,
} from "../../store/slices/discover/discoverAction";
import styles from "../../styles/Components/discover/discover.module.scss";

function Discover(props) {
  let channel_id =
    typeof props.channel_id !== "undefined" ? props.channel_id : 0;
  const currentUser = useSelector((state) => state.authentication.currentUser);
  const [openDrawer, setOpenDrawer] = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { discovers, pagination, categories } = props;

  const [discoverData, setDiscoverData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [categoryOptionData, setCategoryOptionData] = useState([]);
  const [filter, setFilter] = useState("Recently Published");
  const [uniqueCategories, setUniqueCategories] = useState([]);
  // search bar state's
  const [typeValue, setTypeValue] = useState("");
  const [seachInputValue, setSeachInputValue] = useState("");
  const [checkBoxData, setCheckBoxData] = useState({});
  const [isClearSearch, setIsClearSearch] = useState(false);

  // pagination state's
  const [isSearchPagination, setisSearchPagination] = useState(false);
  const [searchPayload, setSearchPayload] = useState({});

  // generic state's
  const [loading, setLoading] = useState(false);

  const getDiscoverData = () => {
    setLoading(true);
    dispatch(getDiscovers({ filter, channel_id }))
      .unwrap()
      .then((res) => {
        setUniqueCategories(res.data.categories);
        setLoading(false);
      })
      .catch((err) => {
        console.log("catchError:", err);
      });
  };

  useEffect(() => {
    if (!currentUser) {
      setOpenDrawer(false);
    }
    getDiscoverData();
  }, []);
  useEffect(() => {
    if (uniqueCategories.length > 0) {
      dispatch(getuniqueCategories({ uniqueCategories }));
    }
  }, [uniqueCategories]);
  useEffect(() => {
    setDiscoverData(discovers);
  }, [discovers]);

  useEffect(() => {
    setPaginationData(pagination);
  }, [pagination]);

  useEffect(() => {
    setCategoryOptionData(categories);
  }, [categories]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      let field = filter === "Recently Published" ? "published_at" : "hot";
      const payload = {
        type: typeValue === "" ? "title" : typeValue?.toLowerCase(),
        value: seachInputValue?.toLowerCase(),
        field,
        channel_id,
        size: rowsPerPage,
      };
      callSearchApi(payload);
    }
  };

  const onChangeCheckBox = (type, value) => {
    const statusPayload = {
      type,
      value,
    };
    setCheckBoxData(statusPayload);
  };

  const callSearchApi = (payload) => {
    setSearchPayload(payload);
    if (payload.type && payload.value.length) {
      setLoading(true);
      dispatch(discoverSearch(payload))
        .unwrap()
        .then((res) => {
          // console.log("res", res);
          setLoading(false);
        });
    }
  };

  const onPressSearch = (fiterData = "") => {
    let field = filter === "Recently Published" ? "published_at" : "hot";
    if (typeValue === "") {
      const payload = {
        type: "title",
        value: seachInputValue?.toLowerCase(),
        field,
        channel_id,
        size: rowsPerPage,
      };
      callSearchApi(payload);
    } else if (
      checkBoxData.type !== "" &&
      checkBoxData.type !== "title" &&
      checkBoxData.type !== "channel" &&
      checkBoxData.type !== undefined
    ) {
      checkBoxData.fiterData = fiterData;
      checkBoxData.channel_id = channel_id;
      checkBoxData.field = field;
      checkBoxData.size = rowsPerPage;
      callSearchApi(checkBoxData);
    } else {
      const payload = {
        type: typeValue?.toLowerCase(),
        value: seachInputValue?.toLowerCase(),
        field,
        channel_id,
        size: rowsPerPage,
      };
      callSearchApi(payload);
    }
  };

  const onPressReset = () => {
    setPage(0);
    setFilter("Recently Published");
    setSeachInputValue("");
    setIsClearSearch(!isClearSearch);
    setisSearchPagination(false);
    setLoading(true);
    getDiscoverData();
  };

  // pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPressSearch();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));

    setPage(0);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(getCategories());
  }, [dispatch]);
  useEffect(() => {
    setTimeout(() => {
      if (isSearchPagination) {
        getDiscoverSearchPagination();
      } else {
        getPaginatedData();
      }
    }, 200);
  }, [page, rowsPerPage]);

  const getPaginatedData = () => {
    const fiterData =
      filter === "Recently Published"
        ? "&field=published_at&order=desc"
        : "&field=hot";

    setLoading(true);
    const payload = {
      id: page + 1,
      pageSize: rowsPerPage,
      field: fiterData,
      type: typeValue === "" ? "title" : typeValue?.toLowerCase(),
      value: seachInputValue?.toLowerCase(),
      channel_id,
    };
    dispatch(discoverPagination(payload))
      .unwrap()
      .then(() => {
        setLoading(false);
      });
  };

  const getDiscoverSearchPagination = () => {
    const payload = {
      id: pagination.pageIndex + 1,
      data: searchPayload,
      channel_id,
      pageSize: rowsPerPage,
    };
    setLoading(true);
    dispatch(discoverSearchPagination(payload))
      .unwrap()
      .then(() => {
        setLoading(false);
      });
  };

  const onPressFilter = () => {
    setPage(0);
    setLoading(true);
    const fiterData =
      filter === "Recently Published"
        ? `?field=published_at&order=desc&channel_id=${channel_id}`
        : `?field=hot&channel_id=${channel_id}`;
    const payload = { fiterData, channel_id };
    setSearchPayload(payload);
    if (seachInputValue !== "" || checkBoxData.type !== "") {
      onPressSearch(fiterData);
    } else {
      dispatch(discoverFilter(payload))
        .unwrap()
        .then(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    onPressFilter();
  }, [filter]);

  return (
    <>
      <Navbar
        title={channel_id ? "Channel" : "Discover"}
        isDrawerOpen={openDrawer}
        toggleDrawer={() => setOpenDrawer(!openDrawer)}
      />
      <Grid p={0} container item xs={12}>
        <CustomLoader isOpen={loading} />
        {props.desc ? (
          <>
            <Box mt={-8}>{props.desc}</Box>
          </>
        ) : (
          <>
            <Box p={2} className={styles.discoveryFilters}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    mr: 1,
                    width: "65%",
                    "@media only screen and (max-width: 768px)": {
                      width: "75%",
                    },
                    "@media only screen and (max-width: 375px)": {
                      width: "85%",
                    },
                  }}
                >
                  <DiscoverSearchbar
                    onChangeSelect={(value) => {
                      setTypeValue(value);
                      if (value === "category") {
                        setSeachInputValue("");
                      }
                    }}
                    onInputChange={(value) => setSeachInputValue(value)}
                    handleKeyDown={(e) => handleKeyDown(e)}
                    options={
                      channel_id
                        ? discoverDropdownSearchOptionsFromChannel
                        : discoverDropdownSearchOptions
                    }
                    categoryData={categoryOptionData}
                    onChangeSelectedCategory={(value) => {
                      onChangeCheckBox(typeValue, value);
                    }}
                    isClearSearch={isClearSearch}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    variant="contained"
                    onClick={() => onPressSearch()}
                    sx={{
                      mr: 1,
                      fontSize: ["12px", "13px", "13px"],
                      lineHeight: ["2.2", "2"],
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      onPressReset();
                    }}
                    sx={{
                      mr: 2,
                      fontSize: ["12px", "13px", "13px"],
                      lineHeight: ["2.2", "2"],
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
              <Box className={styles.discoverySortBy}>
                <DiscoverSortDropdown
                  onChangeDropdown={(value) => setFilter(value)}
                  filter={filter}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
              {!discoverData?.length && (
                <p style={{ textAlign: "center", width: "100%" }}>
                  No records to display
                </p>
              )}
              {discoverData &&
                discoverData.map((item, i) => (
                  <DiscoverItem key={`${item?.title}_${i}`} {...item} />
                ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                width: "100%",
                "@media only screen and (max-width: 768px)": {
                  paddingRight: "15px",
                },
              }}
            >
              <TablePagination
                component="div"
                count={paginationData?.total ?? 0}
                page={paginationData?.current_page - 1}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Grids per page"
              />
            </Box>
          </>
        )}
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  const { discovers, pagination } = state.discover;
  const { categories } = state.category;
  return { discovers, pagination, categories };
}
export default connect(mapStateToProps)(Discover);
